.doc-success{
    background-color: #F8EDDF;
    height: 100vh;
    align-items: center;
    padding-top: 80px;
    & .title{
        font-weight: 500;
        font-size: 23px;
        line-height: 130%;
    }
    & .success-card{
        background-color: #fff;
        border-radius: 24px;
        max-width: 342px;
        padding: 12px;
        margin-top: 24px;
        & .doc-success-lottie{
            width: 134px;
            height: 134px;
          }
        & .title{
            font-weight: 600;
            font-size: 19px;
            line-height: 175%;
            text-align: center;
            margin-bottom: 20px;
        }
        & .thanks-tag{
            background: #FAFAFA;
            border-radius: 9px;    
            padding: 12px;     
            font-weight: 500;
            font-size: 14px;
            line-height: 175%;
            letter-spacing: 0.25px;
        }
    }
}