.delight {
  background-color: #fbf8f4;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  & .content {
    margin-top: 70px;
    text-align: center;
    & .desc {
      font-size: 28px;
      font-weight: 500;
      margin-top: 12px;
    }
    & .earning-card {
      background: #ffffff;
      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
      padding: 12px;
      margin-top: 36px;
      text-align: left;
      & .earning-text {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
        font-size: 14px;
      }
      & .earning-number {
        font-weight: 700;
        font-size: 24px;
      }
    }
    & .bottom-btn {
      margin-top: 60px;
      @media screen and (max-width: $screen-sm-max) {
        background: #ffffff;
        padding: 20px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    & .close-btn {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      cursor: pointer;
      padding: 8px;
      position: fixed;
      top: 24px;
      right: 24px;
    }
  }
}
