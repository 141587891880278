.highlight-modal-container {
  display: block;
  min-height: 487px;
  & .ant-modal-content {
    border-radius: 16px;
    padding: 0;
    overflow: hidden;
    height: 100%;
  }
  & .ant-modal-body {
    height: 100%;
    padding: 0px !important;
  }
}
.highlight-drawer {
  & .ant-drawer-header-close-only {
    position: absolute;
    right: 0px;
    border-bottom: none;
    padding: 14px 0px;
  }

  & .ant-drawer-body {
    overflow: hidden;
    padding: 0px;
  }
  &.ant-drawer-content {
    border-radius: 16px;
    @media screen and (max-width: $screen-sm-max) {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}
.right-content {
  height: 100%;
  @media screen and (min-width: $screen-sm-max) {
    min-height: 480px;
  }
}
.highlight-info {
  height: 100%;
  padding: 24px;
  @media screen and (max-width: $screen-md-min) {
    padding: 22px 16px 0px 16px;
  }
  & .desc {
    color: #141414;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; /* 24px */
    max-width: 90%;
  }
  & .title {
    font-size: 28px;
    font-weight: 700;
    display: block;
    @media screen and (max-width: $screen-xs-max) {
      font-size: 24px !important;
    }
  }
  & .content-container {
    margin-top: 32px;
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 18px;
    }
    & .url-input {
      border-radius: 6px;
      border: 1px solid #b0b0b0;
      background: #fff;
      height: fit-content;
      width: 100%;

      & .link-submit {
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
        padding: 6px 12px;
        margin: 4px;
        background-color: #000;
      }
    }
    & .highlight-options {
      border-radius: 6px;
      padding: 12px;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        background-color: #f7f7f7;
      }

      & .option-title {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

.custom-highlight-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @media screen and (max-width: $screen-md-min) {
    position: fixed;
  }
  & .bottom-desc-text {
    color: #808080;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin: 24px 24px 16px 24px;
    width: fit-content;
  }
  & .custom-btn-container {
    display: flex;
    gap: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px 24px;
    @media screen and (max-width: $screen-md-min) {
      padding: 20px 16px;
    }
  }
}

.highlight-btn-container {
  display: flex;
  position: absolute;
  bottom: 0;
  gap: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: 0;
  padding: 20px 24px;
  @media screen and (max-width: $screen-md-min) {
    position: fixed;
    padding: 20px 16px;
  }

  & .delete-btn {
    border-radius: 16.755px;
    border: 0.698px solid #e7e0c2;
    background: rgba(206, 48, 27, 0.1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    min-width: 45px;
  }
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $screen-md-min) {
    height: fit-content;
  }
  & .close-icon {
    display: none;
    @media screen and (max-width: $screen-md-min) {
      display: block;
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  & .back-icon {
    background-color: #fff;
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    filter: drop-shadow(1px 1px 12px rgba(0, 0, 0, 0.1));
    display: block;
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
  }
  & .sample-card {
    width: 90%;
    min-height: 126px;
    border-radius: 22px;
    background: #fff;
    box-shadow: 3px 3px 21px 0px rgba(0, 0, 0, 0.12);
    padding: 10px;
    margin-bottom: 20px;
    @media screen and (max-width: $screen-md-min) {
      margin-top: 20px;
    }

    & .highlight-image {
      object-fit: cover;
      width: 120px;
    }
    & .desc {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      white-space: pre-wrap;
    }
    & .sec-desc {
      color: #666b60;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
