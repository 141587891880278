.btn-dark {
  color: #fff;
  background-color: $text-color;
  border: 1px solid $text-color;
  transition: all 0.4s ease-in-out;
  font-weight: 700;
  border-radius: 6px;
  &:not(:disabled) {
    &:hover,
    &:focus {
      transition: all 0.4s ease-in-out;
      border: 1px solid $text-color !important;
      background-color: #474747 !important;
      color: #fff !important;
    }
  }
  &.inverse {
    border: 1px solid $text-color;
    color: $text-color;
    background-color: transparent;
    &:hover,
    &:focus {
      transition: all 0.4s ease-in-out;
      border: 1px solid $text-color !important;
      background-color: $text-color !important;
      color: #fff !important;
    }
  }
}

.btn-gray {
  color: $text-color;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  transition: all 0.4s ease-in-out;
  font-weight: 700;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:not(:disabled) {
    &:hover,
    &:focus {
      transition: all 0.4s ease-in-out;
      border: 1px solid #c6c6c6 !important;
      background-color: #c6c6c6 !important;
    }
  }
}

.btn-gray-light-font {
  color: $text-color;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  transition: all 0.4s ease-in-out;
  font-weight: 500;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:not(:disabled) {
    &:hover,
    &:focus {
      transition: all 0.4s ease-in-out;
      border: 1px solid #c6c6c6 !important;
      background-color: #c6c6c6 !important;
    }
  }
}

.btn-call-action {
  background-color: #f3f3f1;
  border: none;
  transition: all 0.4s ease-in-out;
  box-shadow: none;
  &:hover,
  &:focus {
    transition: all 0.4s ease-in-out;
  }
}

.mobile-fixed-cta {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .cta {
    height: 48px;
  }
  @media screen and (max-width: $screen-sm-max) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    & .cta {
      margin: 0 !important;
      height: 48px;
      width: 50%;
    }
  }
}
