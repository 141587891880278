.flex-center {
  display: flex;
  align-items: center;
}

.add-slots {
  border: 1px solid #f2f6f9;
  border-radius: 6px;
  margin-top: 20px;
  & .add-slots-header-section {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    & .slots-header {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    & .ellipsis-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  & .interval-error {
    text-align: right;
    margin-right: 27px;
    color: #9b2c2c;
  }
  & .interval-error > p {
    display: inline-block;
    padding: 6px 10px 6px 10px;
    font-size: 0.9em;
    margin-top: -6px;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #e0b4b4;
    font-weight: 600;
  }
  & .slot-item {
    border: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid #f2f6f9 !important;
    padding: 16px 24px 4px !important;
    & .slots-checkbox {
      height: 38px;
    }
    & .slots-title {
      text-transform: capitalize;
    }
    & .slots-section {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 10px;
      }
      & .add-slot-btn {
        margin-left: 14px;
        font-size: 18px;
        padding: 0;
        box-shadow: none;
      }

      .slots-unavailable {
        margin-top: 8px;
        padding-bottom: 22px;
      }
    }
    & .slots-select-box {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      align-self: flex-end;
      width: 100%;
      & .slots-box {
        width: 40%;
      }
    }
    & .btn-apply-all {
      margin-top: 1px;
      margin-bottom: 12px;
      color: #008060;
      font-weight: 700;
      align-self: flex-end;
      cursor: pointer;
    }
  }
}
