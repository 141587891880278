.payments {
  &.container-title-section {
    padding: 0px !important;
  }
  & .payment-title {
    font-weight: 700;
    font-size: 33px !important;
    @media screen and (max-width: $screen-sm-max) {
      display: none !important;
    }
  }
  & .no-show-strip {
    border-radius: 9px;
    border: 1px solid #e44332;
    background: #ffefed;
    font-size: 14px;
    line-height: 120%;
    font-weight: 400;
    padding: 7px 19px 7px 9px;
    height: 40px;
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 14px;
    }
    & .ns-cta {
      padding: 0;
      font-weight: 700;
      height: auto;
      background-color: none;
      text-decoration: underline;
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}

.balance-payment-options {
  padding: 24px 16px 24px 24px;
  @media screen and (max-width: $screen-sm-max) {
    padding: 16px 10px;
    border-top: 1px solid #e3e8ed !important;
    border-bottom: 1px solid #e3e8ed !important;
  }

  & .bpo-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  & .bpo-subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }
  & .bpo-hidden-text {
    display: none;
    @media screen and (max-width: $screen-sm-max) {
      display: inline;
    }
  }

  & .bpo-stripe-cta {
    height: 32px;
    border-radius: 16px;
    padding: 6px 14px;
    font-weight: 700;
    border: 1px solid $text-color;
  }
}

.payments-page {
  & .tabs-container {
    margin-top: 32px;
  }
  & .tab {
    border-radius: 22px;
    cursor: pointer;
    padding: 6px 20px !important;
    line-height: 175%;
  }
  & .detail-tab {
    background: #ece8e6;
    font-weight: 500;
    font-size: 16px;
  }
  & .active-tab {
    background: #000;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
  }
}
.payments-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
  @media screen and (max-width: $screen-sm-max) {
    padding-bottom: 60px;
  }
  & .es-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  & .es-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-top: 12px;
  }
  & .es-cta {
    margin-top: 32px;
    height: 48px;
    padding: 10px 40px;
  }
}
.payments-nav {
  margin-top: 10px;
  border-radius: 6px;
  background: #faf9fe;
  padding: 8px;
  @media screen and (max-width: $screen-sm-max) {
    background: none;
    padding: 0;
    border-radius: 0;
  }
  & .balance-cards {
    padding: 20px 24px 34px 18px;
    // border-radius: 12px;
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 16px;
      padding: 20px 16px;
      flex-direction: row;
      align-items: center;
      border-radius: 6px;
    }
    &.first {
      border-radius: 12px;
      border: 4px solid #fff;
      background: #f3f1e5;
      @media screen and (max-width: $screen-sm-max) {
        border: none;
      }
    }
    &.last-balance-card {
      padding: 24px 0px 20px 20px !important;
      @media screen and (max-width: $screen-sm-max) {
        border-radius: 6px;
        background: #f7f5ff;
        padding: 20px 16px !important;
      }
    }
    &.second {
      padding: 14px 20px 14px;
      border-left: 1px dashed rgba(0, 0, 0, 0.13);
      @media screen and (max-width: $screen-sm-max) {
        flex-direction: column;
        border-left: none;
        align-items: flex-start;
        padding: 0;
        & > .ant-flex {
          border-radius: 6px;
          background: #f7f5ff;
          width: 100%;
          padding: 20px 16px;
          overflow: hidden;
          max-height: 92px;
        }
      }
      & .balance-value {
        font-size: 20px;
        font-weight: 700;
        line-height: 130%; /* 26px */
      }
      & .in-withdrawal-img {
        position: absolute;
        bottom: 0;
        right: 6px;
        cursor: pointer;
        @media screen and (max-width: $screen-sm-max) {
          width: 80px;
          bottom: 20px;
          right: 10px;
        }
      }
    }
  }
  & .monthly-details {
    padding: 0px 12px 0px 12px !important;
    & .show-diff {
      background-color: #fff;
      border: 1px solid #000000;
      border-radius: 54px;
      position: absolute;
      padding: 2px 12px;
      font-weight: 700;
      font-size: 14px;
      top: 10px;
      right: 10px;
      display: flex;
    }
    & .month-column {
      height: 75%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
    }
    & .month-bar {
      background-color: #af97ff;
      width: 56px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  & .earning-details {
  }

  & .icon {
    @media screen and (max-width: $screen-sm-max) {
      margin-right: 8px;
      width: 28px;
      height: auto;
    }
  }
  & .balance-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
  }
  & .balance-value {
    margin-top: 4px;
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  & .balance-amount {
    display: flex;
    align-items: center;
  }
  & .info-icon {
    margin-left: 4px;
  }
  & .withdraw-btn {
    border-radius: 4px;
    padding: 6px 12px;
    color: #fff;
    background: #141414;
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    line-height: 18px;
    &:hover,
    &:focus {
      color: #fff !important;
      background-color: lighten(#141414, 10%) !important;
    }
  }
  & .in-withdrawal {
    background: rgba(232, 127, 49, 0.13);
    border-radius: 24px;
    padding: 7px 14px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 10px;
    }
  }

  & .stripe-text {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px;
    line-height: 21px;
    font-style: italic;
  }

  & .stripe-img {
    margin-right: 13px;
  }

  & .stripe-divider {
    height: 100%;
  }
}
.bpo-status-cta {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 7px;
  display: flex;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.withdraw-success-content {
  & .withdraw-success-lottie {
    width: 134px;
    height: 134px;
  }
  & .title {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 175%;
  }
  & .text {
    margin-bottom: 32px;
    text-align: center;
  }
}

.payment-onboarding-modal {
  & .po-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
  }
  & .po-subtitle {
    font-size: 14px;
    line-height: 21px;
    color: #686b60;
    margin-top: 12px;
  }
  & .po-content {
    font-size: 16px;
    line-height: 24px;
  }
  & .payout-preference-form {
    margin-top: 24px;
  }
  & .po-options-cta {
    padding: 20px 4px;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    border-radius: 0px;
    border-bottom: 1px solid #e6e6e6;
    &:disabled,
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
      background: #f1f1f1;
    }
    &:hover,
    &:focus {
      transition: all 0.3s ease;
      background-color: #f7f7f7;
      border-radius: 6px;
    }
    &:first-of-type {
      margin-top: 20px;
    }
    &:last-of-type {
      border-bottom: 0;
    }
  }
}
.bpo-payment-gateway {
  @media screen and (min-width: $screen-sm-max) {
    border-left: 1px solid #e3e8ed;
    margin-right: 10px;
    // padding: 14px 32px 20px;
  }
  &.stripe_added {
    border-top: none;
  }
  @media screen and (max-width: $screen-sm-max) {
    border: none;
    padding: 14px 0 0;
    &.not-added {
      padding-top: 4px;
    }
  }
}

.transaction-tab {
  & .mobile-view {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 60px;
    & .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      margin-top: 16px;
    }
  }
  & .filters-row {
    margin-top: 32px;
    & .tabs {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 14px;
      padding-bottom: 12px;
    }
    & .tab-icon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
    & .active-table {
      color: #000000;
      font-weight: 700;
      font-size: 14px;
      padding-bottom: 12px;
      border-bottom: 2px solid #000000;
    }
    .download-icon {
      border-radius: 50%;
      padding: 0px;
      width: 40px;
    }
  }
}
.filter-dropdown {
  & .ant-dropdown-menu {
    overflow: auto;
    max-height: 350px;
  }
}
.month-filter-modal {
  & .filter-tab {
    border: 1px solid #bebebe;
    padding: 6px 12px;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  & .active-tab {
    background: #f7f7f7;
    border: 1px solid #222222;
    border-radius: 4px;
    padding: 6px 12px;
    margin-bottom: 16px;
  }
}
