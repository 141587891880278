*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
* {
  font-family:
    "Gilroy",
    -apple-system,
    BlinkMacSystemFont,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    Arial,
    sans-serif !important;
}

html {
  box-sizing: border-box;
  font-size: 14px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;
}

body {
  background-color: transparent !important;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

::-moz-selection {
  color: #fff;
  background-color: $secondary-color;
}

::selection {
  color: #fff;
  background-color: $secondary-color;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-semibold {
  font-weight: 700;
}

.m-0 {
  margin: 0 !important;
}

.text-disabled {
  color: var(--textColorSecondary) !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 0;
}

// Beamer Customization
.beamer_icon {
  background-color: $error-color !important;
  width: 22px !important;
  height: 22px !important;
  padding: 0 5px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  align-items: center !important;
  justify-content: center !important;
  &.active {
    display: flex !important;
  }
}

.beamer_active {
  background-color: red !important;
  & .headerTitle {
    background-color: red !important;
  }
}

.service-dropdown {
  height: 34px;
  width: 34px;
  border-radius: 6px;
  background-color: rgba(0, 128, 96, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    font-size: 20px;
    color: #008060;
  }
  @media screen and (max-width: $screen-sm-max) {
    height: 30px;
    width: 26px;
  }
}

.back-transparent {
  background-color: transparent !important;
}

.no-padding {
  padding: 0px !important;
}

.phone-input {
  min-height: 40px;
  border-radius: 6px;
  border: 1px solid $input-border-color;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  &:hover,
  &:focus {
    outline: 1px solid $text-color;
    border: 1px solid $text-color;
    border-right-width: 1px;
    background-color: $input-number-handler-hover-bg;
    transition: all 0.25s ease-in-out;
    & .PhoneInputInput {
      background-color: $input-number-handler-hover-bg;
      transition: all 0.25s ease-in-out;
    }
  }
  & .PhoneInputInput {
    border: none !important;
    padding: 10px 11px !important;
    &:focus-visible {
      border: none;
      outline: none;
    }
  }
  & .PhoneInputCountry {
    padding: 0 12px !important;
    background-color: #dcdcdc;
    border: none !important;
    margin-right: 0 !important;
  }
}

.common-back-btn {
  color: $text-color;
  font-size: 40px;
  padding: 4px;
  cursor: pointer;
  @media screen and (max-width: $screen-sm-max) {
    font-size: 32px;
  }
}

.cursor-hover:hover {
  cursor: pointer;
}

.TrengoWidgetLauncher__iframe {
  z-index: 100 !important;
  @media screen and (max-width: $screen-sm-max) {
    bottom: 90px !important;
  }
}

.react-joyride__tooltip button {
  margin: 0px 12px 12px 12px !important;
}

.react-joyride__tooltip > div:nth-child(1) > div {
  padding: 0px 0px !important;
}

.react-joyride__tooltip {
  padding: 0px !important;
  border-radius: 8px !important;
  max-height: 450px !important;
}
.tour-image-container {
  width: 100% !important;
  border-radius: 8px 8px 0px 0px;
}
.tour-text-container {
  padding: 24px 24px 0px 24px !important;
  & .tour-title {
    text-align: left;
    font-weight: 700;
    font-size: 18px !important;
  }
  & .tour-text {
    text-align: left;
    font-weight: 500;
    font-size: 14px !important;
    margin-top: 16px;
  }
}
