@import "@styles/vars.scss";

.Info_Box {
  padding: 24px 14px;
}

.FN_title {
  color: #141414;
  text-align: center;
  font-size: 28px !important;
  font-weight: 700;
  line-height: 110%; /* 30.8px */
  padding-bottom: 8px;
}

.FN_desc {
  color: rgba(20, 20, 20, 0.8);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
  padding-bottom: 70px;
}

.SharingNudge {
  top: 45%;
  position: absolute;
  right: 20px;
}
.SM_Info_Box {
  background-color: #f7f7f7;
  padding: 16px 20px;
  border-radius: 0px 0px 12px 12px;
}

.SM_Title {
  color: #141414;
  font-size: 20px !important;
  font-weight: 700;
  line-height: 110%; /* 22px */
  letter-spacing: -0.6px;
  padding-bottom: 4px;
}

.SM_Desc {
  color: #585858;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.42px;
  padding-bottom: 16px;
}

.SM_Button {
  border-radius: 20px;
  background: #ff5925 !important;
  border: none !important;
  color: #fff !important;
  font-size: 15px;
  font-weight: 700;
  line-height: 175%; /* 26.25px */
}

.SM_Image_Box {
  position: relative;
  padding: 18px;
  max-height: 250px;
  .SM_Close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 26px;
    background-color: #fff;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
  }
}
.SN_Image {
  width: 100%;
  max-height: 240px;
  object-fit: contain;
}

.SharingPostNudge {
  top: 68%;
  position: absolute;
  right: 20px;
  z-index: 3000;
  padding: 4px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.1);
  width: 247px;
}
