.callend-container {
  .details-card {
    padding: 20px 20px 24px 20px !important;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    margin-top: 15%;
    background-color: #fff;
    border: none !important;
    & .like-options {
      margin-top: 32px;
      margin-bottom: 32px;
      & .option-tag {
        border: 1px solid #bebebe;
        border-radius: 22px;
        padding: 8px 18px;
        margin-top: 15px;
        @media screen and (max-width: $screen-sm-max) {
          padding: 8px 12px;
        }
      }
      & .option-tag-selected {
        border: 2px solid #141414;
        border-radius: 22px;
        padding: 8px 18px;
        margin-top: 15px;
        @media screen and (max-width: $screen-sm-max) {
          padding: 8px 12px;
        }
      }
    }
    & .music-tag {
      background-color: #e1e8ff;
      color: #6563ff;
      padding: 14px;
      border-radius: 6px;
      margin-top: 12px;
    }
    & .ant-rate {
      & .ant-rate-star {
        &:first-child {
          margin-inline: 0px !important;
        }
      }
      & .ant-rate-star-zero {
        color: #a5a1a1 !important;
      }
      & .ant-rate-star-second {
        color: inherit !important;
      }
      & .ant-rate-star-full {
        color: #f7941f !important;
      }
    }
    & .card-container {
      padding: 0px;
      margin-top: 24px !important;
      text-align: center;

      & .expert-name {
        font-size: 20px;
        font-weight: 700;
      }
      & .description {
        margin-top: 14px !important;
      }
      & .testimonial-image {
        border-radius: 16px;
        margin-top: 20px;
        margin-bottom: 16px;
        width: 100% !important;
      }
      & .share-btn {
        background: #f3f3f3;
        border-radius: 4px;
        border: none;
        font-weight: 700;
        font-size: 16px;
      }
    }
    & .callend-date {
      margin-top: 4px;
      font-weight: 700;
      font-size: 16px;
    }

    .extra-info {
      padding: 0px;
      flex: none;
      margin-top: 40px !important;
      text-align: center;

      & .time-tag {
        border-radius: 12px;
        box-shadow: none;
        border: none;
        background: rgba(0, 128, 96, 0.03);
        color: #008050;
        font-weight: 600;
        padding: 12px 0px !important;
      }
    }
    & .action-button {
      background-color: black;
      color: white;
    }
  }
  .report-btn {
    text-decoration: underline;
    margin-top: 24px;
  }
}

.review-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.status-container {
  min-height: 100vh;
  padding-bottom: 100px;

  & .title {
    margin-top: 48px !important;
    text-align: center;
  }
  & .details-container {
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    background-color: #fff;
    border: none !important;
    &:first-child {
      margin-top: 56px !important;

      @media screen and (max-width: $screen-sm-max) {
        margin-top: 20px !important;
      }
    }
    &:nth-child(2) {
      margin-top: 20px !important;
    }
    & .expert-details {
      padding: 16px 20px 0px 20px;
      & .expert-pic {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
      & .expert-name {
        font-weight: 700;
        font-size: 14px;
        padding-left: 8px;
      }
    }
    & .call-details {
      padding: 0px 20px 24px 20px !important;
      text-align: center;
      & .expert-profile {
        border-radius: 50%;
        margin: 32px 0px 16px 0px;
        border: 1px solid #000000;
      }
      & .booking-success-lottie {
        width: 134px;
        height: 134px;
      }

      & .calendar-icon {
        font-size: 28px;
        color: #006b43;
        margin-right: 20px;
      }
      & .time-text {
        color: #006b43;
        font-weight: 700;
        font-size: 15px;
      }
    }
    & .booking-status {
      font-weight: 700;
      font-size: 20px;
    }
    & .service-title {
      font-weight: 500;
      font-size: 12px;
      padding-top: 4px;
    }
    & .book-info {
      font-weight: 500;
      font-size: 12px;
      background: #f3f3f3;
      border-radius: 0px 0px 24px 24px;
      padding: 16px 24px;
      text-align: center;
    }

    & .package-book-details {
      padding: 24px 0px;
      & .package-booking-date {
        background: rgba(0, 128, 96, 0.06);
        border-radius: 9px;
        color: #006b43;
        font-weight: 700;
        font-size: 12px;
        margin-top: 16px;
        padding: 12px 0px;
        text-align: center;
      }
      & .detail-title {
        margin: 0px 0px 32px 20px;
        font-size: 20px;
        font-weight: 700;
      }
      & .service-details {
        margin: 0px 20px 0px 20px;
        & .service-title {
          font-weight: 700;
          font-size: 14px;
        }
        & .duration {
          color: #686b60;
          font-weight: 500;
          font-size: 12px;
        }
        & .schedule-btn {
          border: 1px solid #141414;
          border-radius: 3px;
          font-weight: 700;
          font-size: 13px;
        }
      }
      & .tip-container {
        margin: 70px 16px 0px 16px;
        & .book-tip {
          font-size: 16px;
          font-weight: 500;
          margin-left: 12px;
        }
      }
    }
  }
  & .instructions-container {
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    padding: 24px;
    margin-top: 12px !important;
    background-color: #fff;
    border: none !important;

    & .instruction-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 8px;
    }
  }
  & .support-container {
    margin: 48px 0px !important;
    text-align: center;
    & .help-text {
      display: block;
      font-weight: 600;
      font-size: 15px;
      color: #243239;
    }
    & .help-btn {
      background: #fafafa;
      border: 1px solid #141414;
      border-radius: 6px;
      margin-top: 16px;
    }
  }
  & .bottom-container {
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    padding: 24px;
    margin-top: 12px !important;
    background-color: #fff;
    border: none !important;
    text-align: center;
    & .title-nav {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 16px !important;
    }
    & .username-input-container {
      background: #fff;
      border: 1px solid #141414;
      border-radius: 6px;
      min-width: 280px;
      max-width: 400px;
      font-size: 24px;
      display: inline-flex;
      align-items: center;
      padding: 10px 10px 10px 10px;
      & .ant-typography {
        word-break: initial;
      }
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
        min-width: auto;
        margin-top: 40px;
      }
      & .username-input-arrow {
        color: $text-color;
      }
      & .username-input {
        font-size: 20px !important;
        padding: 0;
        border: none;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 16px;
        }
        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
          background-color: transparent;
        }
      }
    }
  }
  & .meeting-module-footer {
    padding: 32px 0px;
  }

  & .booking-confirmation-collapse {
    margin-top: 14px;
    background-color: #fff;
    border-radius: 20px;
    border: none;
    overflow: hidden;
    & .ant-collapse-header {
      padding: 18px 16px;
      border-radius: 20px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    & .bcc-title {
      font-size: 16px;
      line-height: 28px;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 12px;
    }
    & .bcc-actions {
      display: flex;
      flex-direction: row;
      gap: 4px;
      margin: 10px 0;
    }
    & .bcc-section {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
      line-height: 18px;
      padding: 4px;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      &:hover,
      &:focus {
        font-weight: 700;
        transition: all 0.25s ease-in-out;
      }
    }
    & .bcc-icon {
      border-radius: 9px;
      background: #f7f7f7;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.package-status-container {
  background-color: #ecf7f1;
  border-radius: 28px;
  text-align: center;
  display: inline-flex;
  color: #008060;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 10px;
  text-transform: capitalize;
}
