.about {
  & .hero {
    margin-top: 72px !important;
    padding: 35px 0 110px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 50px 0 60px;
    }
    & .title {
      font-size: 68px;
      line-height: 74px;
      font-weight: 700;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 40px !important;
      }
    }
  }
  .about-swiper-wrapper {
    background-image: url("/images/about/img-about-swiper-bg.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @media screen and (max-width: $screen-sm-max) {
      height: 350px;
    }
    .about-swiper {
      & .swiper-image {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
        background-position: center center;
      }
      &.swiper {
        width: 340px;
        height: 340px;
        @media screen and (max-width: $screen-sm-max) {
          width: 250px;
          height: 250px;
        }
      }
      .ant-image {
        height: 100%;
        width: 100%;
      }
      .ant-image-img {
        width: 100%;
        height: 100%;
      }
      & .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        border: 4px solid #fff;
      }
    }
  }
  & .about-founders {
    padding: 145px 0;
    background: #f19a70;
    position: relative;
    @media screen and (max-width: $screen-sm-max) {
      padding: 90px 0;
    }
    & .founders-title {
      font-size: 54px;
      line-height: 70px;
      font-weight: 700;
      margin-bottom: 48px !important;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 16px !important;
      }
    }
    & .founders-text {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 36px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
      }
    }
    & .founders-img {
      width: 370px;
      height: auto;
      object-fit: contain;
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }
    & .founders-img-comment {
      background: url("/src/static/images/about-page/img-about-comment.svg")
        center center no-repeat;
      background-size: contain;
      position: absolute;
      left: 36px;
      top: 36px;
      width: 155px;
      height: 100px;
      @media screen and (max-width: $screen-sm-max) {
        width: 56px;
        height: 36px;
        left: 24px;
        top: 24px;
      }
    }
    & .ss-footer {
      padding-top: 30px;
      & .founders-img {
        display: none;
        width: 100%;
        height: 100%;
        @media screen and (max-width: $screen-sm-max) {
          display: block;
          width: 70%;
          margin-bottom: 20px;
        }
      }
      & .ss-topmate-link {
        padding: 0;
        background: none;
        &:hover {
          background: none;
        }
      }
    }
  }
  & .about-investors {
    padding: 120px 0 150px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 56px 0 60px;
    }
    & .ai-title {
      font-size: 54px;
      line-height: 70px;
      font-weight: 700;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 32px !important;
      }
    }
    & .ai-card {
      background: #fafafa;
      border-radius: 16px;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: auto;
        height: 35px !important;
        object-fit: contain;
      }
      @media screen and (max-width: $screen-sm-max) {
        margin-bottom: 16px;
      }
    }
  }
  & .about-journey {
    padding: 160px 0 250px;
    background-color: #f8def6;
    position: relative;
    @media screen and (max-width: $screen-sm-max) {
      padding: 110px 0 50px;
    }
    & .aj-bg-img {
      position: absolute;
      top: 60px;
      left: 52px;
      @media screen and (max-width: $screen-sm-max) {
        top: 24px;
        left: 24px;
        width: 69px;
      }
    }
    & .aj-title {
      font-size: 54px;
      line-height: 70px;
      font-weight: 700;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 16px !important;
      }
    }
    & .aj-content {
      font-size: 18px;
      line-height: 30px;
      & .aj-cta {
        display: none;
        @media screen and (max-width: $screen-sm-max) {
          display: flex;
          margin-top: 34px;
          width: 100%;
        }
      }
    }
    & .aj-cta {
      font-size: 24px;
      line-height: 26px;
      margin-top: 60px;
      height: 70px;
      min-width: 300px;
      border: 2px solid #000000;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 36px;
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
      &:hover {
        border: 2px solid #000000;
        box-shadow: 4px 4px 0px #000000;
      }
    }
  }
  & .about-team {
    padding: 160px 0 120px;
    position: relative;
    @media screen and (max-width: $screen-sm-max) {
      padding: 120px 0 70px;
    }
    & .at-bg-img {
      position: absolute;
      top: 36px;
      left: 48px;
      @media screen and (max-width: $screen-sm-max) {
        justify-content: flex-start;
        top: 24px;
        left: 24px;
        width: 60px;
        height: auto;
      }
    }
    & .at-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      @media screen and (max-width: $screen-sm-max) {
        justify-content: flex-start;
      }
    }
    & .at-title {
      font-size: 150px;
      line-height: 195px;
      font-weight: 700;
      transform: rotate(270deg);
      word-break: keep-all;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 16px !important;
        transform: none;
      }
    }
    & .team-card {
      & .at-card {
        background: #f2fafe;
        border-radius: 12px;
        height: 260px;
        margin-bottom: 36px;
        padding: 20px 20px 25px;
        transition: 0.25s all ease-in-out;
        outline: 1px solid transparent;
        border: 1px solid transparent;
        &:hover {
          background: #fff !important;
          border: 1px solid #000000;
          outline: 1px solid #000000;
          box-shadow: 4px 4px 0px #000000;
          transition: 0.25s all ease-in-out;
        }
        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 16px;
        }
        & .at-card-img {
          background: #fafafa;
          border-radius: 12px;
          max-width: 100%;
          max-height: 150px;
          object-fit: cover;
          object-position: top;
        }
        & .at-card-name {
          font-size: 20px;
          line-height: 22px;
          font-weight: 700;
          margin-top: 20px;
        }
        & .at-card-department {
          font-size: 16px;
          line-height: 20px;
          margin-top: 8px;
          color: #555555;
        }
      }
    }
  }
}
