.leaderboard-table {
  & .ant-table {
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(6px);
    overflow: hidden;
  }
  & .ant-table-thead,
  & .ant-table-cell {
    padding: 24px 12px !important;
    @media screen and (max-width: $screen-sm-max) {
      padding: 12px 10px;
    }
    &:first-child {
      @media screen and (max-width: $screen-sm-max) {
        padding-left: 18px;
        padding-right: 0;
      }
    }
    &:nth-child(2) {
      @media screen and (max-width: $screen-sm-max) {
        padding-left: 4px;
        padding-right: 6px;
      }
    }
  }
  & .ant-table-wrapper .ant-table-thead > tr {
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }
  & .ant-table-wrapper .ant-table-thead > tr > th,
  & .ant-table-wrapper .ant-table-thead > tr > td {
    border-bottom: 1px solid #d7d7d7;
  }
  & .ant-table-wrapper .ant-table-tbody > tr > td {
    background: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid #d7d7d7;
    cursor: pointer;
  }
  & .ant-table-wrapper .ant-table-thead > tr > th::before {
    width: 0px !important;
  }
  & .rank {
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 16px;
    }
  }
  & .profile-pic {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    @media screen and (max-width: $screen-sm-max) {
      width: 40px;
      height: 40px;
    }
  }
  & .name {
    color: $text-color;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 16px;
    }
    & .date {
      text-decoration: underline;
    }
  }
  & .position {
    @media screen and (min-width: $screen-sm-max) {
      margin-left: 16px;
    }
  }
  & .blur-text {
    filter: blur(6px);
    -webkit-filter: blur(6px);
  }
  [data-text]::after {
    content: attr(data-text);
  }
  & .column-title {
    font-weight: 700;
    font-size: 20px;
    color: #a6a6a6;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 16px;
    }
  }
  & .table-space {
    gap: 24px !important;
    @media screen and (max-width: $screen-sm-max) {
      gap: 12px !important;
    }
  }
  & .contributebtn {
    border-radius: 20px;
    color: #fff;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    gap: 4px;
  }
  & .amount {
    text-align: center;
    display: flex;
    color: $text-color;
    font-weight: 500;
    font-size: 20px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 16px;
    }
  }
  & .amount-desc {
    color: #a6a6a6;
    font-size: 16px;
    text-align: center;
    align-items: center;
    display: flex;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 13px;
    }
  }
}
