.profile {
  & .profile-img-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  & .profile-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 9px;
  }
  & .btn-subscribe {
    background: #f0f0f0;
    border: 1px solid #f0f0f0;
    border-radius: 22px;
    height: 40px;
    padding: 6px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all 0.25s linear;
    &:hover {
      transition: all 0.25s linear;
      border-color: #bcbcbc;
    }
    &.dropdown {
      padding: 10px;
      width: 40px;
      cursor: pointer;
    }
  }
}

.subscribe-modal {
  &.profile {
    & .sm-subtitle {
      margin-top: 16px;
      margin-bottom: 0;
    }
    & .sm-form {
      margin-top: 40px;
      & .ant-input-textarea-show-count .ant-input-data-count {
        bottom: -24px;
      }
    }
  }
}

.profile-color-selector {
  width: 45px;
  height: 45px;
  border: 2px solid #f4f4f4;
  border-radius: 3px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease-in;
  & span {
    border-radius: 3px;
    display: flex;
    height: 100%;
    width: 100%;
  }
  & .ant-form-item-row {
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    & .ant-form-item-label {
      padding: 0;
    }
  }
  & label {
    width: 100%;
    cursor: pointer;
    color: #666 !important;
  }
  &.active {
    transition: all 0.3s ease-in;
    border: 2px solid #000000;
  }
}
.theme-container {
  border: 1px solid #f4f4f4;
  border-radius: 6px;
  padding: 12px;

  & .color-icon {
    background: #f7f7f7;
    border-radius: 3px;
    padding: 12px;
  }

  & .field-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}

.theme-modal {
  & .ant-modal-content {
    padding: 0;
    overflow: hidden;
  }
  & .img-container {
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $screen-sm-max) {
      border-radius: 24px 24px 0 0;
      position: absolute;
      top: -250px;
      left: 0;
      right: 0;
    }
  }
  & .tm-title {
    margin-top: 48px;
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
  }
  & .tm-desc {
    margin-top: 14px;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #666;
  }
  & .tm-cta {
    margin: 30px 0;
  }
  & .tm-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: $screen-sm-max) {
      background-color: #fff;
      padding: 0 20px;
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    }
  }
}

.profile-settings {
  & .config-type {
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 16px;
    }
  }
}

.profile-details-form {
  & .ant-input-group-addon {
    width: 20%;
    @media screen and (max-width: $screen-sm-max) {
      width: 25%;
    }
  }
  & .socials-input {
    display: flex;
    align-items: flex-start;
    position: relative;
    & .socials-input-options {
      width: 20%;
      & > .ant-form-item-row {
        width: 100%;
      }
    }
    & .socials-input-link {
      width: 80%;
      & > .ant-form-item-row {
        width: 100%;
      }
    }
    & .ant-select {
      &.ant-select-focused {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      & .ant-select-selector {
        height: 42px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        text-transform: capitalize;
        background-color: rgba(0, 0, 0, 0.02);
      }
      & .ant-select-item-option-content {
        text-transform: capitalize !important;
      }
    }
    & .ant-input-affix-wrapper {
      height: 42px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    & .remove-social {
      position: absolute;
      cursor: pointer;
      right: -25px;
      top: 12px;
    }
  }
  & .service-title {
    font-weight: 700;
    font-size: 16px;
  }
  & .suggested-tag {
    border-radius: 56px;
    background: #d1f6de;
    display: inline-flex;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    color: #004c39;
    font-size: 12px;
    font-weight: 700;
    height: 28px;
  }
  & .edit-btn {
    font-weight: 600;
    font-size: 16px;
    text-decoration: underline;
  }
  & .desc {
    color: #686b60;
    font-weight: 500;
    font-size: 14px;
  }
}

.beta-tag {
  background-color: #249651;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 2px 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-dark {
  color: #fff;
  background-color: $text-color;
  border: 1px solid $text-color;
  transition: all 0.6s ease-in-out;
  font-weight: 700;
  border-radius: 4px;
  &:disabled {
    background-color: rgba(20, 20, 20, 0.29);
    border: none;
    color: #fff;
  }
  &:hover,
  &:focus {
    transition: all 0.6s ease-in-out;
    border: 1px solid $text-color;
    background-color: #c3c3c3;
    color: #fff;
  }
}

.btn-dark-inverse {
  border: 1px solid $text-color;
  color: $text-color;
  background-color: transparent;
  transition: all 0.6s ease-in-out;
  font-weight: 700;
  border-radius: 6px;
  &:hover,
  &:focus {
    transition: all 0.6s ease-in-out;
    border: 1px solid $text-color;
    color: $text-color;
  }
}

.profile-img-crop-modal {
  & .ant-btn-primary {
    @extend .btn-dark;
  }
  & .ant-btn-default {
    @extend .btn-dark-inverse;
  }
}

.icon-external-link {
  width: 40px;
  height: 40px;
  font-size: 16px;
  background-color: #f0f0f0;
  border-radius: 22px;
  margin-left: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
}

.service-order {
  & .desc {
    color: #686b60;
    font-weight: 500;
    font-size: 14px;
  }
  & .service-list {
    padding: 0px;
    margin-top: 28px;
    overflow-x: hidden;
    & .service-item {
      background-color: #fff;
      padding-top: 16px;
      & .title {
        font-weight: 700;
        font-size: 16px;
      }
      & .type {
        color: #686b60;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
  ul {
    list-style: none;
  }
}
