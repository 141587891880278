.public-profile-v2 {
  height: 100%;
  min-height: 100vh;
  background-color: #fff;

  & .pp-coupon-toast {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    width: fit-content;
    top: 30px;
    border-radius: 24px;
    background: #008060;
    padding: 9px 21px 10px 21px;
    z-index: 999;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    @media screen and (max-width: $screen-sm-max) {
      top: 20px;
      font-size: 14px;
    }
  }
  & .pp-halloween-spider {
    position: fixed;
    top: 0;
    left: 16%;
    z-index: 999;
    @media screen and (max-width: $screen-sm-max) {
      right: 0;
      left: inherit;
      width: 180px !important;
    }
  }

  & .pp-halloween-pumpkin {
    position: fixed;
    bottom: 5%;
    left: 18%;
    z-index: 1;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }

  & .profilePicRef {
    margin-top: 24px !important;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 20px !important;
    }
  }

  & .pp-expert-details {
    margin-top: 32px;
    position: sticky;
    padding-bottom: 120px;
    top: 32px;

    @media screen and (max-width: $screen-sm-max) {
      margin-top: 14px;
      position: static;
      padding: 0 20px 32px;
      padding-right: 0;
    }

    & .claim-btn {
      display: flex !important;
      position: relative !important;
      margin-bottom: 18px;
      bottom: 0 !important;
      left: 0 !important;
      border-radius: 3px;
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%; /* 18.2px */
      padding: 8px 12px 8px 12px;
      height: 40px;
      color: #141414;
      gap: 4px;
      @media screen and (max-width: $screen-sm-max) {
        border-radius: 20px;
      }
      b {
        font-weight: 700;
      }
    }

    & .name {
      font-size: 36px !important;
      line-height: 48px;
      font-weight: 800;
      // margin-top: 24px !important;
      margin-bottom: 16px;

      @media screen and (max-width: $screen-sm-max) {
        // margin-top: 20px !important;
        font-size: 24px !important;
        line-height: 32px;
        margin-bottom: 0;
      }
    }

    & .verified-tag {
      position: absolute;
      bottom: 15px;
      right: 15px;
      z-index: 2;
      // box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
      filter: drop-shadow(4px 4px 24px rgba(0, 0, 0, 0.25));
      @media screen and (max-width: $screen-sm-max) {
        width: 24px;
        bottom: 8px;
        left: 100px;
      }
    }

    & .description {
      font-size: 18px !important;
      line-height: 27px;
      font-weight: 500;

      @media screen and (max-width: $screen-sm-max) {
        margin-top: 8px !important;
        font-size: 16px !important;
        line-height: 24px;
      }
    }

    & .pp-edit-block {
      & .pp-pic-edit {
        display: none;
        transition: all 0.3s ease-in;
      }
      &:hover {
        .pp-pic-edit {
          transition: all 0.3s ease-in;
          display: grid;
        }
      }
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 20px;
      }
    }

    & .profile-pic {
      border-radius: 50%;
      aspect-ratio: 1/1;
      object-fit: contain;
      z-index: 1;
      @media screen and (max-width: $screen-sm-max) {
        width: 130px;
      }
    }

    & .pp-year-recap-lottie {
      width: 70px !important;
      height: 70px !important;
      @media screen and (max-width: $screen-sm-max) {
        width: 50px !important;
        height: 50px !important;
      }
    }

    & .pp-christmas-cta {
      border-radius: 12px;
      background: #f5f3ec !important;
      border: 1px solid #000000 !important;
      padding: 10px 16px 10px 16px;
      height: 64px;
      margin-top: 24px;
      overflow: hidden;
      display: flex;
      align-items: center;
      & * {
        font-size: 22px !important;
        font-weight: 700;
        line-height: 150%; /* 27px */
        // width: 100%;
      }
      @media screen and (max-width: $screen-sm-max) {
        width: 88vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 16px 10px 0px;
      }
    }

    & .pp-extras {
      margin-top: 30px;
      width: 100%;
      margin-right: 12px;
      mask-image: linear-gradient(
        to right,
        transparent 0%,
        black 0% 90%,
        transparent 100%
      );
      display: flex;
      // justify-content: flex-end;
      // align-items: flex-end;
      margin-bottom: 4px;

      @media screen and (max-width: $screen-sm-max) {
        margin-top: 0;
      }
    }

    & .btn-extras {
      border-radius: 20px;
      height: 68px;
      display: flex;
      align-items: center;
      gap: 14px;

      @media screen and (max-width: $screen-sm-max) {
        height: 68px;
        gap: 12px;
      }
    }

    & .btn-subscribe {
      background: #000;
      border-color: #000;
      color: #fff;
      padding: 18px 24px;

      @media screen and (max-width: $screen-sm-max) {
        height: 40px;
        padding: 11px 12px;
        position: absolute;
        right: 0;
        top: 40px;
      }
    }

    & .booking-tag {
      padding: 16px;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);

      & .title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
      }

      & .subtitle {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  & .pp-layout {
    @media screen and (max-width: $screen-sm-max) {
      flex-direction: column;
    }

    & .claim-btn {
      position: fixed;
      bottom: 32px;
      left: 40px;
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }

    & .profile-sidebar {
      width: 33% !important;
      max-width: 33% !important;
      flex: 0 0 33% !important;

      @media screen and (max-width: $screen-sm-max) {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
        flex-direction: column;
      }
    }
  }

  & .pp-service-details {
    padding: 64px 0;
    min-height: 100vh;
    background-color: #efece3;

    @media screen and (max-width: $screen-sm-max) {
      width: 100% !important;
      padding: 20px 0;
    }

    & .pp-services {
      & .title {
        font-size: 32px;
        line-height: 48px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 24px;

        @media screen and (max-width: $screen-sm-max) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 20px;
          font-weight: 800;
        }
      }

      & .is-pinned {
        z-index: 9;
      }
    }
  }

  & .is-pinned {
    // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    z-index: 1;
    width: 100%;

    @media screen and (max-width: $screen-sm-max) {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  & .service-card {
    border-radius: 32px;
    background: rgba(255, 255, 255, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.95);
    min-height: 274px;
    margin-bottom: 40px;
    position: relative;
    box-shadow:
      0.5px 0.5px 4px rgba(0, 0, 0, 0.03),
      -0.5px -0.5px 4px rgba(0, 0, 0, 0.03);
    transition: all 0.5s ease-in-out;
    display: grid;
    align-items: start;
    @media screen and (max-width: $screen-sm-max) {
      min-height: 268px;
      margin-bottom: 24px;
    }

    &:hover {
      box-shadow: 12px 12px 32px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.5s ease-in-out;
      & .sc-arrow {
        background-color: #000;
        color: #fff;
        border-radius: 50%;
        transition:
          color,
          background-color 0.6s ease-in-out;
      }
    }

    &.edit-mode {
      &:hover {
        cursor: grab;
        & .edit-icon {
          display: grid;
          transition: all 0.4s ease-in-out;
        }
      }

      & .edit-icon {
        @media screen and (max-width: $screen-sm-max) {
          display: grid;
          top: -22px;
        }
      }
    }

    & .edit-icon {
      display: none;
      position: absolute;
      top: -26px;
      left: 10px;
      width: 48px;
      height: 48px;
      border-radius: 32px;
      border: 1px solid #e9e9e9;
      background: #fff;
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease-in-out;
      @media screen and (max-width: $screen-sm-max) {
        width: 40px;
        height: 40px;
      }
    }

    & .sc-product-tag {
      border-radius: 20px;
      background: rgba(12, 12, 12, 0.5);
      backdrop-filter: blur(2px);
      position: relative;
      bottom: 54px;
      left: 9px;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 125%; /* 20px */
      width: fit-content;
      padding: 12px 10px;
    }
    & .sc-thumbnail {
      width: 100%;
      border-radius: 32px 32px 0px 0px;
    }

    & .sc-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 24px 0;
      max-height: 74px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 20px 20px 4px;
      }
    }

    & .sc-icon {
      color: #666b60;
      background: #f7f7f7;
      border: 1px solid #ebeaeb;
      border-radius: 9px;
      padding: 10px 12px;
      font-size: 16px;
      line-height: 125%;
      height: 40px;
    }

    & .sc-details {
      padding: 24px 24px 10px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 0 20px 10px;
      }

      & .sc-name {
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;

        @media screen and (max-width: $screen-sm-max) {
          font-size: 20px;
          line-height: 26px;
          margin-top: 12px;
          margin-bottom: 0;
        }
      }

      & .sc-description {
        color: #666b60;
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;

        @media screen and (max-width: $screen-sm-max) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    & .sc-footer {
      padding: 20px 24px;
      border-top: 1px solid #ebeaeb;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-self: flex-end;

      @media screen and (max-width: $screen-sm-max) {
        padding: 16px 20px;
      }
    }

    & .sc-tags {
      display: flex;
      align-items: center;
      color: #666b60;
      font-size: 16px;
      line-height: 20px;

      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  & .pp-about {
    @media screen and (max-width: $screen-sm-max) {
      padding: 0 20px 80px;
    }

    & .pp-title {
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;

      @media screen and (max-width: $screen-sm-max) {
        font-size: 24px;
        line-height: 32px;
        font-weight: 800;
      }
    }

    & .pp-description {
      font-size: 18px;
      line-height: 32px;
      font-weight: 500;
      white-space: pre-line;

      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
        line-height: 28px;
        margin-top: 20px;
      }
    }

    & .social-media {
      padding: 22px;
      width: 80px;
      height: 80px;
      background: rgba(255, 255, 255, 0.95);
      border: 1px solid #ebeaeb;
      box-shadow:
        0.5px 0.5px 4px rgba(0, 0, 0, 0.03),
        -0.5px -0.5px 4px rgba(0, 0, 0, 0.03);
      border-radius: 16px;

      @media screen and (max-width: $screen-sm-max) {
        padding: 14px;
        width: 60px;
        height: 60px;
      }

      & .social-media-icons {
        font-size: 36px;

        @media screen and (max-width: $screen-sm-max) {
          font-size: 28px;
        }
      }
    }
  }

  & .pp-ratings {
    margin-top: 40px;
    margin-bottom: 70px;

    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 50px;
      margin-top: 10px;
      padding: 0 20px;
    }

    & .pp-title {
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;

      @media screen and (max-width: $screen-sm-max) {
        font-size: 24px;
        line-height: 32px;
        font-weight: 800;
        margin-bottom: 0;
      }
    }

    & .pp-testimonial-edit-icon {
      display: none;
      position: absolute;
      top: -18px;
      right: -18px;
      width: 48px;
      height: 48px;
      border-radius: 32px;
      border: 1px solid #e9e9e9;
      background: #fff;
      cursor: pointer;
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease-in-out;
      z-index: 2;
      @media screen and (max-width: $screen-sm-max) {
        width: 40px;
        height: 40px;
      }
    }

    & .share-cta {
      border-radius: 12px;
      background: #f7f7f7;
      border-color: #f7f7f7;
      align-items: center;
      height: 56px;
      display: flex;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      padding: 16px 36px;
      color: #141414;
      font-size: 18px;
      font-weight: 700;
      line-height: 130%; /* 23.4px */
      @media screen and (max-width: $screen-sm-max) {
        padding: 8px;
        height: 40px;
      }
    }

    & .pp-ratings-block {
      margin-top: 16px;
      background: rgba(255, 255, 255, 0.95);
      border: 1px solid #ebeaeb;
      box-shadow:
        0.5px 0.5px 4px rgba(0, 0, 0, 0.03),
        -0.5px -0.5px 4px rgba(0, 0, 0, 0.03);
      border-radius: 32px;
      height: 176px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 12px;
      overflow: visible !important;
      transition: all 0.25s linear;

      &:focus,
      &:hover {
        & .pp-testimonial-edit-icon {
          display: flex;
          transition: all 0.25s linear;
        }
      }

      @media screen and (max-width: $screen-sm-max) {
        gap: 8px;
        aspect-ratio: 1/1;
        height: auto;
        align-items: flex-start;
        padding: 0 12px;

        & img {
          width: 80%;
          margin: 0 auto;
          height: auto;
        }
      }

      & .rb-values {
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;

        @media screen and (max-width: $screen-sm-max) {
          font-size: 24px;
          line-height: 24px;
        }
      }

      & .rb-prop {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #666b60;
        text-transform: uppercase;

        @media screen and (max-width: $screen-sm-max) {
          font-size: 12px;
          line-height: 16px;
        }
      }

      & .christmas-balls-lottie {
        width: 160px;
        @media screen and (max-width: $screen-sm-max) {
          top: -20px;
          left: -4px;
          width: 100%;
        }
      }
    }
  }

  & .pp-thanksgiving-cta {
    border-radius: 12px;
    background: #fff;
    color: #141414;
    padding: 11px 7px 11px 16px;
    font-weight: 500;
    height: 100px;
    margin-top: 30px;
    line-height: 180%; /* 27px */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    & .ant-typography {
      font-size: 18px !important;
    }
    @media screen and (max-width: $screen-sm-max) {
      width: 100%;
      height: 50px;
      white-space: no-wrap;
      padding: 8px 12px;
      justify-content: space-between;
      width: 90vw;
      border-radius: 12px;
    }
  }

  & .logo-section {
    position: relative;
  }

  & .pp-pic-edit {
    position: absolute;
    bottom: 4px;
    left: 16px;
    border: 1px solid #e9e9e9;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
    height: 48px;
    width: 48px;
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    align-items: center;
    justify-content: center;
    z-index: 1;

    @media screen and (max-width: $screen-sm-max) {
      left: 0px;
      z-index: 1;
      height: 40px;
      width: 40px;
      font-size: 16px;
      padding: 0;
    }
  }

  & .highlights-container {
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 20px;
    }
    .swiper-slide {
      width: 100% !important;
    }

    & .highlight-card {
      border-radius: 32px;
      border: 1px solid #ebeaeb;
      background: rgba(255, 255, 255, 255);
      transition: all 0.4s ease-in-out;
      min-height: 175px;
      padding: 24px 34px 24px 24px;
      position: relative;
      transition: all 0.4s ease-in-out;
      display: grid;
      align-items: stretch;
      margin-bottom: 40px;
      width: 100%;
      @media screen and (max-width: $screen-sm-max) {
        margin-bottom: 16px;
        min-height: 124px;
        padding: 12px 16px;
        border-radius: 18px;
      }

      & .desc {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        white-space: pre-wrap;
      }

      & .sec-desc {
        color: #666b60;
        font-size: 12px;
        font-weight: 500;
        margin-top: 6px;
        line-height: 150%;
      }

      &:hover {
        & .sc-arrow {
          background-color: #000;
          color: #fff;
          border-radius: 50%;
          transition:
            color,
            background-color 0.6s ease-in-out;
        }
      }
      & .campaign-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
      }
      & .campaign-desc {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        margin-top: 18px;
      }
      & .ant-progress-line {
        margin-bottom: 4px;
      }
      & .campaign-sec-desc {
        color: #666b60;
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
      }
      &.edit-mode {
        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 40px;
        }

        &:hover {
          border-color: transparent;
          box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
          transition: all 0.4s ease-in-out;

          & .edit-icon {
            display: grid;
            transition: all 0.4s ease-in-out;
          }
        }

        & .edit-icon {
          cursor: pointer;

          @media screen and (max-width: $screen-sm-max) {
            display: grid;
            top: -34px;
          }
        }
      }

      & .edit-icon {
        display: none;
        position: absolute;
        top: -32px;
        left: 10px;
        width: 48px;
        height: 48px;
        border-radius: 32px;
        border: 1px solid #e9e9e9;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease-in-out;
      }

      & .highlight-image {
        width: 100%;
        height: 127px;
        object-fit: contain;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
        @media screen and (max-width: $screen-sm-max) {
          height: 100px;
          border-radius: 18px;
          background: #f7f7f7;
        }
      }
    }

    & .swiper-pagination {
      @media screen and (max-width: $screen-sm-max) {
        bottom: -2px;
        & .swiper-pagination-bullet-active {
          background-color: $text-color;
        }
      }
    }
  }

  & .smart-discount-timer {
    position: fixed;
    top: 20px;
    right: 29%;
    z-index: 1;
    line-height: 150%;
    border-radius: 24px;
    background: #008060;
    color: #fff;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    & .timer {
      border-radius: 24px;
      font-weight: 700;
      padding: 2px 8px;
      border: 1px solid #e0dfdd;
      color: #fff;
    }
  }

  & .snow-time {
    max-width: 100%;
    overflow: hidden;
  }

  & .snow {
    position: absolute;
    top: -1.5rem;
    animation: snowfall 2.9s infinite both ease-in;
    z-index: 99;
    &:nth-child(1) {
      left: 50%;
      font-size: 1.75rem;
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
    }
    &:nth-child(2) {
      left: 22%;
      font-size: 0.9rem;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      left: 13%;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s;
    }
    &:nth-child(4) {
      left: 95%;
      -webkit-animation-delay: 0.7s;
      animation-delay: 0.7s;
    }
    &:nth-child(5) {
      left: 29%;
      font-size: 1.25rem;
      -webkit-animation-delay: 0.9s;
      animation-delay: 0.9s;
    }
    &:nth-child(6) {
      left: 60%;
      -webkit-animation-delay: 1.1s;
      animation-delay: 1.1s;
    }
    &:nth-child(7) {
      left: 85%;
      -webkit-animation-delay: 1.3s;
      animation-delay: 1.3s;
    }
    &:nth-child(8) {
      left: 45%;
      font-size: 0.9rem;
      -webkit-animation-delay: 1.5s;
      animation-delay: 1.5s;
    }
    &:nth-child(9) {
      left: 93%;
      -webkit-animation-delay: 1.7s;
      animation-delay: 1.7s;
    }
    &:nth-child(10) {
      left: 26%;
      -webkit-animation-delay: 1.9s;
      animation-delay: 1.9s;
    }
    &:nth-child(11) {
      left: 10%;
      font-size: 1.5rem;
      -webkit-animation-delay: 2.1s;
      animation-delay: 2.1s;
    }
    &:nth-child(12) {
      left: 90%;
      -webkit-animation-delay: 2.3s;
      animation-delay: 2.3s;
    }
    &:nth-child(13) {
      left: 6%;
      -webkit-animation-delay: 2.5s;
      animation-delay: 2.5s;
    }
    &:nth-child(14) {
      left: 64%;
      font-size: 1.25rem;
      -webkit-animation-delay: 2.7s;
      animation-delay: 2.7s;
    }
    &:nth-child(15) {
      left: 77%;
      -webkit-animation-delay: 2.9s;
      animation-delay: 2.9s;
    }
  }

  @-webkit-keyframes snowfall {
    0% {
      -webkit-transform:
        translate3d(0, 0, 0),
        skew(10deg) rotateX(0) rotateY(0);
      transform:
        translate3d(0, 0, 0),
        skew(10deg) rotateX(0) rotateY(0);
      opacity: 1;
    }

    65% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(-120px, 100vh, 0) skew(-10deg)
        rotateX(50deg) rotateY(50deg);
      transform: translate3d(-120px, 100vh, 0) skew(-10deg) rotateX(50deg)
        rotateY(50deg);
      opacity: 0;
    }
  }

  @keyframes snowfall {
    0% {
      -webkit-transform:
        translate3d(0, 0, 0),
        skew(10deg) rotateX(0) rotateY(0);
      transform:
        translate3d(0, 0, 0),
        skew(10deg) rotateX(0) rotateY(0);
      opacity: 1;
    }

    65% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(-120px, 100vh, 0) skew(-10deg)
        rotateX(50deg) rotateY(50deg);
      transform: translate3d(-120px, 100vh, 0) skew(-10deg) rotateX(50deg)
        rotateY(50deg);
      opacity: 0;
    }
  }
}

.service-filters {
  background-color: #efece3;
  z-index: 1;
  overflow: hidden;
  width: 100%;

  & .service-filters-container {
    padding-top: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    overflow-x: auto;
    z-index: 9;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    @media screen and (max-width: $screen-sm-max) {
      padding: 16px 20px;
    }
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
  & .filter-item {
    border-radius: 12px;
    background: transparent;
    border: 1px solid $text-color;
    display: flex;
    height: 54px;
    padding: 15px 24px 16px 24px;
    font-size: 18px;
    line-height: 129%; /* 23.22px */
    cursor: pointer;
    margin-bottom: 12px;
    white-space: nowrap;
    transition: all 0.4s ease-in-out;
    @media screen and (max-width: $screen-sm-max) {
      padding: 10px 20px;
      height: 40px;
      font-size: 15px;
      white-space: nowrap;
      margin-bottom: 0;
    }
    &.active {
      // filter: invert(1);
      background-color: #000;
      color: #fff;
      font-weight: 700;
      transition: all 0.25s ease-in-out;
      &:hover,
      &:focus {
        transition: all 0.4s ease-in-out;
        background-color: #000;
        color: #fff;
      }
    }
    &:hover,
    &:focus {
      transition: all 0.4s ease-in-out;
      background: rgba(0, 0, 0, 0.06);
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    // top: -1px;
    // margin-bottom: 10px;
  }
}

.pp-testimonials {
  padding: 40px 0 10px;

  & .testimonials-container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    display: grid;
    grid-gap: 24px;
    grid-auto-flow: dense;
    width: 100%;
    @media screen and (max-width: $screen-sm-max) {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }

  & .pp-review-container {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: 100%;
    gap: 40px;
    margin-top: 30px;
    @media screen and (max-width: $screen-sm-max) {
      gap: 20px;
      margin-top: 24px;
    }
  }
  & .pp-testimonials-btn {
    padding: 13px 26px;
    border-radius: 6px;
    height: 48px;
    width: auto;
    display: inline-flex;
    margin: 40px auto 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    background: #fdfbf9;
    border: 1px solid #ebeaeb;
    box-shadow:
      -0.5px -0.5px 4px rgba(0, 0, 0, 0.03),
      0.5px 0.5px 4px rgba(0, 0, 0, 0.03);
    transition: all 0.25s linear;
    &:hover,
    &:focus {
      border: 1px solid #ebeaeb !important;
      box-shadow: 12px 12px 24px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.25s linear;
    }
  }

  & .t-card {
    padding: 38px 24px 24px;
    position: relative;
    background: #fdfbf9;
    border: 1px solid #ebeaeb;
    box-shadow:
      -0.5px -0.5px 4px rgba(0, 0, 0, 0.03),
      0.5px 0.5px 4px rgba(0, 0, 0, 0.03);
    border-radius: 32px;
    display: inline-flex;
    flex-flow: column;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;
    gap: 24px;
    transition: all 0.25s linear;
    &.long {
      min-height: 500px;
    }

    &:hover,
    &:focus {
      box-shadow: 12px 12px 24px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.25s linear;
    }
  }

  & .t-card-text {
    font-size: 18px;
    line-height: 32px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  & .t-card-details {
    @media screen and (max-width: $screen-sm-max) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  & .t-card-time {
    color: #797979;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%; /* 18px */
  }

  & .t-card-quote {
    color: #efe3d2;
    position: absolute;
    top: 6px;
    left: 20px;
  }
}

.inline-edit-input.ant-input {
  background-color: transparent;
  // border: 1px solid transparent;
  border: none;
  color: inherit;
  // font-size: inherit;
  // line-height: inherit;
  // border-radius: 20px;
  // padding: 2px;
  padding: 0;

  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
}

.pp-login-nav {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.98);
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.24);
  height: 64px;
  position: fixed;
  bottom: 20px;
  left: 45%;
  width: 615px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  z-index: 99;
  padding: 12px;

  @media screen and (max-width: $screen-sm-max) {
    height: 68px;
    padding: 14px 12px;
    &.dashboard {
      left: initial;
      right: 8px;
      bottom: 20px;
      height: 70px;
    }
  }

  &.dashboard {
    width: 160px;
    padding: 8px;
    left: 20px !important;
    height: 80px;
  }

  & .nav-btn-section {
    & .nav-btn {
      border-radius: 6px;
      background: #f3f3f3;
      border: 1px solid #e3e3e3;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      transition: all 0.25s ease-in-out;
      &:hover,
      &:focus {
        transition: all 0.25s ease-in;
        background: #e4e4e4 !important;
        border-color: #e4e4e4 !important;
      }

      & .theme-box {
        width: 28px;
        height: 28px;
        border-radius: 4px;
        @media screen and (max-width: $screen-sm-max) {
          width: 24px;
          height: 24px;
        }
      }

      @media screen and (max-width: $screen-sm-max) {
        padding: 6px 0;
        width: 36px !important;
        height: 36px;
        border-radius: 6px;
      }
    }
  }

  &.invert {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 4px 4px 24px rgba(255, 255, 255, 0.12);

    @media screen and (max-width: $screen-sm-max) {
      background: #fff;
      box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    right: 12px;
    left: 12px;
    z-index: 99;
    height: 64px;
    width: auto;
  }
}

.home-btn {
  width: auto !important;
  border-radius: 6px;
  background: #ece5d9;
  border-color: #ece5d9 !important;
  padding: 9px 12px;
  height: 40px;
  transition: all 0.25s ease-in;
  &:focus,
  &:hover {
    transition: all 0.25s ease-in;
    background: #eeddc1 !important;
    border-color: #eeddc1 !important;
  }

  @media screen and (max-width: $screen-sm-max) {
    padding: 6px 0;
    width: 36px !important;
    height: 36px;
  }
}

.share-btn {
  border-radius: 6px;
  background: #015840;
  border-color: #015840;
  color: #d8e747;
  text-align: center;
  height: 40px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.25s ease-in;
  &:focus,
  &:hover {
    transition: all 0.25s ease-in;
    background: darken($color: #015840, $amount: 3) !important;
    border-color: darken($color: #015840, $amount: 3) !important;
    color: #d8e747 !important;
  }

  @media screen and (max-width: $screen-sm-max) {
    padding: 6px 0;
    width: 36px;
    height: 36px;
  }
}

.portal-btn {
  cursor: pointer;
  padding: 10px;
  border-radius: 32px;
  background: #ece5d9;
  // width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  @media screen and (max-width: $screen-sm-max) {
    border-radius: 14px;
    padding: 6px;
    gap: 8px;
  }
}

.stats-swiper {
  @media screen and (max-width: $screen-sm-max) {
    & .swiper {
      mask-image: linear-gradient(
        to right,
        transparent 0%,
        black 20% 80%,
        transparent 100%
      );
    }
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 4px;
  }
}

.stats {
  display: flex;
  @media screen and (max-width: $screen-sm-max) {
    display: none;
    transform: scale(0.9) !important;
    transition: all 0.5s ease-in-out;
    & .s-block {
      & .ant-typography {
        opacity: 0;
        transition: all 0.5s ease-in-out;
      }
    }
    &.swiper-slide-active {
      opacity: 1;
      transform: scale(1) !important;
      transition: all 0.5s ease-in-out;
      & .ant-typography {
        opacity: 1;
        transition: all 0.5s ease-in-out;
      }
    }
  }
  & .s-block {
    border-radius: 14px;
    background-color: #fff !important;
    box-shadow: 0.88px 0.88px 10.56px 0px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    padding: 5px;
    flex-direction: column;
    width: 72px;
    height: auto;
    object-fit: contain;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 129%; /* 15.48px */
    @media screen and (max-width: $screen-sm-max) {
      min-width: 75px;
      padding: 12px 7px 13px 8px;
      font-size: 12px;
      font-weight: 500;
      line-height: 129%; /* 15.48px */
    }
    & .title {
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 130%; /* 15.6px */
      margin-top: 5px;
    }
    & .value {
      font-size: 24px;
      font-weight: 700;
      line-height: 130%; /* 41.28px */
      text-align: center;
      background-color: #e3e8ed;
      padding: 14px 0 14px;
      border-radius: 12px 12px 0 0;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}

.public-service-empty {
  display: flex;
  border-radius: 32px;
  background: #f9fafc;
  color: #939393;
  align-items: center;
  padding: 30px;
  justify-content: center;
  font-size: 24px !important;
  gap: 16px;
  line-height: 125%; /* 30px */
  cursor: pointer;
  @media screen and (max-width: $screen-sm-max) {
    font-size: 18px !important;
    padding: 20px;
    gap: 12px;
  }
}

.halloween-modal {
  & .ant-modal-content {
    border-radius: 16px;
    padding: 0;
    overflow: hidden;
  }
  & .hm-top-section {
    padding: 22px 22px 0px 22px;
  }
  & .hm-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 110%; /* 35.2px */
    letter-spacing: -0.96px;
  }
  & .hm-subtitle {
    color: rgba(20, 20, 20, 0.69);
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin-bottom: 0;
  }
  & .hm-btn {
    height: 48px;
    // margin-top: 8px;
  }
  & .hm-img {
    width: 100%;
    height: auto;
  }
}

.pp-loader {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #fff;
  z-index: 999;
}

.start-page-btn {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.2) !important;
  border: none !important;
  color: #141414 !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
  b {
    font-weight: 700 !important;
  }
}
