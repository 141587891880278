.onboarding-nav {
  margin-top: 16px;
  & .onboarding-back-btn {
    font-size: 28px;
    margin-right: 15px;
    cursor: pointer;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 24px;
      margin-right: 0;
      margin-left: 12px;
      margin-top: 10px;
      z-index: 999;
    }
  }
  &.skip-btn {
    @media screen and (max-width: $screen-sm-max) {
      padding-top: 0;
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    padding: 24px 0 0;
    & .ant-progress-bg {
      height: 4px !important;
    }
  }
}

.progress-bar {
  @media screen and (max-width: $screen-sm-max) {
    margin-left: -8% !important;
  }
  .stepper-wrapper {
    font-family: Arial;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #f2f2f2;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }

  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 10px solid #f2f2f2;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }

  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    top: 40%;
    margin-bottom: 6px;
  }

  .step-image {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 15%;
    margin-bottom: 6px;
    width: 36px;
    height: 36px;
  }
  .stepper-item.active {
    font-weight: bold;
  }

  .stepper-item.completed .step-counter {
    // background-color: #4bb543;
  }

  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 10px solid #000000;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }

  .first::after {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .stepper-item:first-child::before {
    // content: "";
    display: none;
    // margin-left: -50px;
  }
  .stepper-item:last-child::after {
    content: none;
  }

  .progress-lottie {
    position: absolute;
    top: -22px;
  }
  .progress-dot {
    width: 16px;
    height: 16px;
    background: #e44332;
    border: 1px solid #000000;
    border-radius: 4px;
    transform: rotate(-45deg);
  }
}

.onboarding-checkbox {
  justify-content: flex-start;
  flex-wrap: wrap;
  & .radio-checkbox-item {
    border-radius: 20px !important;
    border: 1px solid #dddddd;
    outline: 1px solid transparent;
    transition: all 0.25s ease-in;
    margin-bottom: 10px;
    width: auto;
    &.ant-radio-button-wrapper-checked {
      border: 1px solid #222222 !important;
      outline: 1px solid #222222 !important;
      color: #222;
      background-color: #f7f7f7;
      box-shadow: none;
      font-weight: 600;
      transition: all 0.25s ease-in;
    }
    &:hover,
    &:focus {
      color: #222;
    }
  }
}
.onboarding {
  min-height: 100vh;
  & .content-box {
    display: flex;
    min-height: 85vh;
    justify-content: center;
  }
  & .add-slots {
    margin-top: 0;
  }

  & .onboarding-main {
    margin: 32px 0 40px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 30px;
    }
  }
  & .onboarding-content {
    & .onboarding-header {
      font-size: 34px;
      line-height: 44px;
      font-weight: 600;
      margin-bottom: 12px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 23px;
        line-height: 36px;
      }
    }
    & .onboarding-subheader {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 40px;
      color: #848484;
    }
    & .onboarding-description {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 30px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 40px;
      }
    }
    & .ant-form-item {
      margin-bottom: 32px;
      & .ant-form-item-label {
        padding-bottom: 12px;
        & > label {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          margin: 0;
        }
      }
    }
    & .preparing-text {
      font-weight: 700;
      font-size: 20px;
    }
    & .service-type {
      font-weight: 500;
      font-size: 16px;
      padding: 10px 0px;
    }
    & .input-expertise {
      border: 1px solid #dddddd;
      border-radius: 4px;
      & .submit-expertise {
        font-weight: 600;
        font-size: 14px;
        border-radius: 6px;
        margin: 6px;
      }
    }
  }

  & .onboarding-card {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    height: 56px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #ddd;
    outline: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    &.active {
      border: 2px solid #222;
      background-color: #f7f7f7;
      font-weight: 600;
      &:hover,
      &:focus {
        border: 2px solid #222;
        background-color: #f7f7f7;
      }
    }
    &:hover,
    &:focus {
      border: 1px solid #222;
      background-color: #f7f7f7;
      transition: all 0.2s ease-in-out;
    }
  }

  & .onboarding-action {
    display: flex !important;
    justify-content: flex-end;
    z-index: 1;
    @media screen and (max-width: $screen-sm-max) {
      position: fixed !important;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px 20px;
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
      background-color: #fff;
    }
  }

  & .onboarding-cta {
    height: 48px;
    padding: 0 60px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 0;
    }
  }
  & .onboarding-bottom {
    background-color: #fff;
    position: sticky;
    bottom: 0;
    z-index: 9;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    & .nav-content {
      padding: 16px 0px;
      & .back-icon {
        padding: 0;
        font-size: 1.5rem;
        font-weight: 400;
        color: #141414;
      }
    }
  }
}

.general-info {
  & .reason-field {
    .radio-checkbox {
      display: block !important;
    }
    & .radio-checkbox-item {
      border-radius: 4px !important;
      border: 1px solid #dddddd;
      outline: 1px solid transparent;
      transition: all 0.25s ease-in;
      margin-bottom: 10px;
      width: 100%;
      height: 48px;
      &.ant-radio-button-wrapper-checked {
        border: 1px solid #222222 !important;
        outline: 1px solid #222222 !important;
        color: #222;
        background-color: #f7f7f7;
        box-shadow: none;
        font-weight: 600;
        transition: all 0.25s ease-in;
      }
      &:hover,
      &:focus {
        color: #222;
      }
    }
  }
  & .error-number {
    background: #ffffff;
    border: 1px solid #e0b4b4;
    color: #9f3a38;
  }

  & .option-tag-container {
    border-radius: 3px;
    padding: 14px 24px;
    margin-top: 10px;
    height: 48px;
    margin-right: 4px;
  }
  & .option-tag {
    border: 1px solid #dddddd;
    outline: 1px solid transparent;
  }
  & .option-tag-selected {
    border: 1px solid #222222 !important;
    outline: 1px solid #222222 !important;
  }

  & .spam-message {
    font-size: 0.85em;
    color: #808080;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  & .social-url-message {
    background-color: #f7f7f7;
    border-radius: 6px;
    display: flex;
    align-items: flex-start;
    margin-top: 12px;
    padding: 14px 12px;
    & img {
      margin-right: 10px;
    }
  }
  & .social-error {
    align-items: center;
    background-color: #fdf3e7;
    display: flex;
    margin: 10px 0 40px 0px;
    & .social-error-text {
      padding-left: 12px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      @media screen and (min-width: $screen-sm-max) {
        padding: 24px;
      }
    }
  }

  & .earning-tag {
    background: #f7f7f7;
    border-radius: 24px;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 24px;
  }
  & .form-subheader {
    color: #919191;
    font-weight: 500;
    font-size: 14px;
  }
}
.error-skip-btn {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
}
.general-error {
  color: #9f3a38;
}

.onboard-earning {
  background-color: #ffe6e3;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  & .content {
    margin-top: 70px;
    text-align: center;
    & .heading {
      font-weight: 700;
      font-size: 64px;
    }
    & .desc {
      font-weight: 500;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 12px;
    }
    & .earning-card {
      background: #ffffff;
      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
      padding: 12px;
      margin-top: 36px;
      text-align: left;
      & .earning-text {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
        font-size: 14px;
      }
      & .earning-number {
        font-weight: 700;
        font-size: 24px;
      }
    }
  }
}
