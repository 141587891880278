.sidebar-menu {
  background-color: #f7f6f2 !important;
  border: none !important;
  & .menu-item {
    margin-bottom: 4px;
    height: 40px !important;
    background: none !important;
    &.hide {
      display: none;
    }
  }
  & .ant-menu-item {
    padding-inline: 0px;
    padding-left: 0 !important;
    margin: 0;
    margin-bottom: 2px;
  }
  & .ant-menu-item-selected {
    background: none !important;
  }
  & .menu-sub-menu {
    margin-bottom: 0px;
    padding: 0;
    & .ant-menu-submenu-title {
      padding: 7px 12px !important;
      border-radius: 8px;
      height: 40px;
      display: flex;
      align-items: center;
      transition: all 0.5s ease-in-out;
      margin: 0;
      & .menu-logo {
        width: 22px;
        height: 22px;
        margin-right: 12px;
      }
      & svg {
        color: $text-color;
        flex-shrink: 0;
      }
      & .menu-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 27px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 56%;
      }
    }
    & .ant-menu-sub {
      margin-top: 4px;
      background: none !important;
      margin-left: 34px;
    }
  }
  & .menu-link {
    padding: 7px 12px !important;
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: all 0.5s ease-in-out;
    & .menu-logo {
      width: 22px;
      height: 22px;
      margin-right: 12px;
    }
    & svg {
      color: $text-color;
      flex-shrink: 0;
    }
    & .menu-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 27px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85%;
    }

    & .error-icons {
      color: $error-color;
    }
    &.active {
      background-color: rgba(140, 83, 0, 0.1) !important;
      transition: all 0.5s ease-in-out;
      color: #0a171f;
      font-weight: 600;
      pointer-events: none;
      & .menu-title {
        color: #5b3600;
        font-weight: 700;
      }
      & .menu-logo {
        color: #5b3600 !important;
      }
    }
    &:hover {
      background-color: rgba(140, 83, 0, 0.1);
      transition: all 0.5s ease-in-out;
    }
  }
}

.main-sidebar {
  background-color: #fcf9f9;
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 16px 16px 10px;
  @extend .sidebar-menu;
  & .ant-layout-sider-children {
    padding-bottom: 92px;
    height: auto;
  }
}

.sidebar-profile-container {
  background-color: #fff;
  border-radius: 12px;
  & .sidebar-profile-divider {
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }

  & .nav-share {
    border: 1.5px solid #8c5300;
    background: #fff;
    border-radius: 18px;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    height: 56px;

    @media screen and (max-width: $screen-sm-max) {
      background: #f8f7f2;
      border: 1.5px solid #e7e0c2;
    }
  }
  & .share-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #f3f3f1;
    border-radius: 20px;
    border: none;
    font-weight: 700;
    font-size: 16px;
    &:hover,
    &:focus {
      background-color: darken(#f3f3f1, 5%);
    }
  }
}

.sidebar-profile-share {
  padding: 12px 16px 16px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $screen-sm-max) {
    border-bottom: 1px solid #f7f7f7;
    padding: 12px 6px 16px 6px;
  }
}

.sidebar-profile-data {
  display: flex !important;
  align-items: center;
  padding: 12px;
  & .copy-profile {
    font-size: 18px;
    cursor: pointer;
  }
  & .user-profile {
    border-radius: 50%;
    object-fit: contain;
  }
  & .sidebar-profile-link {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    white-space: break-spaces;
    word-break: break-word;
    @media screen and (max-width: $screen-sm-max) {
      font-weight: 500;
      font-size: 16px;
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    padding: 14px 4px 8px 4px;
  }
}

.mobile-dashboard-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $mobile-nav-height;
  max-height: $mobile-nav-height;
  background: rgba(250, 250, 250);
  z-index: 99;

  & .top-supporters-banner {
    padding: 10px;
    width: 100%;
    cursor: pointer;
    @media screen and (max-width: $screen-sm-max) {
      width: 200px;
    }
  }

  & .nav-mobile-base {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid #e3e3e3;
    & .back-btn-mobile {
      width: 32px;
      height: 32px;
    }
    & .mobile-logo {
      position: absolute;
      left: 20px;
      top: 10px;
      cursor: pointer;
    }
    & .menu-btn {
      padding: 6px 0;
      height: auto;
    }
    & .mobile-nav-title {
      font-size: 15px;
      line-height: 28px;
      max-width: 80%;
    }
    & .right-btn {
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }
  & .dashboard-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 72px;
    background: #ffffff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.04);
    &.hidden {
      transition: all 0.25s linear;
      display: none;
    }
  }
}
.nav-list {
  & .bn-link {
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 15px;
    &.active {
      pointer-events: none;
      cursor: pointer;
      border-color: $success-color;
      & .bn-icon,
      & .bn-title {
        color: $success-color;
      }
    }
    & .bn-title {
      margin-top: 15px;
    }
    & .bn-icon {
      color: #434343;
    }
  }
  & .ant-badge {
    width: 100%;
  }
}

.bottom-nav-list {
  height: 100%;
  padding: 13px 0 14px 0;
  & .ant-badge,
  & .bn-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 auto;
  }
  & .bn-link {
    height: 100%;
    &.active {
      pointer-events: none;
      cursor: pointer;

      & .bn-icon-container {
        border-color: $selected-state-color;
      }

      & .bn-icon,
      & .bn-title {
        color: $selected-state-color;
        font-weight: 700;
      }
    }
    & .bn-icon {
      color: #434343;
    }
    & .bn-title {
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      line-height: 18px;
      margin-top: 8px;
      font-weight: 500;
    }
  }
  &.drawer {
    border-top: 1px solid #f4f4f4;
    padding-top: 4px;
    & .new-tag-badge {
      padding: 2px 8px;
      background-color: #d4f5e3;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.15px;
      color: #3e8750;
      border-radius: 20px;
    }
    & .count-badge {
      padding: 2px 8px;
      background-color: #fdf0ef;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.15px;
      color: #e44332;
      border-radius: 20px;
    }
    & .bn-link {
      background: rgba(255, 255, 255, 0.95);
      border: 1px solid #ebeaeb;
      border-radius: 20px;
      margin-top: 8px;
      height: auto;
      &.active {
        pointer-events: none;
        cursor: pointer;
        border-color: $success-color;
        & .bn-icon,
        & .bn-title {
          color: $success-color;
        }
      }
      & .bn-title {
        font-size: 16px;
        font-weight: 700;
        color: $text-color;
        text-align: center;
        margin: 0px 0px 0px 16px;
      }
      & .top-tabs {
        width: 100%;
        padding: 14px 16px;
      }
    }
    & .bottom-bn-link {
      & .bn-title {
        font-size: 15px;
        font-weight: 500;
        color: $text-color;
        text-align: center;
        margin: 0px 0px 0px 16px;
      }
      &.active {
        pointer-events: none;
        cursor: pointer;
        border-color: $success-color;
        & .bn-icon,
        & .bn-title {
          color: $success-color;
        }
      }
    }
    & .bottom-tabs {
      width: 100%;
      height: 56px;
      padding: 14px 12px;
    }
  }
}

.mobile-drawer {
  & .ant-drawer-body {
    padding: 20px 16px 30px;
    background-color: #fafafa !important;
    overflow: hidden;
    @extend .sidebar-menu;
  }
  &.ant-drawer-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.query-number {
  color: #fff;
  background-color: #e44332;
  font-size: 12px;
  min-width: 22px;
  height: 22px;
  border-radius: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0 8px;
  & * {
    color: #fff;
  }
}

.share-card {
  max-width: 100%;
  width: 100%;
  border-radius: 14px;
  min-height: 92px;
  height: 96px;
  background-color: #fff;

  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    display: block;
    height: 500px;
    width: 500px;
    position: absolute;
    z-index: 0;
    animation: rotate 20s linear infinite;
  }

  & .inner {
    padding: 12px;
    width: 100%;
    border-radius: 12px;

    position: relative;
    z-index: 1;
    width: 100%;

    margin: 2px;
  }

  &.variant-1 {
    & .inner {
      background: #fff;
    }
    &::before {
      content: "";
      display: block;
      background: linear-gradient(
        90deg,
        rgb(247, 246, 242) 0%,
        rgba(229, 0, 0, 1) 70%,
        rgb(247, 246, 242) 100%
      );
      box-shadow: rgba(0, 0, 0, 0.04);
      height: 300px;
      width: 100px;
      transform: translate(0);
      position: absolute;
      animation: rotate 6s linear forwards infinite;
      z-index: 0;
      top: 50%;
      transform-origin: top center;
    }
  }

  &.variant-2 {
    & .inner {
      background: #fff;
    }
    &::before {
      content: "";
      display: block;
      background: linear-gradient(
        90deg,
        rgb(247, 246, 242) 0%,
        rgba(0, 128, 96, 1) 50%,
        rgb(247, 246, 242) 100%
      );
      height: 300px;
      width: 100px;
      transform: translate(0);
      position: absolute;
      animation: rotate 6s linear forwards infinite;
      z-index: 0;
      top: 50%;
      transform-origin: top center;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
