.custom-quill-editor {
  width: fit-content;
  min-width: 100%;
  &.disabled {
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.6;
  }
  .ql-editor {
    height: 300px;
    max-height: 350px;
  }
  .ql-editor p {
    font-size: 16px !important;
    line-height: 28px;
  }
  .ql-editor div {
    font-size: 16px !important;
    line-height: 28px;
  }
  .ql-editor h1 {
    font-size: 32px !important;
    line-height: 40px;
  }
  .ql-editor h2 {
    font-size: 28px !important;
    line-height: 36px;
  }
  .ql-editor h3 {
    font-size: 24px !important;
    line-height: 36px;
  }
  .ql-editor h4 {
    font-size: 20px !important;
    line-height: 32px;
  }
  .ql-editor h5 {
    font-size: 14px !important;
    line-height: 20px;
  }

  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: "Small" !important;
  }

  .ql-toolbar:nth-child(2) {
    display: none !important;
  }
  & .ql-toolbar.ql-snow {
    border-bottom: none;
    background: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 48px;
    display: flex;
    align-items: center;
    & .ql-formats {
      display: flex;
      align-items: center;
      margin-right: 10px;
      // flex-wrap: wrap;
      & button {
        padding: 8px;
        height: 36px;
        width: 36px;
      }
      & .ql-stroke {
        stroke: $text-color;
      }
      & .ql-fill {
        fill: $text-color;
      }
    }
  }
  & .ql-container.ql-snow {
    border: 1px solid #dededf !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 350px;
    // overflow-y: auto;
  }
  & .ql-editor.ql-blank::before {
    font-style: normal;
  }
  .ql-snow {
    .ql-picker {
      &.ql-size {
        .ql-picker-label,
        .ql-picker-item {
          &::before {
            content: attr(data-value) !important;
          }
        }
      }
    }
  }
}
