.service-payments {
  min-height: 100vh;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 70px;
  @media screen and (max-width: $screen-sm-max) {
    padding: 0;
  }
  & .sp-details {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.02);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
    border-radius: 40px;
    overflow: hidden;
    position: relative;
    // padding-bottom: 125px;
    @media screen and (max-width: $screen-sm-max) {
      box-shadow: none;
      border: 0;
      border-radius: 0;
      padding-bottom: 20px;
      min-height: 100vh;
    }
    & .header {
      padding: 24px 0 18px 0;
      border-bottom: 0.5px solid #e8e8e8;
      align-items: center;
      @media screen and (max-width: $screen-sm-max) {
        background: #fafafa;
        padding: 10px 12px 6px 18px;
        width: 100%;
        height: 60px;
        z-index: 99;
      }
      & .profile {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
      & .name {
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        margin-left: 12px;
        margin-bottom: 0;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 14px;
          line-height: 26px;
          font-weight: 500;
        }
      }
    }
    & .sp-title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      text-transform: capitalize;
      margin-top: 24px;
      margin-bottom: 0;
    }
    & .sp-description {
      margin-top: 6px;
      & * {
        color: #a7a7a7;
      }
    }
  }
  & .slot-info {
    border-bottom: 0.5px solid #e8e8e8;
    border-radius: 9px;
    margin-top: 16px;
    border: 1px solid #50a688;
    & .ant-alert-info {
      background-color: transparent !important;
      border: none;
    }
  }
  & .sp-alert {
    background-color: #e9f4eb;
    width: 100%;
    @media screen and (max-width: $screen-sm-max) {
      padding: 6px 6px;
    }
  }
  & .query-body {
    padding: 16px 24px 0px 24px;
    & .title-card {
      background-color: #ffd9a2;
      border-radius: 9px;
      padding: 16px 24px;
      align-items: center;
      & .query-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }
    }
    & .response-time {
      color: rgba(0, 0, 0, 0.63);
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      padding-top: 16px;
    }
    & .query-desc {
      padding-top: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    & .question-form {
      padding: 32px 0px;
    }
  }
  & .discount-input {
    box-shadow: none;
    border: dashed;
    border-color: #b0b0b0 !important;
    outline: 0px;
  }
  & .apply-btn {
    border: 1px solid #000000;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
  }
  & .pwyw-chips-field {
    width: 80%;
    @media screen and (max-width: $screen-sm-max) {
      width: 100%;
    }
  }
  & .pwyw-chips-field-booking {
    width: 100%;
  }
  & .coupon-success {
    background-color: #fee9ee;
    border-radius: 3px;
    padding: 9px 12px;
    margin-top: 8px;
    font-weight: 500;
    font-size: 13px;
  }

  & .webinar-suggestion-card {
    margin-bottom: 40px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 40px;
    }
    & .ant-card-body {
      padding: 16px 20px;
    }
    & .star-icon {
      position: absolute;
      top: -10px;
      right: -10px;
    }
    & .suggestion-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      margin-bottom: 20px;
    }
    & .service-detail {
      border-radius: 12px;
      background: #efefef;
      padding: 12px 16px;
      & .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%; /* 24px */
      }
      & .price {
        color: #5c5c5c;
        font-size: 16px;
        font-weight: 500;
      }
      & .add-btn {
        font-size: 16px;
        font-weight: 700;
        border-radius: 20px;
        border: 1px solid #000000;
        text-align: center;
        display: flex;
        background-color: #000000;
        color: #fff;
      }
      & .remove-btn {
        background-color: #000000;
        font-size: 16px;
        font-weight: 700;
        border-radius: 20px;
        border: 1px solid #000000;
        text-align: center;
        display: flex;
        color: #fff;
      }
    }
  }
}
.popular-tag {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 18px;
  color: #fff;
  background-color: #008060;
  border-radius: 0px 0px 8px 8px !important;
}
.radio-checkbox {
  @media screen and (max-width: 768px) {
    flex-wrap: unset;
    justify-content: space-between;
  }
  & .radio-checkbox-item {
    height: 54px;
    border: 1px solid #ebeaeb;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 14px;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
    &.popular {
      padding-bottom: 14px;
    }
    &.ant-radio-button-wrapper-checked {
      border: 1px solid #008060;
      outline: 1px solid #008060;
      border-radius: 9px !important;
      background-color: #e9f4eb;
      color: #008060;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      transition: all 0.25s ease-in-out;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.recording-popconfirm {
  & .ant-popconfirm-buttons {
    @media screen and (max-width: $screen-sm-max) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      & button {
        margin-inline-start: 0;
        margin-left: 0 !important;
      }
    }
  }
}

.sp-form {
  margin-top: 32px;
  // @media screen and (max-width: $screen-sm-max) {
  padding-bottom: 100px;
  // }

  & .recording-upsell-card {
    background-color: #fafafa;
    border: 1px solid #ebeaeb;
    border-radius: 6px;
    margin-top: 20px;
  }
  & .upsell-card {
    & .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      @media screen and (max-width: $screen-sm-max) {
        margin: 0;
      }
    }
    & .content {
      color: #5c5c5c;
      font-size: 14px;
      font-weight: 500;
    }
    & .ant-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      border-radius: 20px;
      width: 120px;
    }
    & .add-btn {
      border: 1px solid $text-color;
      padding: 4px 10px;
    }
    & .remove-btn {
      background-color: #e9f4eb;
      border: 1.5px solid #008060;
    }
    & .upsell-service-item {
      &:not(:last-child) {
        border-bottom: 1px solid #ebeaeb;
      }
      padding: 0px 0px 10px 0px;
      &:not(:first-child) {
        padding: 16px 0px 10px 0px;
      }

      & .service-title {
        color: #141414;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%; /* 24px */
        @media screen and (max-width: $screen-sm-max) {
          padding: 0;
        }
      }
      & .service-desc {
        color: #5c5c5c;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
    & .suggestion-collapse-btn {
      border-radius: 20px;
      background: #efeded;
      color: #808080 !important;
      font-size: 12px !important;
      font-weight: 700;
      padding: 7px 11px;
      border: none !important;
      width: unset;
    }
  }

  & .suggestion-collapse {
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 20px;
    }
    & .star-icon {
      position: absolute;
      top: -10px;
      left: -10px;
    }
    & .sub-heading {
      color: #5c5c5c;
      font-size: 14px;
      font-weight: 500;
      line-height: 130%; /* 18.2px */
    }
    & .suggestion-item {
      border-radius: 6px;
      border: 1px solid #ebeaeb;
      background: #fafafa;
      transition: all 0.2s linear;
      & .ant-collapse-expand-icon {
        transition: all 0.2s linear;
      }
      &.ant-collapse-item-active {
        transition: all 0.2s linear;
        & .ant-collapse-expand-icon {
          transition: all 0.2s linear;
          & svg,
          & img {
            // filter: invert(45%) sepia(49%) saturate(3223%) hue-rotate(335deg)
            //   brightness(86%) contrast(110%);
            // transform-origin: 0 7px;
            transform: rotate(-180deg);
            transition: all 0.2s linear;
          }
        }
      }
      & .ant-collapse-header {
        align-items: center;
        padding: 22px 16px;
      }
      & .ant-collapse-header-text {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
    & .package-contents {
      @media screen and (max-width: $screen-sm-max) {
        padding-bottom: 0;
      }
      & .service-packages {
        & .service-packages-count {
          color: #141414;
          font-size: 16px;
          font-weight: 500;
        }
        & .service-packages-duration {
          color: #5c5c5c;
          font-size: 14px;
          font-weight: 500;
          line-height: 150%; /* 21px */
        }
      }
    }
  }
}

.sp-form-cta {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 16px 32px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 9;
  @media screen and (max-width: $screen-sm-max) {
    position: fixed;
    padding: 12px 14px;
  }
  & .sp-charge {
    font-size: 20px;
    line-height: 36px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  & .sp-min-input {
    margin-bottom: 0;
    & .ant-input-number-affix-wrapper {
      width: 100%;
      font-size: 16px;
      line-height: 20px;
      color: #a1a1a1;
    }
    & .ant-input-number {
      width: 100%;
    }
    & .ant-input-number-input {
      height: 48px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  & .sp-cta {
    // margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
    padding: 0 24px;
    height: 48px;
    font-weight: 700;
  }
}
.confirm-pay-title {
  color: #141414;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.1px;
  text-align: center;
  margin: 24px 0px !important;
}

.pay-secure-btn {
  border-radius: 12px;
  background: #f9fafb !important;
  border: none !important;
  pointer-events: none;
  color: #525265;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-top: 36px;
}

.recording-container {
  border-radius: 16px;
  border: 1px solid #ebeaeb;
  background: #fff;
  padding: 20px 16px;

  & .title {
    color: #141414;
    font-size: 16px;
    font-weight: 700;
  }

  & .desc {
    color: #5c5c5c;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-top: 2px;
  }

  .recording-swiper {
    margin-top: 15px;
    & .swiper-image {
      width: 100%;
    }
  }
  & .recording-item {
    background-color: #faf6ee;
    border-radius: 12px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    & .text-lottie {
      width: 90px;
      padding: 0px 13px;
      border-radius: 12px;
      background: #ccdd48;
      height: 64px;
      @media screen and (max-width: $screen-sm-max) {
        height: 48px;
      }
    }
  }
}
