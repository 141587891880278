.video-call {
  // background-color: #f9f5ed;
  min-height: 100vh;
  & .video-container {
    text-align: center;
    padding-top: 48px;
  }
  .status-tip-card {
    width: 368px;
    text-align: left;
    margin: 0 auto !important;
  }
  .card-title {
    font-size: 19px;
    font-weight: 700;
    line-height: 34px;
  }
  .status-card {
    border-radius: 24px !important;
    margin: 0 auto !important;
    margin-top: 32px !important;
    width: 368px;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
    & .light-button {
      margin-top: 12px;
      margin-bottom: 20px;
      font-weight: 700;
    }

    & .dark-button {
      background-color: black;
      color: #fff;
      margin-top: 12px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    & .description {
      margin-top: 32px;
      background-color: #fafafa;
      border-radius: 9px;
      font-size: 14px;
      line-height: 24px;
      text-align: left;
      padding: 12px;
    }
  }
  & .status-title {
    font-weight: 600;
  }
  & .timer-tag {
    border-radius: 12px;
    box-shadow: none;
    border: none;
    background: rgba(0, 128, 96, 0.03);
    color: #008050 !important;
    font-weight: 600;
    margin-top: 32px !important;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  & .user-profile {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin-bottom: 30px;
  }
}

.tip-card {
  display: flex;
  background-color: #fff;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 24px !important;
  margin-top: 20px !important;
  padding: 16px;
  & .tip-text {
    padding-left: 10px;
    & .update-button {
      margin-top: 14px;
      color: black;
      font-size: 12px;
      font-weight: 700;
    }
  }

  & .ant-image-img {
    width: 32px;
  }
}
.meeting-module-main {
  justify-content: center;
  & .user-profile {
    border-radius: 50%;
    width: 123px;
    height: 123px;
  }
  & .buy-again-tab {
    font-size: 16px;
    font-weight: 700;
  }
  & .buy-again-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 12px;
    margin-top: 8px;
  }
  & .testimonial-desc {
    color: rgba(20, 20, 20, 0.6);
    font-weight: 500;
    font-size: 14px;
  }
}
.meeting-module-segment {
  background-color: #fff;
  border-radius: 16px !important;
  border: 1px solid #c4c4c4;
  padding: 20px;
  margin-top: 20px;
}

.campaign-options {
  & .top-img {
    border-radius: 16px 16px 0px 0px;
    width: 100%;
  }
  & .ant-modal-content {
    padding-bottom: 8px;
    border-radius: 24px;
    padding: 0;
  }

  & .title {
    margin: 24px 24px 12px 24px;
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
  }

  & .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin: 0px 24px 30px 24px;
    color: #686b60;
  }

  & .list {
    & .campaign-item {
      font-size: 16px;
      font-weight: 500;
      margin: 24px 12px 24px 12px;
      & .name {
        font-size: 16px;
        font-weight: 700 !important;
        margin-left: 8px;
      }
      & .campaign-desc {
        font-weight: 500;
        font-size: 14px;
        margin-left: 8px;
      }
    }
  }
  & .btn-submit {
    margin: 24px;
  }
}
