.network-snapshot {
  background-color: #ea7d56;
  min-height: 100vh;
  @media screen and (max-width: $screen-xs-max) {
    padding: 20px 0px 20px 24px;
  }

  & .snap-logo {
    width: 194px;
    height: 36px;
    @media screen and (max-width: $screen-xs-max) {
      width: 134px;
      height: 28px !important;
    }
  }

  & .left-side {
    @media screen and (min-width: $screen-xs-max) {
      padding: 60px 0px 60px 120px;
    }
  }

  & .logo-text {
    font-weight: 500;
    font-size: 20px;
    padding-left: 12px;
  }

  & .snap-heading {
    font-weight: 700;
    font-size: 100px;
    line-height: 100%;
    margin-top: 104px;
    @media screen and (max-width: $screen-xs-max) {
      font-size: 48px;
      margin-top: 46px;
    }
  }
  & .linkedin-input {
    border: 2px solid #141414;
    outline: 3px solid rgba(20, 20, 20, 0.14);
    border-radius: 12px;
    margin-top: 72px;
    height: 64px;
    font-size: 20px;
    padding: 0 18px 0 16px;
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 40px;
    }
    & .ant-input {
      font-size: 20px !important;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 16px !important;
      }
    }
  }
  & .stars-container {
    margin-top: 80px;
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 20px;
    }
    & .stars-img {
      width: 90px;
      height: 90px;
      @media screen and (max-width: $screen-xs-max) {
        width: 60px;
        height: 60px;
      }
    }
  }
  & .frame-container {
    background-image: url("/images/homepage/Star4.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  & .sub-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    margin-top: 24px;
    @media screen and (max-width: $screen-xs-max) {
      font-size: 16px;
    }
  }
}
.email-modal {
  & .ant-modal-content {
    padding: 0px 0px 24px 0px;
  }
  & .top-nav {
    background: #eee9fc;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    height: 63px;
  }
  & .content {
    padding: 20px 24px 0px 24px;
    & .heading {
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
    }
    & .desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      margin-bottom: 12px;
    }
    & .submit-btn {
      margin-top: 60px;
    }
  }
}
