// Global Variables
$navbar-height: 72px;
$ph-banner: 56px;
$navbar-height-mobile: 56px;
$primary-color: var(--colorPrimary);
$text-color: var(--colorTextBase);
$success-color: var(--colorSuccess);
$error-color: var(--colorError);
$secondary-color: var(--colorSecondary);
$border-radius-base: var(--borderRadiusBase);
$input-border-color: var(--inputBorderColor);
$input-number-handler-hover-bg: #f5f6f8;
$selected-state-color: #8c5300;
$mobile-nav-height: 62px;

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);
