.home-page-design {
  margin-bottom: 80px;
  & .rewards-home-nav {
    @media screen and (max-width: $screen-sm-max) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 72px;
      background-color: $text-color;
      padding: 8px 10px 8px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & .ant-typography {
        color: #fff;
      }
      & .claim-btn {
        background-color: #d03660;
        color: #fff;
        padding: 0 32px;
        border-radius: 24px;
      }
    }
  }
}

.dashboard-nav {
  & .mobile-home-header {
    padding: 16px 0px;
    & .header-name {
      font-size: 24px;
      font-weight: 700;
    }
    & .header-link {
      color: #838383;
      font-size: 14px;
      font-weight: 500;
    }
  }
  & .link-container {
    border-radius: 16.755px;
    border: 0.698px solid #e7e0c2;
    background: #f8f7f2;
    padding: 8px;
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 16px;
    }
    & .user-profile {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
.copy-profile {
  border-radius: 16.755px;
  border: 0.698px solid #e7e0c2;
  background: #f8f7f2;
  cursor: pointer;
  align-items: center;
  display: flex;
  padding: 0px 16px !important;
  color: #5b3600;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  @media screen and (max-width: $screen-sm-max) {
    padding: 8px !important;
  }
}

.common-container-card {
  margin-right: 24px;
  & .ant-card-body {
    padding: 11px 17px;
  }
}

.featured-container-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: $screen-sm-max) {
    flex-wrap: wrap;
  }
}

.feed-section-left-card {
  .ant-card-body {
    padding: 40px 0px;
    background: #f9f4f0;
    border-radius: 9px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 32px 0px;
    }
  }
  & .line-image {
    & img {
      position: absolute !important;
      top: 60px;
      width: 100%;
      height: auto;
      @media screen and (max-width: $screen-sm-max) {
        top: 50px;
      }
    }
  }
  & .journey-path {
    display: flex;
    justify-content: space-around;
    & .journey-section {
      align-items: center;
      display: flex;
      flex-direction: column;
      & .section-image {
        z-index: 1;
        @media screen and (max-width: $screen-sm-max) {
          width: 48px;
          height: 48px;
        }
      }
      & .section-image-main {
        z-index: 1;
        @media screen and (max-width: $screen-sm-max) {
          width: 72px;
          height: 72px;
        }
      }
      & .section-title {
        font-size: 12px;
        font-weight: 500;
      }
      & .section-title-main {
        font-size: 18px;
        font-weight: 700;
      }
      & .action-btn {
        margin-top: 16px;
      }
    }
  }
}

.home-help-section {
  margin-top: 65px;
  & .help-heading {
    @media screen and (min-width: $screen-sm-max) {
      margin-left: 48px;
      margin-right: 48px;
    }
  }
  & .guide-container {
    @media screen and (min-width: $screen-sm-max) {
      margin: 0 48px;
    }
  }
}

.feed-section-content-header {
  font-weight: 600 !important;
  font-size: 32px !important;
  @media screen and (max-width: $screen-sm-max) {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
}

.common-text {
  font-size: 19px;
  margin-bottom: 0 !important;
}

.dashboard-main-title {
  font-size: 23px;
  line-height: 30px;
  font-weight: 700;
}

.img-size-reducer {
  width: 50px;
  height: 50px;
}

.feed-section-text {
  font-weight: normal;
  font-size: 13px;
  line-height: 175%;
  letter-spacing: 0.25px;
  margin-bottom: 0 !important;
}

.dashboard-main-summary {
  margin-top: 16px !important;
  font-weight: 500;
  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 16px !important;
  }
}

.feed-section-card-button {
  height: 45px !important;
  margin-top: 48px;
  padding: 10px 24px !important;
  border-radius: 0 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-right: 8px;
  @media screen and (max-width: $screen-sm-max) {
    width: 100%;
    margin-top: 8px;
    margin-right: 0;
  }
}

.feed-section-content-data {
  color: #000000 !important;
  font-weight: 500;
  font-size: 15px;
  line-height: 175%;
  letter-spacing: 0.15px;
  text-decoration-line: underline !important;
}

.feed-section-margin {
  margin-top: 10px !important;
}

.feed-section-small {
  margin-top: -1em;
}

.featured-container-card-bigger-text {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 175%;
}

.featured-container-card-smaller-text {
  margin-top: 5px;
}

.featured-container-margin {
  margin-top: 17px;
  margin-bottom: 4px !important;
}

.guide-container-type {
  font-weight: 500;
  font-size: 13px;
  line-height: 175%;
  letter-spacing: 1.25px;
  margin-top: 16px;
  margin-bottom: 4px !important;
}

.guide-container-heading {
  font-weight: 700;
  font-size: 19px;
  line-height: 175%;
  text-decoration: underline;
}

.home-checked-icon {
  font-size: 15px;
  color: #38a169 !important;
}

.home-check-share-button {
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
}

.home-check-list {
  padding: 0px;
}

.home-divider {
  margin: 0 !important;
}

.guide-container-card {
  width: 100%;
  border: 0px !important;
  cursor: pointer;
  @media screen and (max-width: $screen-sm-max) {
    width: 100%;
    margin-bottom: 30px !important;
  }
}

.guide-container-image {
  width: 100%;
  margin-bottom: 16px;
}

.guide-container-card-normal-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #434343;
}

.guide-container-card .ant-card-body {
  padding: 0px !important;
}

.checklist-section,
.next-steps {
  padding-bottom: 60px;
}
.analysis-content-card {
  @media screen and (max-width: $screen-sm-max) {
    padding-top: 30px;
  }
}

.similar-profiles {
  background: #f8f7f2;
  border-radius: 24px;
  padding: 36px 20px;
  height: fit-content;
  @media screen and (min-width: $screen-sm-max) {
    margin: 32px 32px 60px 32px;
  }
  @media screen and (max-width: $screen-sm-max) {
    padding: 24px 28px;
    margin-top: 20px;
  }
  & .similar-profiles-container {
    @media screen and (max-width: $screen-sm-max) {
      text-align: center;
    }

    & .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      margin-bottom: 8px;
    }
    & .subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      margin-bottom: 28px;
      @media screen and (max-width: $screen-sm-max) {
        margin-bottom: 24px;
      }
    }
    & .profiles-container {
      margin-bottom: 20px;
      & .suggested-profile {
        border-radius: 16px;
        cursor: pointer;
        padding: 8px;
        @media screen and (max-width: $screen-sm-max) {
          padding: 8px 0px;
        }
        &:hover {
          background-color: #ede6da;
          & .goto-icon {
            display: block !important;
          }
        }
        & .user-name {
          font-size: 18px;
          font-weight: 500;
        }
        & .profile-pic {
          border-radius: 50%;
          width: 56px;
          height: 56px;
        }
        & .goto-icon {
          @media screen and (min-width: $screen-sm-max) {
            display: none;
          }
        }
      }
    }
    & .see-profile-btn {
      background: #ffffff;
      box-shadow: 2px 1px 12px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 700;
      font-size: 18px;
      border: none;
      height: 52px;
    }
  }
}

.be-available {
  border-radius: 24px;
  background: rgba(228, 67, 50, 0.12);
  padding: 20px 8px;

  &.onboarding-bar {
    padding: 0px;
    border-radius: 12px;
    background: unset;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 20px;
      background: rgba(228, 67, 50, 0.12);
      padding: 20px 8px;
    }
  }
  & .be-title {
    font-size: 24px;
    line-height: 32px;
    margin-top: 12px;
  }
  & .be-subtitle {
    font-size: 14px;
    line-height: 18px;
    margin-top: 14px;
  }
  & .be-cta {
    margin-top: 26px;
    margin-bottom: 12px;
    min-width: 146px;
    font-size: 15px;
    font-weight: 700;
  }
}

.home-checklist {
  margin-top: 12px;
  & .checklist-progress {
    margin-top: 12px;
    display: flex;
    line-height: 3px;
  }
  & .checklist-items {
    border: 1px solid #f4f4f4;
    padding: 0 20px;
    @media screen and (max-width: $screen-sm-max) {
      border: none;
      padding: 0;
    }
  }
  & .checklist-item {
    padding: 30px 18px 24px 16px;
    &:not(:last-child) {
      border-bottom: 1px solid #f4f4f4;
    }
    @media screen and (max-width: $screen-sm-max) {
      padding: 20px 0;
    }
    & .icon-container {
      background-color: #eaf0ff;
      color: #2c61a7;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      @media screen and (max-width: $screen-sm-max) {
        margin-right: 10px;
        flex-shrink: 0;
      }
    }
    & .checklist-data {
      display: flex;
      align-items: flex-start;
      & .checklist-title {
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;
        display: flex;
        align-items: center;
        & .recommended-text {
          background-color: #fcf0c6;
          margin-left: 7px;
          border-radius: 12px;
          padding: 2px 12px;
          display: inline-flex;
          height: 22px;
          font-size: 10px;
          line-height: 18px;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
      & .checklist-description {
        font-size: 13px;
        line-height: 24px;
        font-weight: 400;
        color: #717171;
      }
    }
    & .checklist-cta {
      display: flex;
      justify-content: flex-end;
    }
    &.checked {
      & .checklist-title {
        text-decoration: line-through;
        font-weight: 400;
      }
    }
  }
}

.checklist-progress {
  padding: 0px 24px 13px;
  .ant-progress-steps-outer {
    justify-content: space-between;
  }

  .ant-progress-steps-item {
    width: max-content;
    margin-inline-end: 18px !important;
    width: unset;
    flex-basis: 14%;
    @media screen and (max-width: $screen-sm-max) {
      flex-basis: 12%;
    }
  }
}

.welcome-model {
  display: inline-block !important;
  & .ant-modal-body {
    padding: 0px !important;
    font-size: 14px;
    line-height: 1.17;
    word-wrap: break-word;
    max-width: 330;
    height: 432px;
    & .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.45) !important;
    }

    & .view-btn {
      background-color: black !important;
      color: white !important;
      margin-bottom: 8px !important;
      margin-top: 5px !important;
    }
    & .com-btn {
      background-color: #eeeeee !important;
      font-weight: bold !important;
      border: none;
      margin-bottom: 20px !important;
    }
    & .welcome-image {
      background-color: #e0f4fb;
      margin-bottom: 20px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    & .welcome-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center !important;
      padding: 0 10px !important;

      & .welcome-mess {
        margin-top: 20px;
        font-size: 20px !important;
        font-weight: 600;
        margin-bottom: 20px;
        align-items: center;
      }
    }
    & .profile-link {
      text-decoration: underline;
      color: green !important;
      margin-bottom: 40px !important;
      cursor: pointer;
    }
  }
}
.ant-modal-mask {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.ant-modal-centered .ant-modal {
  padding-left: 15px;
  padding-right: 15px;
}

.home-checklist-section {
  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 74px;
  }
  @media screen and (min-width: $screen-sm-max) {
    border-bottom: 1px solid #f7f7f7;
  }
  & .steps-image {
    width: 100%;
    aspect-ratio: "1/1";
  }
  & .checklist-container {
    @media screen and (min-width: $screen-sm-max) {
      padding: 32px 32px 60px 48px;
      border-right: 1px solid #f7f7f7;
    }
  }
}
.floating-checklist {
  @media screen and (max-width: $screen-sm-max) {
    border-radius: 0px !important;
  }
  @media screen and (min-width: $screen-sm-max) {
    width: 580px !important;
  }
}
.checklist-card {
  background: #ffffff;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 24px;

  & .header {
    padding: 24px;
    & .heading {
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 20px;
      }
    }
  }
  & .sub-head {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    margin-top: 8px;
  }
  & .checklist-item {
    padding: 22px 30px;
    border-top: 1px solid #f0f0f0;
    background: #fafafa;
    @media screen and (max-width: $screen-sm-max) {
      padding: 13px 18px;
    }
    & .service-title {
      font-weight: 500;
      font-size: 16px;
      padding-left: 12px;
    }
  }
  & .active-checklist-item {
    padding: 22px 30px;
    border-top: 1px solid #f0f0f0;
    background: #fff !important;
    @media screen and (max-width: $screen-sm-max) {
      padding: 13px 18px;
    }
    & .service-title {
      font-weight: 700;
      font-size: 16px;
      padding-left: 12px;
    }
  }
}

.container-children:has(.home-page-design) {
  @media screen and (min-width: $screen-sm-max) {
    padding: 0 !important;
    display: flex;
    align-items: stretch;
    min-height: 90vh;
  }
  & > .ant-col {
    @media screen and (min-width: $screen-sm-max) {
      min-width: -webkit-fill-available;
      margin: 0px;
    }
  }
}

.notification-container {
  padding-top: 40px;
  height: 100%;
  border-bottom: 1px solid #f7f7f7;
  @media screen and (max-width: $screen-sm-max) {
    padding-top: 65px;
  }
  & .header {
    font-size: 20px;
    font-weight: 700;
    @media screen and (min-width: $screen-sm-max) {
      font-size: 16px;
      font-weight: 500;
      padding-left: 32px;
      padding-bottom: 20px;
    }
  }
  & .notification {
    border-bottom: 1px solid #f7f7f7;
    &:nth-child(6) {
      border-bottom: none;
    }

    & .notification-content {
      padding: 16px 0px;
      @media screen and (min-width: $screen-sm-max) {
        padding: 16px 48px 16px 32px;
      }
      & .title {
        font-size: 16px;
        font-weight: 600;
      }
      & .desc {
        color: #838383;
        font-size: 12px;
        font-weight: 500;
        margin-top: 2px;
      }
      & .label {
        background-color: #effaf4;
        border-radius: 20px;
        color: #3e8750;
        padding: 4px;
        min-width: 70px;
        text-align: center;
      }
    }
  }
}

.second-row-section {
  & .banner-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    @media screen and (min-width: $screen-sm-max) {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #f7f7f7;
      border-bottom: 1px solid #f7f7f7;
      padding: 32px 48px 32px 48px;
      width: 100%;
    }
  }
  & .slider-container {
    width: inherit;
    @media screen and (max-width: $screen-sm-max) {
      overflow: hidden;
    }
  }
  & .foryou-banner-card {
    background-color: #9dc4f4;
    height: 100%;
    width: 100%;
    text-align: center;
    padding: 40px 48px;
    border-radius: 20px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 12px 16px 48px 16px;
    }
    & .new-tag {
      border-radius: 24px;
      color: #fff;
      font-weight: 700;
      text-align: center;
      justify-content: center;
      display: flex;
      height: 36px;
      width: 72px;
      font-size: 14px;
      @media screen and (min-width: $screen-sm-max) {
        margin-top: 34px;
        height: 40px;
        width: 93px;
        font-size: 16px;
      }
    }
    & .banner-title {
      font-size: 32px;
      font-weight: 700;
      line-height: 130%;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 24px;
      }
    }
    & .banner-desc {
      font-size: 14px;
      font-weight: 500;
      line-height: 139%;
      margin-top: 16px;
      @media screen and (min-width: $screen-sm-max) {
        font-size: 16px;
      }
    }
    & .banner-image {
      @media screen and (min-width: $screen-sm-max) {
        width: 150px;
        height: 150pxs;
      }
    }
    & .forward-icon {
      border-radius: 24px;
      background: #fff;
      display: flex;
      width: fit-content;
      height: 36px;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: $screen-sm-max) {
        margin-top: 24px;
      }
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #000 !important;
    opacity: 1 !important;
  }
  .swiper-pagination-bullet {
    background-color: rgba(0, 0, 0);
    opacity: 0.4;
  }
  .banner-full-width {
    @media screen and (max-width: $screen-sm-max) {
      width: 100% !important;
    }
  }
  .swiper {
    width: 100%;
    height: 300px;
    @media screen and (max-width: $screen-sm-max) {
      height: 250px;
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
}

.home-stats-section {
  margin-top: 65px;
  .ant-select-focused {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    border-radius: none !important;
  }
  & .analytics-header {
    gap: 24px !important;
    margin-bottom: 12px;
    & .duration-selector {
      & .ant-select-selector {
        @media screen and (max-width: $screen-sm-max) {
          background-color: transparent !important;
          border: none !important;
          box-shadow: none !important;
          padding-left: 0px;
        }
        border-radius: 12px;
        border: 0.698px solid #e7e0c2;
        background: #f8f7f2;
      }
      & .ant-select-selection-item {
        @media screen and (max-width: $screen-sm-max) {
          font-size: 20px;
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
  }
  & .stats-container {
    height: 100%;
    @media screen and (min-width: $screen-sm-max) {
      padding: 32px;
      border-right: 1px solid #f7f7f7;
    }
    &:first-child {
      @media screen and (min-width: $screen-sm-max) {
        padding-left: 0px;
      }
    }
    & .stats-box {
      @media screen and (max-width: $screen-sm-max) {
        border-radius: 12px;
        background: #f6f6f6;
        margin-bottom: 12px;
        padding: 10px 12px;
      }

      & .analytics-title {
        color: #838383;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 8px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 14px;
          color: #000;
        }
      }

      & .analytics-value {
        color: #000;
        font-size: 32px;
        font-weight: 500;
        line-height: 130%;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 20px;
          font-weight: 700;
        }
      }
      & .analytics-percent {
        color: #000;
        gap: 2px !important;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        display: flex;
        align-items: flex-end;
      }
    }
  }
}
