.discard-modal {
  & .reject-desc {
    font-size: 15px;
    color: #434343;
    line-height: 24px;
    margin-bottom: 84px;
  }
  & .btn-cancel {
    text-decoration: underline;
    font-weight: 700;
    font-size: 15px;
    line-height: 175%;
    margin-right: 8px;
  }
}
