.footer {
  background-color: $text-color;
  padding-top: 95px;
  padding-bottom: 100px;
  color: #fff;
  @media screen and (max-width: $screen-sm-max) {
    padding: 50px 0;
  }
  & .link-dark {
    color: #141414 !important;
  }
  & .footer-linkedin {
    background-color: #000;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;
    &:hover,
    &:focus {
      filter: invert(100%);
      transition: all 0.3s linear;
    }
    & .anticon-twitter {
      font-size: 28px;
    }
  }
  & .footer-socials {
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 20px;
    }
  }
  & .footer-logo {
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 50px;
      & img {
        max-width: 200px;
      }
    }
  }
  & .footer-nav {
    @media screen and (max-width: $screen-sm-max) {
      text-align: center;
      flex-direction: column;
      & .ant-col {
        padding-bottom: 15px;
      }
    }
  }
  & .footer-link {
    color: #fff;
  }
}
