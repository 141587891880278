.social-login {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-xs-max) {
    flex-direction: column;
  }
  .social-login-button {
    align-items: center;
    color: #141414;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    border: 2px solid black;
    border-radius: 6px;
    padding: 12px 0px;
    height: 48px;
    &:hover {
      transition: all 0.25s ease-in-out;
      border: 2px solid #000000;
      background-color: #fafafa;
    }
    @media screen and (min-width: $screen-xs-max) {
      width: 48%;
    }
  }
  .name {
    font-size: 15px;
    font-weight: 500;
    padding-left: 12px;
  }
}

.spacing-top {
  margin-top: 27px !important;
}
.right-image {
  text-align: center;
  padding-top: 50px;
  height: 100vh;
}
.forgot-password {
  color: rgb(0, 128, 96);
  font-size: 1.2em;
  font-weight: 600;
  padding-bottom: 24px;
}

.signin-side-button {
  @media screen and (min-width: 768px) {
    margin-right: 48px;
  }
}
.form-submit-button {
  background-color: black !important;
  margin-top: 16px;
  margin-bottom: 32px;
}
.signup-top-divider {
  margin: 16px 0px;
  @media screen and (max-width: $screen-sm-max) {
    margin: 8px 0px;
  }
}

.login-container {
  padding-top: 64px;
  & .sign-up {
    padding-bottom: 12px;
    padding-top: 20px;
  }
}
