.share {
  height: 100%;

  & .category-desc {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    max-height: 90vh;
    position: sticky;
    top: 88px;
    @media screen and (max-width: $screen-sm-max) {
      padding-top: 0;
      margin-right: 0;
      display: none;
    }
  }

  & .category-title {
    font-weight: 400;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 0;
    & strong {
      font-weight: 800;
    }
    @media screen and (max-width: $screen-sm-max) {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }

  & .category-image {
    // background: #f0f0f0;
    border-radius: 12px;
    margin-top: 40px;
    width: 100%;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }

  & .category-tip {
    margin-top: 20px;
    background: #f9f9f9;
    border-radius: 12px;
    padding: 20px 32px 20px 20px;
    margin-bottom: 80px;
    display: flex;
    align-items: flex-start;
    gap: 18px;
    font-size: 14px;
    line-height: 20px;
    @media screen and (max-width: $screen-sm-max) {
      // display: none;
      width: 100%;
      margin-top: 16px;
    }
  }

  & .share-content {
    padding-top: 40px;
    padding-left: 30px;
    border-right: 1px solid #e3e8ed;
    border-left: 1px solid #e3e8ed;
    @media screen and (max-width: $screen-sm-max) {
      border-right: none;
      border-left: none;
      padding-left: 0;
      padding-top: 24px;
    }
  }

  & .share-tabs {
    padding-bottom: 60px;
    & .ant-tabs-nav {
      margin-bottom: 40px;
      @media screen and (max-width: $screen-sm-max) {
        margin-bottom: 24px;
      }
      &::before {
        border: none;
      }
    }
    & .ant-tabs-ink-bar {
      display: none;
    }
    & .ant-tabs-tab {
      padding: 8px 24px 8px 12px;
      background: #fff;
      border: 1px solid $text-color;
      outline: 1px solid transparent;
      border-radius: 6px;
      transition: all 0.4s ease-in-out;
      &.ant-tabs-tab-active {
        transition: all 0.5s ease-in-out;
        border: 2px solid $text-color;
        outline: none;
        background-color: #f7f7f7;
        pointer-events: none;
        cursor: pointer;
      }
      &:hover,
      &:focus {
        transition: all 0.5s ease-in-out;
        background-color: #f7f7f7;
      }
      & .ant-tabs-tab-btn {
        color: $text-color;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }
    }
    & .ant-tabs-nav-operations {
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }
    @media screen and (min-width: $screen-md-min) {
      & .category-title {
        display: none;
      }
      & .category-image {
        display: none;
      }
      & .category-tip {
        display: none;
      }
    }
  }

  & .share-card {
    background: #fafafa;
    border: 1px solid #fafafa;
    border-radius: 12px;
    margin-bottom: 24px;
    transition: all 0.4s ease-in-out;
    padding: 16px;
    display: flex;
    overflow: hidden;
    cursor: pointer;
    height: 380px;
    &:hover,
    &:focus {
      background-color: #fff;
      border: 1px solid $text-color;
      box-shadow: 4px 4px 0px #000000;
      transition: all 0.4s ease-in-out;
    }
    @media screen and (max-width: $screen-sm-max) {
      height: 174px;
    }
    & .ant-skeleton {
      width: 100%;
    }
    & .share-card-title {
      // margin-top: 28px;
      font-size: 16px !important;
      line-height: 20px !important;
      font-weight: 700 !important;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 18px;
      }
      & .anticon {
        font-size: 22px;
        font-weight: 700;
      }
    }
    & .share-card-tag {
      color: #535655 !important;
      font-size: 12px !important;
      line-height: 16px !important;
      margin-top: 2px !important;
    }
  }
  & .share-title {
    align-self: flex-end;
    @media screen and (max-width: $screen-sm-max) {
      align-self: initial;
    }
  }
  & .share-empty-lottie {
    width: 75%;
    @media screen and (max-width: $screen-sm-max) {
      width: 60%;
    }
  }
  & .share-post {
    background-color: #fff;
    border-radius: 12px;
    align-self: flex-start;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.03);
    overflow: hidden;
    aspect-ratio: 1/1;
    letter-spacing: 0.25px;
    @media screen and (max-width: $screen-sm-max) {
      height: 100%;
    }
    &:has(.share-post-text) {
      padding: 12px;
    }
  }
  & .share-card-image {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1/1;
    @media screen and (max-width: $screen-sm-max) {
      aspect-ratio: initial;
    }
  }
  & .share-post-text {
    font-size: 14px;
    line-height: 18px;
    overflow-x: auto;
    @media screen and (max-width: $screen-sm-max) {
      aspect-ratio: initial;
      -webkit-line-clamp: 6 !important;
    }
  }
  & .share-float-button {
    display: none;
    @media screen and (max-width: $screen-sm-max) {
      display: flex;
      min-width: 130px;
      flex-direction: column;
      align-items: flex-end;
      bottom: 90px;
      right: 20px;
    }
    & .ant-float-btn {
      & .ant-float-btn-body {
        background-color: $text-color;
      }
      & .anticon svg {
        fill: #fff;
      }
    }
    & .ant-float-btn-group-wrap {
      width: 100%;
      & .ant-float-btn {
        width: 100%;
      }
      & .ant-float-btn-body {
        background-color: #fff;
        border-radius: 24px;
        width: 100%;
      }
      & .anticon svg {
        fill: $text-color;
      }
      & .ant-float-btn-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }
    }
  }
}

.container-children:has(.share) {
  padding: 0 !important;
  display: flex;
  align-items: stretch;
  min-height: 90vh;
}

.container-title-section {
  @media screen and (max-width: $screen-sm-max) {
    border-bottom: none !important;
  }
  .share {
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }
}
