.react-calendar {
  min-width: 300px;
  width: 100%;
  max-width: 100%;
  background: white;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__navigation__arrow {
  font-size: 2.2rem !important;
}

.react-calendar__navigation__label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #141414 !important;
}
.react-calendar__navigation__label:disabled {
  background: transparent !important;
  pointer-events: none;
  color: #141414 !important;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: #666666;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day {
  color: blue;
}

.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  overflow: hidden;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  pointer-events: none;

  /* flex-basis: unset !important; */
}

.react-calendar__tile:not(:disabled) abbr {
  border: 1px solid #ebf5f2 !important;
  background-color: #ebf5f2;
  color: #008060;
  padding: 7px 0;
  border-radius: 50%;
  width: 32px !important;
  height: 32px !important;
  text-align: center;
  display: inline-block;
  pointer-events: auto;
  white-space: nowrap;
}

.react-calendar__tile:not(:disabled) {
  color: #008060;
}
.react-calendar__tile:disabled {
  background-color: transparent;
  color: #666666;
  text-decoration: line-through;
}
.react-calendar__tile:enabled:hover abbr,
.react-calendar__tile:enabled:focus abbr {
  background-color: #008060;
  color: #fff;
  opacity: 0.8;
}

/* .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #008060;
  color: #fff;
  opacity: 0.8;
} */

.react-calendar__tile--now:not(:disabled):after {
  /* background: #ffff76; */
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  width: 4px;
  height: 4px;
  margin-top: 13px;
  background-color: currentColor;
  border-radius: 50%;
  transform: translate(-429%, 234%);
  content: " ";
}
.react-calendar__tile--now:disabled:after {
  /* background: #ffff76; */
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  width: 4px;
  height: 4px;
  margin-top: 13px;
  background-color: currentColor;
  border-radius: 50%;
  transform: translate(-144%, 24%);
  content: " ";
}
/* 
.react-calendar__tile--now abbr:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin-top: 13px;
  background-color: currentColor;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  content: "";
} */

/* .react-calendar__tile--now:enabled:hover, */
/* .react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
} */
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active abbr {
  background: #008060 !important;
  color: white !important;
}

.react-calendar__tile--active {
  background: transparent !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
