.container,
.container-fluid {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}
@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
