.product-container {
  .cover-image-container {
    & .cover-back-btn {
      position: absolute;
      top: 24px;
      left: 20px;
      z-index: 2;
      border-radius: 20px;
      background: #fff;
      color: #141414 !important;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
      border: 1px solid #141414 !important;
    }

    & .swiper-button-next,
    .swiper-button-prev {
      width: 30px;
      height: 30px;
    }
    & .swiper-button-next::after,
    .swiper-button-prev::after {
      color: #000;
      border-radius: 50%;
      background: #fff;
      width: 30px;
      height: 30px;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #000;
    }
    & .swiper-slide {
      position: relative;
      height: 445px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      @media screen and (max-width: $screen-sm-max) {
        height: 345px;
      }
    }
    & .swiper-pagination-bullet {
      background-color: #fff;
      border: 1px solid #000;
      opacity: 100;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      width: 16px;
      height: 16px;
    }
    & .swiper-pagination-bullet-active {
      background-color: #000;
      opacity: 100;
    }
  }
  .doc-details {
    border-top: 0.5px solid #e3e8ed;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    padding: 32px 36px 20px 36px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 20px;
    }
    & .title-tag {
      font-weight: 800;
      font-size: 32px;
      line-height: 42px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    & .tag {
      border-radius: 5px;
      padding: 10px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      border-radius: 5px;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 12px;
        line-height: 18px;
        padding: 7px 8px;
      }
    }
  }
  .booking-section-padding {
    padding: 0 24px !important;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 0 !important;
      padding: 0 !important;
    }
    & .product-tags {
      border: 1px solid #e3e8ed;
      & .tag-icon {
        color: #006b43;
        margin-right: 4px;
      }
      & .doc-tag {
        padding: 12px 16px;
        border-right: 1px solid #e3e8ed;
        display: inline-flex;
        align-items: center;
        border-right: 1px solid #e3e8ed;
        @media screen and (max-width: $screen-sm-max) {
          border-top: 1px solid #e3e8ed;
        }
      }
    }
  }
}
