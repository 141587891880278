.common-text {
  font-weight: normal;
  font-size: 19px !important;
  line-height: 175% !important;
  letter-spacing: 0.15px;
}

.full-width {
  width: 100%;
}
