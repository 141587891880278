.video-call {
  & .main {
    margin-top: 50px;
    margin-bottom: 50px;
    @media screen and (max-width: $screen-sm-max) {
      min-height: 100vh;
      margin-top: 0;
    }
  }
  & .service-user-avatar {
    @media screen and (max-width: $screen-sm-max) {
      width: 32px !important;
      height: 32px !important;
    }
  }
  & .slider {
    margin-top: 34px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 24px;
    }
  }
  & .embed-main {
    min-width: 100%;
  }
  & .service-details {
    background: #fff;
    border: none;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 40px;
    // height: 100%;
    min-height: 100vh;
    @media screen and (max-width: $screen-sm-max) {
      border-radius: 0;
      padding-bottom: 0;
      height: auto;
      margin-bottom: 20px;
    }
    &.product-container {
      width: auto;
      margin-top: 0;
    }
    & .service-user-name {
      font-size: 20px;
      line-height: 35px;
      font-weight: 700;
      margin-bottom: 0;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
        line-height: 26px;
        font-weight: 500;
      }
    }
    & .service-user-avatar {
      @media screen and (max-width: $screen-sm-max) {
        width: 32px !important;
        height: 32px !important;
      }
    }
    & .service-charge {
      padding: 14px 24px;
      border-right: 0.5px solid #e3e8ed;
      font-size: 16px;
      line-height: 28px;
      font-weight: 700;
      display: flex;
      align-items: center;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
        line-height: 24px;
        padding: 8px 4px 8px 12px;
        & span {
          font-size: 14px !important;
          margin-right: 4px !important;
        }
      }
    }
    & .service-duration {
      padding: 14px 24px;
      font-size: 16px;
      line-height: 28px;
      font-weight: 700;
      display: flex;
      align-items: center;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
        line-height: 24px;
        padding: 8px 4px 8px 12px;
      }
    }
    & .service-icon {
      margin-right: 12px;
      @media screen and (max-width: $screen-sm-max) {
        margin-right: 8px;
      }
    }
    & .description-section {
      padding: 0 40px;
      margin-top: 32px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 0 20px;
        margin-top: 24px;
      }
      & .service-description {
        font-size: 16px;
        line-height: 28px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    & .testimonials-section {
      margin-bottom: 20px;
      padding: 0 40px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 0 20px;
      }
    }
    & .slider-section {
      margin-top: 24px;
      & .testimonials-header {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & .testimonials-title {
          font-size: 24px;
          line-height: 42px;
          font-weight: 700;
          margin-bottom: 0;
        }
      }
      & .testimonials-swiper {
        width: 100%;
        @media screen and (max-width: $screen-sm-max) {
          padding-left: 0;
        }
      }
      & .ai-crest-disclaimer {
        position: absolute;
        bottom: 20px;
        color: #5e5e5e;
        font-size: 12px;
        font-weight: 500;
        line-height: 150%; /* 18px */
      }
      & .ai-crest {
        background: #fdfbf9;
      }
      & .crest-card {
        border: 1px solid #f1f1f1;
        border-radius: 12px;
        padding: 20px 16px 36px;
        height: 100%;
        min-height: 200px;
      }
      & .crest-title {
        font-size: 15px;
        line-height: 26px;
        font-weight: 700;
      }
      & .crest-description {
        font-size: 13px;
        line-height: 22px;
        margin-top: 20px;
      }
    }
    & .stats-section {
      @media screen and (max-width: $screen-sm-max) {
        padding-top: 30px;
      }
      & .stats-title-section {
        display: flex;
        align-items: center;
        padding: 0 40px;
        @media screen and (max-width: $screen-sm-max) {
          padding: 0 20px;
        }
      }
      & .stats-title {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 24px;
        }
      }
      & .stats-container {
        // margin-top: 18px;
        @media screen and (max-width: $screen-sm-max) {
          margin-top: 18px;
        }
      }
      & .stats-block {
        padding: 4px 12px;
        border-radius: 40px;
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.06);
        @media screen and (max-width: $screen-sm-max) {
          padding-left: 6px;
        }
      }
      & .stats-block-space {
        @media screen and (max-width: $screen-sm-max) {
          gap: 4px;
        }
      }
      & .stats-value {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
      }
      & .stats-type {
        font-size: 12px;
        line-height: 22px;
        white-space: nowrap;
      }
    }
  }

  & .service-slots {
    background: #fff;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 40px;
    overflow: hidden;
    @media screen and (min-width: $screen-sm-min) {
      // position: sticky;
      top: 20px;
      min-height: 70vh;
    }
    & .slots-header {
      font-size: 20px;
      line-height: 36px;
      font-weight: 700;
    }
    & .slot-selection {
      margin-top: 24px;
      position: relative;
    }
  }

  & .common-nav {
    height: 105px;
    padding: 24px 0 18px 0;
    border-bottom: 0.5px solid #e8e8e8;
    @media screen and (max-width: $screen-sm-max) {
      background: #fafafa;
      padding: 10px 12px 6px 18px;
      width: 100%;
      height: 60px;
      z-index: 99;
    }
  }
  & .mobile-slots-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 16px;
  }
  & .slot-placeholder {
    background-color: #f8f9fc;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
  }
  & .service-mobile-nav {
    @media screen and (max-width: $screen-sm-max) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 9;
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & .slot-title {
        font-size: 10px;
        line-height: 18px;
        color: rgba(20, 20, 20, 0.61);
        font-weight: 700;
      }
      & .slot-value {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
      }
      & .slot-cta {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        height: 48px;
        padding: 0 36px;
      }
    }
  }
  & .services-slider {
    padding-top: 24px;
    padding-bottom: 40px;
    background-color: #f7f7f7;
    & .services-swiper {
      padding-left: 40px;
      @media screen and (max-width: $screen-sm-max) {
        padding-left: 10px;
      }
    }
    & .service-card {
      background-color: #fff;
      border-radius: 3px;
      height: 130px;
      padding: 20px 12px 12px 16px;
      cursor: pointer;
      & .title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        margin: 0;
      }
      & .description {
        font-size: 12px;
        line-height: 16px;
        margin-top: 8px;
      }
      & .slider-icon {
        position: absolute;
        bottom: 12px;
        right: 12px;
        @media screen and (max-width: $screen-sm-max) {
          display: flex;
        }
      }
    }
  }
  & .slots-empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    & .se-title {
      margin-top: 20px;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
    }
    & .se-sub-title {
      font-size: 16px;
      line-height: 24px;
      margin-top: 6px;
      margin-bottom: 32px;
    }
    & .se-cta {
      height: 48px;
      font-weight: 700;
      font-size: 14px;
    }
  }

  & .sc-footer {
    border-radius: 18px;
    background: #efeff0;
    padding: 14px 16px;
    width: 100%;
    transition: all 0.25s linear;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    & .title {
      font-size: 16px !important;
      font-weight: 700 !important ;
      line-height: 150%; /* 24px */
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px !important;
      }
    }

    & .sub-title {
      color: #5c5c5c;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 150%; /* 24px */
      @media screen and (max-width: $screen-sm-max) {
        font-size: 12px !important;
      }
    }

    & .cta {
      border-radius: 20px;
      border: 0.5px solid #000;
      background: #fff;
    }
  }

  & .mobile-slots {
    margin-top: 20px;
  }
}

.reschedule-confirm {
  @media screen and (max-width: $screen-sm-max) {
    top: 35% !important;
  }
}

.slot-selector-slider {
  position: relative;
  & .section-title {
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 12px;
    &.slot-time {
      margin-top: 50px;
      margin-bottom: 0;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 30px;
      }
    }
  }
  & .slider-icon {
    position: absolute;
    top: 20px;
    &.right {
      right: -34px;
    }
    &.left {
      left: -34px;
    }
  }
  & .slots-list {
    max-height: 244px;
    overflow-y: auto;
    @media screen and (max-width: $screen-sm-max) {
      max-height: 180px;
    }
  }
  & .timezone-block {
    margin-top: 30px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 0px;
    }
    & .title {
      font-size: 13px;
      line-height: 24px;
      margin-bottom: 6px;
      @media screen and (max-width: $screen-sm-max) {
        margin-bottom: 0;
      }
    }
  }
  & .timezone-select {
    &.borderless {
      border: none;
      & [class*="-control"] {
        border: none;
        box-shadow: none;
      }
      & [class*="-singleValue"] {
        font-size: 16px;
        line-height: 28px;
        border-bottom: 1px solid #212121;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 13px;
          line-height: 22px;
        }
      }
      & [class*="-indicatorSeparator"] {
        display: none;
      }
      & [class*="-ValueContainer"] {
        @media screen and (max-width: $screen-sm-max) {
          padding-left: 0;
        }
      }
      & [class*="-indicatorContainer"] {
        padding: 8px 0;
        & > svg {
          filter: invert(100%) sepia(0%) saturate(17%) hue-rotate(324deg)
            brightness(95%) contrast(105%);
        }
      }
      & [class*="-Sa"] {
        padding-left: 0;
      }
      & [class*="-menu"] {
        @media screen and (max-width: $screen-sm-max) {
          z-index: 99;
          background-color: #fff;
        }
      }
      & [class*="-MenuList"] {
        @media screen and (max-width: $screen-sm-max) {
          font-size: 13px;
        }
      }
    }
    & .react-select__option {
      &.react-select__option--is-selected {
        background-color: $primary-color;
      }
    }
  }
}

.subscription-block {
  & .sb-cta {
    height: 48px;
    padding: 8px;
    font-size: 18px;
    font-weight: 400 !important;
    @media screen and (max-width: $screen-sm-max) {
      text-align: left;
      padding: 8px 16px;
    }
  }
  & .discount-tag {
    position: absolute;
    right: 7px;
    top: 7px;
    height: 32px;
    width: 80px;
    border-radius: 32px;
    background: #e44332;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    padding: 7px 12px 5px;
  }
  & .sb-help-text {
    margin-top: 6px;
    color: #5c5c5c;
    font-size: 11px;
    line-height: 130%; /* 14.3px */
  }
}

.slider-icon {
  width: 28px;
  height: 28px;
  background-color: #e4e6ea;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.slot-card {
  border: 1px solid #f0efef;
  outline: 0.5px solid transparent;
  border-radius: 12px;
  padding: 12px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.25s ease-in;
  height: 64px;
  &.active {
    border: 1.5px solid #8c5300;
    outline: none;
    background: #f8f7f2;
    transition: all 0.25s ease-in;
    & .slot-title,
    & .slot-subtitle {
      color: #8c5300;
      font-weight: 700 !important;
    }
    & .slot-title {
      font-weight: 500 !important;
    }
  }
  &.time {
    margin-top: 12px;
    padding: 15px 10px;
    border-radius: 6px;
    height: 48px;
    & .slot-subtitle {
      font-weight: 500;
    }
  }
  & .slot-title {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 2px;
  }
  & .slot-subtitle {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    white-space: nowrap;
  }
}

.rare-slot-block {
  margin-top: 17px !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 20px 30px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $screen-sm-max) {
    padding: 15px;
    margin-top: 20px !important;
  }
  & .rare-slot-title {
    font-size: 14px;
    line-height: 24px;
    margin-right: 50px;
    @media screen and (max-width: $screen-sm-max) {
      margin-right: 10px;
    }
  }
}

.showcase-testimonials {
  margin-bottom: 140px;
  & .showcase-testimonials-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  }
  & .desc-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin-bottom: 32px;
  }
  & .showcase-testimonials-swiper {
    & .swiper-slide-prev {
      & .crest-card {
        transform: scaleY(0.8);
        opacity: 0.5;
        transition: all 0.3s ease;
      }
    }
    & .swiper-slide-next {
      & .crest-card {
        transform: scaleY(0.8);
        opacity: 0.5;
        transition: all 0.3s ease;
      }
    }
    & .crest-card {
      background-color: #fcf5eb;
      border-radius: 8px;
      text-align: center;
      padding: 24px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.crest-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 24px;
      }
      & .crest-name {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-top: 24px;
      }
      & .crest-time {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
