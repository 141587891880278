.referral {
  margin-top: 20px;
  position: relative;
  height: 100%;
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 0;
    padding-bottom: 80px;
  }
  & .referral-title {
    font-size: 48px;
    line-height: 54px;
    font-weight: 800;
    & span {
      font-weight: 400;
    }
    @media screen and (max-width: $screen-sm-max) {
      font-size: 36px;
      line-height: 44px;
    }
  }
  & .referral-block {
    background: #f9f9f9;
    border-radius: 12px;
    padding: 20px 0 64px 20px;
    width: 100%;
    @media screen and (max-width: $screen-sm-max) {
      padding: 18px;
      margin-top: 16px;
    }
    & .rb-title {
      font-size: 20px;
      line-height: 26px;
      margin-top: 40px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
        line-height: 20px;
        margin-top: 20px;
      }
    }
    & .img-1 {
      text-align: right;
      @media screen and (max-width: $screen-sm-max) {
        text-align: left;
        & img {
          width: 109px;
        }
      }
    }
    & .img-2 {
      text-align: right;
      padding-right: 20px;
      @media screen and (max-width: $screen-sm-max) {
        padding-right: 0;
        & img {
          width: 74px;
        }
      }
    }
  }
  & .referral-label {
    font-size: 14px;
    line-height: 26px;
    margin-top: 16px;
  }
  & .referral-input {
    padding: 6px 6px 6px 12px;
    height: 44px;
    margin-top: 6px;
  }
  & .copy-button {
    background: #eee;
    border-radius: 3px;
    font-weight: 700;
  }
  & .referral-empty-state {
    border: 1px solid #e3e8ed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    margin: 20px 0;
    padding: 40px 0;
    & .es-title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      margin-top: 32px;
    }
    & .es-subtitle {
      font-size: 16px;
      line-height: 20px;
      margin-top: 6px;
    }
  }
  & .referral-table {
    & .referral-tag {
      font-size: 14px;
      line-height: 21px;
      font-weight: 700;
      border-radius: 14px;
      display: inline-flex;
      align-items: center;
      &.in_review {
        background: #fff4dc;
        color: #996a00;
      }
      &.credited {
        background: rgba(0, 128, 96, 0.09);
        color: #008060;
      }
      &.criteria_not_fulfilled {
        background: #f7f7f7;
      }
    }
    & .ant-table-cell {
      padding: 10px 14px;
    }
  }
  & .booking-mobile-footer {
    bottom: 72px;
  }
}

.referral-nav {
  @media screen and (max-width: $screen-sm-max) {
    & .header-section {
      display: none !important;
    }
    & .analytics-row {
      margin: 0;
    }
    & .title-analytics-section {
      border-top: none;
    }
    &.container-title-section {
      border-bottom: none !important;
    }
    // display: none;
  }
}

.referral-footer {
  position: fixed;
  bottom: 0;
  border-top: 1px solid #e3e8ed;
  width: 100%;
  background-color: #fff;
  padding: 18px 20px 18px 50px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  @media screen and (max-width: $screen-sm-max) {
    position: unset;
    padding-bottom: 90px;
  }
  & .rf-links {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $text-color;
  }
}

.referral-share-modal {
  & .referral-share-modal-img {
    padding: 12px;
    width: 48px;
    height: 48px;
    background: #edefec;
    border-radius: 9px;
  }
}
