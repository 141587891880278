body {
  --commissionValue: "5%";
}

.commission {
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-corner {
    background: var(--background);
  }

  &.mobile {
    background: #f9ddf0;
    height: 100%;
    min-height: 76vh;
    justify-content: center;
    & .title {
      font-size: 24px;
      line-height: 32px;
      width: 70%;
      text-align: center;
      margin: 0 auto;
      @media screen and (max-width: $screen-sm-max) {
        width: 95%;
      }
    }
    & .ant-btn {
      font-size: 16px;
    }
  }

  & .full-page-block {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    scroll-snap-align: center;
  }
  & .intro {
    background: #f9ddf0;
    position: relative;
    & .intro-grid {
      position: absolute;
      top: 0;
      right: 0;
    }
    & .flex-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    & .intro-title {
      margin-bottom: 40px;
      margin-top: 40px;
      & .text-semibold * {
        font-weight: 600;
      }
      & * {
        font-size: 64px;
        line-height: 1;
        font-weight: 500;
      }
    }
    & .intro-container {
      background: #fff;
      border-radius: 64px;
      height: 200px;
      padding: 42px 24px 42px 42px;
      &.compact {
        height: 100px;
        padding: 32px 24px 32px 42px;
        display: flex;
        align-items: center;
      }
      &.extend {
        height: 100%;
      }
      & .ic-img {
        margin-bottom: 32px;
      }
      & .ic-title {
        font-size: 20px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 40px;
      }
      & .ic-value {
        font-size: 40px;
        line-height: 40px;
        font-weight: 700;
        text-align: left;
      }
      & .ic-desc {
        font-size: 32px;
        line-height: 40px;
        font-style: italic;
        font-weight: 400;
      }
    }
  }
  & .memories {
    background: #ec6539;
    & .m-img {
      margin-bottom: 80px;
    }
  }
  .bts {
    background-color: #b989f8;
    padding-top: 70px;
    position: relative;
    & .bts-grid {
      position: absolute;
      top: 0;
      right: 0;
    }
    & .building-blocks {
      width: 100%;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 60px;
      border-bottom: none;
    }
    & .bts-block-title {
      text-align: center;
      font-size: 20px;
      line-height: 1;
      margin-bottom: 30px;
      color: #fff;
    }
  }
  & .bts-intro * {
    text-transform: uppercase;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
  }
  & .bts-title * {
    font-size: 64px;
    line-height: 76px;
    font-weight: 400;
    margin-right: 14px !important;
  }
  & .where-we-stand {
    background-color: #86ea5d;
    position: relative;
    & .wws-grid {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  & .our-standpoint {
    background-color: #8de3e1;
    position: relative;
    & .os-grid {
      position: absolute;
      top: 0;
      right: 0;
    }
    & .os-grid-2 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    & .content {
      display: flex;
      align-items: center;
    }
    & .image-scroll {
      height: 100vh;
      display: flex;
      --mask-bottom: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 1) 10%,
          rgba(0, 0, 0, 1) 85%,
          rgba(0, 0, 0, 0) 95%,
          rgba(0, 0, 0, 0) 10%
        )
        100% 50% / 100% 100% repeat-x;
      --mask-top: linear-gradient(
          to top,
          rgba(0, 0, 0, 1) 10%,
          rgba(0, 0, 0, 1) 85%,
          rgba(0, 0, 0, 0) 95%,
          rgba(0, 0, 0, 0) 10%
        )
        100% 50% / 100% 100% repeat-x;
      -webkit-mask: var(--mask-bottom);
      mask: var(--mask-bottom);
    }
  }
  & .see-future {
    background-color: #7c16f5;
    & * {
      color: #fff !important;
    }
  }
  & .select-commission {
    position: relative;
    & .cb-grid {
      position: absolute;
      top: 20%;
      left: 17%;
    }
    & .cb-grid-2 {
      position: absolute;
      top: 20%;
      right: 17%;
    }
    & .range__list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: auto;
      overflow: hidden;
      margin: 0 auto;
      width: 100%;
      & option {
        &:before {
          content: attr(value);
          font-size: 24px;
          line-height: 28px;
          color: $text-color;
          display: block;
        }
      }
    }
    & .commission-slider {
      display: flex;
      height: 26px;
      padding: 0;
      margin-top: 8px;
      & .ant-slider-handle {
        width: 60px;
        height: 60px;
        transition: all 0.3s ease-in-out;
        &::before {
          border: 12px solid #e44332;
          background-color: $text-color;
          width: 118px;
          height: 118px;
          border-radius: 50%;
          inset-inline-start: -26px;
          inset-block-start: -50px;
        }
        &::after {
          content: var(--commissionValue);
          color: #fff;
          width: 118px;
          height: 118px;
          background: none;
          font-size: 24px;
          border: none;
          box-shadow: none;
          display: flex;
          inset-inline-start: -26px;
          inset-block-start: -50px;
          align-items: center;
          justify-content: center;
        }
      }
    }
    & .commission-benefits {
      background: #74f8a0;
      border: 4px solid #000000;
      border-radius: 40px;
      margin-bottom: 6px;
      display: flex;
      height: 320px;
      padding: 40px;
      & .cb-percentage {
        font-size: 64px;
        line-height: 70px;
        font-weight: 700;
      }
      & .cb-checklist {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        margin-left: 20px;
      }
      & .cbc-item {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        text-align: left;
        & svg {
          margin-top: -2px;
          flex-shrink: 0;
        }
      }
    }
  }
  & .btn-confirm {
    margin-top: 50px;
  }
  & .footer {
    & .title {
      font-size: 64px;
      line-height: 64px;
      font-weight: 700;
    }
    & .link {
      font-size: 24px;
      line-height: 32px;
      margin-top: 32px;
      font-weight: 400;
      & a {
        color: #fff;
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
  .progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: red;
    bottom: 100px;
  }
}

html {
  scroll-snap-type: y mandatory;
}

.parallax {
  // overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  width: 150px;
  transform-origin: center center;
  transform: rotate(90deg);
  & .parallax-image {
    border-radius: 50%;
    // margin-right: 50px;
  }
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 64px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  gap: 30px;
}

.parallax-container {
  display: flex;
  align-items: center;
  justify-items: center;
  height: "100%";
  overflow: hidden;
  --mask-top: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 0,
      rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0) 0
    )
    100% 50% / 100% 100% repeat-x;
  -webkit-mask: var(--mask-top);
  mask: var(--mask-top);
}

.parallax span {
  align-items: center;
  margin-right: 30px;
}
