// Color variables (appears count calculates by raw css)
$color0: #ffffff; // Appears 3 times
$color1: #000000; // Appears 3 times

.router-error {
  align-items: center;
  background: $color0;
  display: flex;
  flex-direction: column;
  font-family: "Merriweather Sans", sans-serif;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  & svg {
    width: 100%;
  }
  #errorText {
    font-size: 22px;
    margin: 14px 0;
  }
  #g6219 {
    animation: an1 12s 0.5s infinite ease-out;
    transform-origin: 85px 4px;
  }
}
@keyframes an1 {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(3deg);
  }
  15% {
    transform: rotate(-2.5deg);
  }
  25% {
    transform: rotate(2deg);
  }
  35% {
    transform: rotate(-1.5deg);
  }
  45% {
    transform: rotate(1deg);
  }
  55% {
    transform: rotate(-1.5deg);
  }
  65% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  85% {
    transform: rotate(2.5deg);
  }
  95% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0);
  }
}
