.request-slot-container {
  background: rgb(249, 245, 237);
  width: 100%;
  height: 100vh;
  & .request-slot-card {
    padding: 20px 20px 24px 20px !important;
    border-radius: 16px !important;
    background-color: #fff;
    border: none !important;
    & .slot-title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
    & .slot-desc {
      margin-top: 16px;
      margin-bottom: 32px;
      text-align: center;
    }
  }
}

.maintenance {
  text-align: center;
  & .title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 18px;
  }
  & .subtitle {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
    color: #676767;
  }
}
