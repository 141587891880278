.call-reschedule {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 72px;
  min-height: 100vh;
  & .title {
    font-weight: 700;
    font-size: 32px;
  }
  & .reason-container {
    padding: 20px 20px 24px 20px !important;
    border-radius: 24px;
    margin-top: 24px;
    background-color: #fff;
  }
  & .bottom-logo {
    background-color: rgb(251, 222, 190);
    margin: 40px 0px;
    width: 100%;
  }
  & .bottom-container {
    border-radius: 24px;
    padding: 24px;
    margin-top: 12px !important;
    background-color: #fff;
    & .expert-pic {
      width: 123px;
      height: 123px;
      border-radius: 50%;
    }
    & .expert-call {
      font-weight: 700;
      font-size: 20px;
      margin-top: 8px;
      line-height: 175%;
    }
    & .service-details {
      font-weight: 500;
      font-size: 12px;
      line-height: 175%;
    }
    & .calendar-icon {
      font-size: 28px;
      color: #006b43;
      margin-right: 20px;
    }
    & .time-text {
      color: #006b43;
      font-weight: 700;
      font-size: 15px;
    }
  }
}
