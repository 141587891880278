@import "@styles/vars.scss";
@import "@styles/core/common/common.scss";
@import "@styles/core/normalize.scss";
@import "@styles/core/antReset.scss";
@import "@styles/core/public/public.scss";
@import "@styles/core/onboarding.scss";
@import "@styles/core/dashboard.scss";
@import "react-phone-number-input/style.css";
@import "node_modules/locomotive-scroll/bundled/locomotive-scroll.css";

// Regular
@font-face {
  font-family: "Gilroy";
  src:
    url("/fonts/GilroyRegular/font.woff2") format("woff2"),
    url("/fonts/GilroyRegular/font.woff") format("woff"); /* Super Modern Browsers */
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

// Medium
@font-face {
  font-family: "Gilroy";
  src:
    url("/fonts/GilroyMedium/font.woff2") format("woff2"),
    url("/fonts/GilroyMedium/font.woff") format("woff"); /* Super Modern Browsers */
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

// Bold
@font-face {
  font-family: "Gilroy";
  src:
    url("/fonts/GilroyBold/font.woff2") format("woff2"),
    url("/fonts/GilroyBold/font.woff") format("woff"); /* Super Modern Browsers */
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

// Extra Bold
@font-face {
  font-family: "Gilroy";
  src:
    url("/fonts/GilroyExtraBold/font.woff2") format("woff2"),
    url("/fonts/GilroyExtraBold/font.woff") format("woff"); /* Super Modern Browsers */
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
