.featured-page {
  background-color: #fff;
  overflow-x: hidden;
  &.banner {
    margin-top: 72px;
  }
}
.header-logo {
  margin-top: 26px;
  width: 28px;
  height: 28px;
}
.header-logo-title {
  color: white;
  width: 104px;
  height: 23px;
}
.site-page-header {
  padding: 0;
}

.header-image {
  margin-top: auto;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.header-wrapper {
  padding: 60px 0 100px 0;
  @media screen and (max-width: $screen-sm-max) {
    padding: 60px 0 80px 0;
  }
  .header-title {
    font-size: 64px;
    font-weight: 800;
    line-height: 120%;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 32px;
    }
  }
  .header-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin-top: 16px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.featured-container {
  padding: 50px 0 140px 0;
  @media screen and (max-width: $screen-sm-max) {
    padding: 24px 0 80px 0;
  }
  & .date-filter {
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 24px;
    }
  }
  & .filter-container {
    ::-webkit-scrollbar {
      display: none;
    }
    & .filter-tabs {
      margin-bottom: 36px;
      overflow-x: scroll;
      overflow-y: hidden;
      width: auto;
      white-space: nowrap;
    }
    & .filter-data {
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 40px;
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;
      padding: 12px 40px;
      transition: all 0.5s linear;
      white-space: nowrap;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
        padding: 8px 16px;
      }
      margin-right: 8px;
    }
    & .selected-tab {
      background: #000000 !important;
      color: #fff !important;
      transition: all 0.5s linear;
    }
  }
  & .featured-with-bio {
    min-height: 404px !important;
    @media screen and (max-width: $screen-sm-max) {
      min-height: 290px !important;
    }
  }
  & .featured-card-wrap {
    background: #ffffff;
    border-radius: 16px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    &:hover {
      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
      transition: all 0.3s ease-in-out;
    }
  }
  & .featured-card {
    padding: 16px 16px 24px 16px;
    & .details {
      margin-top: 24px;
    }
    & .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }
    & .description {
      color: #4a5568;
      font-weight: 600;
      font-size: 14px;
      margin-top: 6px;
    }
    & .short-bio {
      color: #4a5568;
      font-weight: 400;
      font-size: 14px;
      margin-top: 6px;
    }
  }
  & .featured-card-image {
    height: 287px !important;
    object-fit: contain;
    border-radius: 6px;
    width: 100%;
    @media screen and (max-width: 767px) {
      height: 126px !important;
    }
  }
}
.card_col {
  margin-bottom: 10px;
}
.card-tag {
  margin-top: 63px !important;
  margin-bottom: 29px;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 10px;
}

@media screen and (max-width: 767px) {
  .site-page-header {
    background-image: linear-gradient(to bottom right, #6654f5, #8873de);
    width: 100% !important;
    height: 274px !important;
    background: #6654f5;
  }
  .header-logo-title {
    color: #dbdbdb !important;
    font-size: 20px !important;
    margin-left: 20px !important;
  }
  .header-image {
    display: none;
  }
  .header-logo {
    display: none !important;
  }
  .card-tag {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 1024px) {
  .site-page-header {
    background-image: linear-gradient(to bottom right, #6654f5, #8873de);
  }
  .header-image {
    display: none !important;
  }
  .header-logo {
    display: none !important;
  }
  .ant-card-meta-title {
    font-size: inherit !important;
  }
}

.featured-banner {
  background-color: #fdf5e6;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px;
  height: 72px;
  z-index: 1;
  @media screen and (max-width: $screen-sm-max) {
    padding: 8px 16px;
  }
  & .featured-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .featured-title {
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  & .featured-cta {
    margin-left: 40px;
    @media screen and (max-width: $screen-sm-max) {
      margin-left: 20px;
    }
  }
}

.featured-top-nav {
  padding: 8px 0px;

  .b2b-signup-btn {
    border: none;
    font-size: 16px;
    font-weight: 500;
  }
}

.featured-footer {
  & .details-container {
    padding: 72px 0 100px 0;
  }
  & .socials-container {
    margin: 32px 0px;
    & .support-links {
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
      }
      & .support {
        color: #ffffff;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        cursor: pointer;
        text-decoration-line: underline;
      }
    }
  }
  & .copyright {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    position: absolute;
    bottom: 20px;
  }
}
