.page-container {
  & .right-image {
    text-align: center;
    padding-top: 50px;
    height: 100vh;
  }
}
.spacing-top {
  margin-top: 27px !important;
}
.container-password {
  margin-top: 64px !important;
}
