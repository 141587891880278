.service-empty-view {
  margin-top: 60px;
  text-align: center;
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 20px;
  }
  & .ses-title {
    margin-top: 24px;
    margin-bottom: 0;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
  }
  & .ses-desc {
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
  }
  & .ses-cta {
    margin-top: 24px;
  }
}

.add-service-form {
  & .fields {
    font-weight: 700;
    font-size: 16px;
  }
}
.services {
  .service-card {
    margin-bottom: 20px;
    border: 1px solid #e7eaef;
    border-radius: 12px;
    height: 177px;
    display: flex;
    &.expanded {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    @media screen and (max-width: $screen-sm-max) {
      height: 160px;
      &.expanded {
        margin-bottom: 0;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    & .service-card-main {
      padding: 16px;
      & .title-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & .service-title {
          font-weight: 700;
          font-size: 20px;
          line-height: 35px;
          max-width: 80%;
          @media screen and (max-width: $screen-sm-max) {
            font-size: 18px;
            line-height: 27px;
            max-width: 70%;
          }
        }
      }
      & .service-description {
        margin-top: 6px;
        line-height: 20px;
      }
    }
    & .service-card-footer {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-self: flex-end;
      & .service-duration {
        font-size: 12px;
        line-height: 23px;
        font-weight: 400;
        display: flex;
        align-items: center;
        & svg {
          color: #ffa900;
          font-size: 18px;
          margin-right: 9px;
        }
      }
      & .copy-link {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        & .copy-link-text {
          margin-left: 8px;
          @media screen and (max-width: $screen-sm-max) {
            display: none;
          }
        }
        & .ant-btn {
          border-radius: 18px;
          background-color: #eee;
          height: 36px;
          padding: 3px 13px 3px 13px;
          display: flex;
          align-items: center;
          @media screen and (max-width: $screen-sm-max) {
            border-radius: 50%;
            width: 36px;
            padding: 0;
            justify-content: center;
          }
        }
      }
    }
  }
  & .service-card-analytics {
    margin-bottom: 20px;
    border: 1px solid #e7eaef;
    border-left: none;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    @media screen and (max-width: $screen-sm-max) {
      border-top: none;
      border-top-right-radius: 0;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-left: 1px solid #e7eaef;
    }
    & .sca-section {
      padding: 22px 8px 9px 20px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 12px 8px 12px 12px;
      }
      &:first-child {
        border-right: 1px solid #e7eaef;
        border-bottom: 1px solid #e7eaef;
        @media screen and (max-width: $screen-sm-max) {
          border: none;
        }
      }
      &:nth-child(2) {
        border-bottom: 1px solid #e7eaef;
        @media screen and (max-width: $screen-sm-max) {
          border: none;
        }
      }
      &:nth-child(3) {
        border-right: 1px solid #e7eaef;
        @media screen and (max-width: $screen-sm-max) {
          border: none;
        }
      }
      & .sca-title {
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 12px;
          line-height: 18px;
        }
      }
      & .sca-value {
        font-weight: 700;
        font-size: 20px;
        line-height: 35px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    & .sca-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      height: 175px;
      @media screen and (max-width: $screen-sm-max) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        height: 100%;
      }
    }
  }
}

.add-questions {
  .action-buttons {
    margin-top: 32px !important;
    text-align: center;
  }
  .add-question-style {
    margin-top: 10px !important;
    border-radius: 4px !important;
    background-color: #141414 !important;
  }
  .add-question-error {
    margin-top: 4px;
    padding-left: 26px;
    color: red;
    text-align: center;
  }
  .ant-input:hover {
    border-color: #141414;
    border-right-width: 1px;
  }
  .question-button-type {
    width: 45%;
    height: 40px;
    background: #ffffff !important;
    border: 1px solid #dededf !important;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .question-button-text {
    font-weight: 500 !important;
    font-size: 13px !important;
    text-align: center;
  }
  .question-button-submit {
    color: #ffffff !important;
  }
  .question-button-select {
    color: #141414;
  }
  .typeSelected {
    width: 45%;
    border: 1px solid #141414 !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    background-color: #f7f7f7 !important;
  }
}

.dashboard-services {
  & .mobile-new-service-add {
    display: none;
    @media screen and (max-width: $screen-sm-max) {
      display: block;
      position: fixed;
      bottom: 150px;
      right: 20px;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      background-color: #000;
      display: grid;
      align-items: center;
      justify-content: center;
    }
  }
}

.query-button-type {
  width: 100%;
  height: 118px !important;
  border: 1px solid #dedede !important;
  border-radius: 6px !important;
  padding: 16px 12px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  @media screen and (max-width: $screen-sm-max) {
    height: unset !important;
  }
  &:hover {
    background-color: #f7f6f2;
    & .query-button-subtext {
      transition: all 0.25s ease-in-out;
      display: block;
    }
  }
}
.query-button-type-selected {
  border: 1px solid #8c5300 !important;
  outline: 1px solid #8c5300 !important;
  background: #f7f6f2 !important;
  transition: all 0.25s ease-in-out;
  & .query-button-subtext {
    display: block;
  }
  & .query-button-text {
    transition: all 0.25s ease-in-out;
    font-weight: 700;
  }
}
.query-button-text {
  transition: all 0.25s ease-in-out;
  font-weight: 500;
  font-size: 16px !important;
}
.query-button-subtext {
  transition: all 0.25s ease-in-out;
  font-size: 12px !important;
  line-height: 120%;
}
.img-style {
  height: auto;
  margin-right: 10px;
}
.new-service-submit {
  background-color: #fff;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  @media screen and (max-width: $screen-sm-max) {
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 16px 18px;
  }
}

.title-fields {
  font-weight: 500;
  // margin-bottom: 8px !important;
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 12px !important;
  }
}

.product-graphic-card {
  background: #eef5fa;
  border-radius: 6px;
  flex: 1;
  font-weight: 500;
  font-size: 13px;
  line-height: 175%;
  letter-spacing: 0.15px;
  text-align: center;
  & .card-text {
    @media screen and (max-width: $screen-sm-max) {
      max-width: 60%;
    }
    margin: auto;
  }
}
.response-time-tag {
  background-color: #fcf0c6;
  display: flex;
  align-items: flex-start;
  border-radius: 9px;
  font-size: 12px;
  line-height: 21px;
  font-weight: 400;
  padding: 12px 24px;
  margin-top: 12px !important;
}

.add-question {
  margin-top: 24px !important;
  & .title-fields {
    margin-bottom: 10px;
  }
}

.dynamic-price-text {
  color: #666666;
  font-size: 14px !important;
  font-weight: 500;
  margin-bottom: 12px !important;
  max-width: 400px;
  & .learn-more {
    color: #141414;
  }
}
.switch-input {
  & .ant-form-item-control {
    @media screen and (max-width: $screen-xs-max) {
      flex: auto !important;
      max-width: 55px !important;
    }
  }
  & .ant-form-item {
    @media screen and (max-width: $screen-xs-max) {
      flex: auto !important;
      max-width: 55px !important;
    }
  }
  & .ant-form {
    @media screen and (max-width: $screen-xs-max) {
      flex: auto !important;
      max-width: 55px !important;
    }
  }
  & .ant-form-item-row {
    flex-direction: row !important;
    & .ant-form-item-label {
      padding: 0 !important;
      position: relative;
      display: flex;
      align-items: center;
    }
  }
  & .ant-switch {
    width: 44px;
    height: 22px;
    &.ant-switch-checked {
      & .ant-switch-handle {
        inset-inline-start: calc(100% - 20px);
      }
    }
  }
  & .ant-switch-handle {
    height: 18px;
    width: 18px;
    &:before {
      border-radius: 50%;
    }
    & .ant-switch-loading-icon {
      top: 2px;
    }
  }
}

.right-card {
  background-color: #f9f9f9;
  border: none;
  border-radius: 12px;
  height: 250px;
  & .side-image {
    border-top-left-radius: 12px;
    width: 100px;
    height: auto;
  }
  & .dismiss-btn {
    cursor: pointer;
    margin: 14px 14px 0px 0px;
    text-decoration: underline;
  }
  & .banner-details {
    padding: 12px 24px;
    & .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: 0.25px;
      margin-bottom: 12px;
    }
    & .desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: 0.25px;
      color: #7c7c7c;
      margin-bottom: 24px;
    }
  }
}

.discount-container {
  & .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 8px;
    margin-top: 16px;
  }
  & .desc {
    color: #717171;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 32px;
  }
}

.icon-container {
  background: #f3f3f3;
  padding: 10px !important;
  border-radius: 20px;
}
.service-edit-form {
  & .pack-service {
    border: none;
    margin-top: 16px;
    background: #f4f4f4;
    border-radius: 6px;
    font-size: 13px;
    text-align: center;
    margin-bottom: 40px;
  }

  & .title-fields {
    & .title-input {
      font-size: 16px;
    }
    & .input-desc {
      font-weight: 500;
    }
    & .radio-checkbox-item {
      font-weight: 500;
      &:focus {
        font-weight: 700;
      }
    }
    &
      .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ) {
      font-weight: 700;
    }
    & .quill > .ql-container > .ql-editor {
      font-weight: 500;
      font-size: 16px;
    }
  }

  & .ant-upload {
    width: 100%;
  }
  & .field-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;
  }
  & .field-no-margin {
    margin-bottom: 0px;
  }
  & .field-sub-heading {
    color: #141414;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }

  & .validity-field {
    margin-top: 24px;
  }

  .form-save-btn {
    margin-top: 30px;
  }
  & .scheduling-tab {
    width: 167px;
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 24px;
      width: 100%;
    }
  }
  & .question-dropdown-arrow {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }

  & .question-set {
    width: 100%;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    text-align: left;
    min-height: 40px;
    padding-left: 10px;
    background: #ffffff;
    border: 1px solid #dededf;
    box-sizing: border-box;
    border-radius: 4px;
  }
  & .minimum-amount-block {
    border: 1px solid #e3e8ed;
    border-radius: 6px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    & .title {
      font-size: 16px;
      line-height: 28px;
    }
    & .amount {
      background: #f7f7f7;
      border-radius: 20px;
      padding: 8px 22px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }

  & .added-coupons {
    & .name {
      font-weight: 700;
      font-size: 16px;
    }
    & .desc {
      color: #666666;
      font-weight: 500;
      font-size: 14px;
      margin-top: 4px;
    }
  }
  & .thumbnail-desc {
    pointer-events: none;
    cursor: default;
    color: rgba(20, 20, 20, 0.39);
    font-size: 12px;
    font-weight: 500;
    line-height: 150%; /* 18px */
  }
  & .add-doc-card {
    margin-bottom: 24px;
  }
  & .doc-name {
    font-weight: 700;
    font-size: 16px;
  }
  & .doc-type {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    font-size: 12px;
  }
  & .edit-icon {
    background: #f3f3f3;
    border-radius: 20px;
    cursor: pointer;
    padding: 8px;
  }
  & .delete-icon {
    background: rgba(228, 67, 50, 0.1);
    border-radius: 20px;
    cursor: pointer;
    padding: 8px;
  }
  & .doc-list {
    margin-bottom: 12px;
  }

  & .add-doc-btn {
    background-color: #f0f0f0 !important;
    border: none;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 36px;
    margin-top: 12px;
    box-shadow: none;
    height: unset;
  }
  & .thumbnail-upload {
    & .ant-upload.ant-upload-select {
      width: 240px;
      height: 120px;
    }
  }

  & .ai-card-container {
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #dededf;
    // background: #f7f7f7;
    overflow: hidden;
    margin-bottom: 20px;

    & .ai-card {
      padding: 16px;
      background: #f5f5f5;
      max-height: 320px;
      overflow-y: auto;
      resize: vertical;
      cursor: not-allowed;
    }

    & .ai-card-footer {
      padding: 9px 12px;
    }
  }
}
.webinar-link-box {
  & .edit-input-btn {
    background-color: #f6f6f6;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-left: 16px;
  }
  & .webinar-link-desc {
    color: rgba(20, 20, 20, 0.6);
    font-size: 14px;
    font-weight: 500;
    line-height: 175%; /* 24.5px */
    letter-spacing: 0.5px;
    padding-top: 5px;
  }
  & .ant-form-item {
    margin-bottom: 0px;
  }
  & .link-dropdown-arrow {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }
}
.schedule-form {
  margin-top: 24px !important;
  & .label-heading {
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
  }
  & .service-divider {
    margin-top: 0px !important;
  }
  & .save-btn {
    padding-top: 32px;
  }
}
.drawer-container {
  & .ant-drawer-body {
    padding: 24px 16px;
  }
}

.package-service-box {
  @media screen and (min-width: $screen-xs-max) {
    // margin: 0px 16px;
  }
  & .header {
    background-color: #f2f7fc;
    margin-bottom: 20px;
  }
  & .package-img {
    margin-top: 35px;
  }
  & .upsell-action {
    & .ant-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      border-radius: 20px;
      width: 110px;
      &:active {
        color: unset;
        border-color: unset;
      }
    }
    & .add-btn {
      border: 1px solid $text-color;
      padding: 4px 10px;
    }
    & .remove-btn {
      background-color: #e9f4eb;
      border: 1.5px solid #008060;
    }
  }
  & .top-bar {
    margin: 0px 24px;
    & .title {
      font-weight: 600;
      font-size: 23px;
      line-height: 175%;
      letter-spacing: 0.15px;
    }
    & .desc {
      font-weight: 400;
      font-size: 13px;
      line-height: 175%;
      letter-spacing: 0.25px;
      color: #717171;
      margin-bottom: 20px;
    }
  }
  & .no-service {
    padding: 0px 24px;
  }
  & .btn-container {
    margin-top: 10px;
    background: #fff;
    box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    padding: 16px 24px;
    @media screen and (max-width: $screen-xs-max) {
      padding-top: 80px;
    }
  }
}

.service-list {
  max-height: 220px;
  overflow: scroll;
  padding: 0px 24px;
  & .service-details {
    border-bottom: 1px solid #ededed;
    padding: 12px 16px 14px 0px;
    & .service-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 175%;
    }
    & .details {
      font-weight: 00;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.25px;
      color: #717171;
    }
    & .selected-action {
      background: #fff;
      border: 2px solid #141414;
      border-radius: 6px;
      width: 68px;
      height: 32px;
    }
    & .action-btn {
      background: #fff;
      border: 1px solid #141414;
      border-radius: 6px;
      width: 68px;
      height: 32px;

      & .action-text {
        font-weight: 700;
        font-size: 12px;
        color: #141414;
      }
    }
  }
}
.ant-tour {
  max-width: 350px;
}

.prices-btn {
  border: none;
  border-radius: 22px;
  padding: 8px 12px;
  background-color: #f0f0f0;
  box-shadow: none;
}

.parity-options {
  & .ant-modal-content {
    padding-bottom: 8px;
    border-radius: 24px;
    padding: 0;
  }
  & .header {
    margin-bottom: 12px;
    & .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 110%;
      text-align: center;
    }
    & .close-share {
      cursor: pointer;
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
  & .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    color: #686b60;
  }

  & .price-list {
    display: flex;
    flex-direction: column;
    & .price-item {
      font-size: 16px;
      font-weight: 500;
      & .country-name {
        font-size: 16px;
        font-weight: 700 !important;
        margin-left: 8px;
      }
    }
    & .share-image {
      margin-right: 12px;
    }
    & .pricing {
      background: #f7f7f7;
      border-radius: 20px;
      padding: 2px 8px;
    }
  }
}
.ant-tour-next-btn {
  background-color: #141414;
}

.select-type {
  font-weight: 700;
  font-size: 16px !important;
}

.template-btn {
  background: #f3f3f1;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  border: none;
}
.service-templates-drawer {
  & .templates-header {
    padding: 16px 100px 16px 32px;
    border: 1px solid #e3e8ed;
  }
  & .service-templates-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
  & .drawer-close-icon {
    position: absolute;
    right: 32px;
    top: 16px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 6px;
    color: $text-color;
    cursor: pointer;
  }
  & .template-body {
    padding: 20px 24px;
    & .expertise-title {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
  .sample-service {
    border: 1px solid #dedddc;
    border-radius: 6px;
    margin-top: 24px !important;
    padding: 17px 24px;
    width: 100%;
    &:hover {
      transition: all 0.25s ease-in-out;
      border: 1px solid #141414;
      & .arrow-btn {
        background: #f3f3f3;
        border-radius: 50%;
        border: none;
        display: none;
        transition: all 0.25s ease-in-out;
      }
      & .use-btn {
        background: #f3f3f3;
        border-radius: 14px;
        border: none;
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        transition: all 0.25s ease-in-out;
      }
    }
    & .title {
      font-weight: 700;
      font-size: 16px;
      font-size: 16px;
      line-height: 150%;
    }
    & .short-desc {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
    }
    & .arrow-btn {
      background: #f3f3f3;
      border-radius: 50%;
      border: none;
    }
    & .use-btn {
      align-items: center;
      background: #f3f3f3;
      border-radius: 14px;
      border: none;
      display: none;
    }
  }
}

.desc-formatting {
  & > p {
    margin-bottom: 0;
    font-size: 16px !important;
    line-height: 28px !important;
  }
  & img {
    max-width: 100%;
    object-fit: contain;
  }
  & .ql-size-huge {
    font-size: 24px !important;
    line-height: 28px !important;
  }
  & .ql-size-large {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  & .ql-size-small {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  & h1 {
    font-size: 32px !important;
    line-height: 40px !important;
  }
  & h2 {
    font-size: 28px !important;
    line-height: 36px !important;
  }
  & h3 {
    font-size: 24px !important;
    line-height: 36px !important;
  }
  & h4 {
    font-size: 20px !important;
    line-height: 32px !important;
  }
  & h5 {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  & h6 {
    font-size: 12px !important;
    line-height: 18px !important;
  }
}

.upsell-suggestions {
  & .ant-modal-content {
    padding-bottom: 8px;
    border-radius: 24px;
    padding: 0;
  }
  & .desc-text {
    color: #141414;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
  & .change-btn {
    color: #141414;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px; /* 112.5% */
  }
}

.product-type-checkbox {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #dededf;
  background: #fff;
  padding: 16px 12px;
  cursor: pointer;

  &:hover {
    background: #f7f6f2;
  }
  & .checkbox-title {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
  & .checkbox-desc {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.25px;
  }
}

.product-type-checkbox-selected {
  width: 100%;
  border-radius: 12px;
  border: 1.5px solid #8c5300;
  background: #f7f6f2;
  padding: 16px 12px;
  cursor: pointer;
  & .checkbox-title {
    font-weight: 700;
  }
}

.pro-tip-card {
  position: relative;
  margin-top: 20px;
  background-color: #f7f6f2;
  border-radius: 11px;
  padding: 12px 12px;
  color: #141414;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  & a {
    text-decoration-line: underline !important;
    color: #141414 !important;
  }

  & .tip-tag {
    border-radius: 16px;
    background: rgba(140, 83, 0, 0.1);
    padding: 7px 10px;
    color: #8c5300;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
    width: 200px !important;
    text-align: center;
    height: fit-content;
  }

  & .course-tip-btn {
    border-radius: 16px;
    border: 1px solid #000 !important;
    background: #fff;
    font-size: 14px;
    font-weight: 700;
    color: #141414 !important;
    margin-top: 8px;
    padding: 2px 8px;
    height: unset;
  }
}
.examples-swiper {
  & .swiper-slide {
    height: 100%;
  }
  & .swiper-pagination {
    bottom: 28px !important;
    left: 50% !important;
    width: fit-content !important;
    transform: translateX(-50%);
  }
  & .swiper-pagination-bullet-active {
    background: #000;
  }
}
.examples-card {
  min-height: 300px !important;
  background-color: #f9f9f9;
  border-radius: 12px;
  min-height: 250px;
  position: relative;
  & .banner-details {
    padding: 12px 24px;
    & .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: 0.25px;
      margin-bottom: 12px;
    }
    & .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      letter-spacing: 0.25px;
      color: #141414;
      margin-bottom: 42px;
    }
  }
  & .ex-card-tag {
    border-radius: 16px;
    background: #0080601a;
    padding: 7px 10px;
    color: #008060;
    font-size: 12px;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: 0.5px;
    display: inline-block;
    margin: 12px 0px 16px 0px;
  }
  & .suggested-btn {
    border-radius: 12px;
    border: 1px solid #dededf;
    background: #fff;
    color: #141414;
    font-size: 16px;
    font-weight: 700;
    height: unset;
    &:hover,
    &:active {
      border: 1px solid #dededf !important;
      color: #141414 !important;
    }
  }
  .swiper-arrow {
    position: absolute;
    border-radius: 16px;
    border: 1px solid #000 !important;
    background: #fff;
    font-size: 14px;
    font-weight: 700;
    color: #141414 !important;
    margin-top: 8px;
    padding: 2px 8px;
    height: unset;
    bottom: 24px;
  }
  .swiper-arrow-left {
    left: 24px;
  }
  .swiper-arrow-right {
    right: 24px;
  }
}
