.carousel-maker {
  padding-top: 100px;
  position: relative;

  @media screen and (max-width: $screen-sm-max) {
    padding-top: 40px;
    overflow: hidden;
  }
  & .bg {
    position: absolute;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }
  & .bg-left-top {
    top: 0;
    left: 0;
  }
  & .bg-right-top {
    top: 0;
    right: 0;
  }
  & .bg-right-bottom {
    bottom: 0;
    right: 0;
  }
  & .bg-left-bottom {
    bottom: 0;
    left: 0;
  }
  & .bg-center-bottom {
    bottom: 0;
    right: 25%;
    @media screen and (max-width: $screen-sm-max) {
      display: block;
      right: -10%;
    }
  }
  & .main-title {
    font-size: 64px;
    line-height: 70px;
    font-weight: 600;
    text-align: center;
    margin-top: 24px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 40px;
      line-height: 44px;
      font-weight: 600;
    }
  }
  & .ph-helper-text {
    font-size: 12px;
    line-height: 16px;
    color: rgba(20, 20, 20, 0.7);
    margin-top: 12px;
  }
  & .sub-text {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    & .ant-typography {
      color: rgba(20, 20, 20, 0.7);
    }
    & .icon {
      color: #e44332;
      margin: 0 13px;
      @media screen and (max-width: $screen-sm-max) {
        margin: 0 6px;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 24px;
      font-size: 12px;
      line-height: 18px;
    }
  }
  & .carousel-form {
    margin-top: 40px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 25px;
    }
  }
  & .footer {
    background-color: #000;
    text-align: center;
    padding: 55px 0 20px 0;
    margin-top: 80px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 0;
    }
    & .footer-title {
      font-size: 32px;
      line-height: 36px;
      font-weight: 700;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 24px;
        line-height: 30px;
      }
    }
    & .ant-typography {
      color: #fff;
    }
    & .ant-typography-danger {
      color: $secondary-color;
    }
    & .end-text {
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      margin-top: 80px;
    }
    & .footer-cta {
      height: 48px;
      padding: 8px 33px;
      font-size: 16px;
      line-height: 28px;
      font-weight: 700;
      margin-top: 42px;
    }
  }
  & .generate-input {
    height: 50px !important;
    border-radius: 6px;
    font-size: 20px !important;
    line-height: 36px;
    padding-right: 130px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 14px !important;
      line-height: 20px;
      padding-right: 90px;
      height: 43px !important;
    }
  }
  & .generate-cta {
    height: 34px;
    padding: 2px 16px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    border-radius: 3px;
    position: absolute;
    right: 8px;
    bottom: 32px;
    z-index: 2;
    @media screen and (max-width: $screen-sm-max) {
      padding: 2px 10px;
      font-size: 12px;
      line-height: 20px;
      bottom: 28px;
      right: 5px;
    }
  }
  & .carousel-container {
    background-color: #f1efe9;
    padding-bottom: 56px;
    position: relative;
    & .header {
      border-bottom: 1px solid #e4e4e4;
      padding: 24px 0;
      margin-bottom: 32px;
    }
  }
  & .slider-icon {
    width: 48px;
    height: 48px;
    border: 1px solid #212121;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 40%;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
    &.right {
      right: -64px;
    }
    &.left {
      left: -64px;
    }
  }

  & .theme-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 5px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      border: 2px solid #000000;
    }
    @media screen and (max-width: $screen-sm-max) {
      height: 40px;
      width: 40px;
    }
  }
  & .theme-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }
  & .download-cta {
    @media screen and (max-width: $screen-sm-max) {
      width: 100%;
    }
  }
}
