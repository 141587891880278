.analytics-nav {
  padding: 0 !important;
  & .mobile-header {
    padding-top: 24px;
    & .heading {
      font-weight: 700;
      font-size: 24px;
      line-height: 175%;
    }
  }
  & .beta-btn {
    background: #f0f8f6;
    border: 1px solid #008060;
    border-radius: 5px;
    color: #008060;
    font-weight: 700;
    font-size: 11px;
    line-height: 175%;
    height: 24px;
    padding: 2px 8px;
    width: 80px;
    @media screen and (min-width: $screen-sm-max) {
      margin-right: 20px;
      width: 120px;
    }
  }
  & .analytics-row {
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 20px;
    }
  }
  & .earning-card {
    @media screen and (max-width: $screen-sm-max) {
      border-top: none !important;
    }
  }
  & .title-analytics-section {
    border-right: 1px solid #e3e8ed;
    padding: 17px 24px;
    display: flex;
    align-items: center;
    @media screen and (max-width: $screen-sm-max) {
      padding: 10px 16px;
      border-top: 1px solid #e3e8ed;
      border-bottom: 1px solid #e3e8ed;
    }
    &:first-child {
      border-left: 1px solid #e3e8ed;
    }

    &:last-child {
      @media screen and (max-width: $screen-sm-max) {
        border-right: none;
        border-top: none;
      }
    }
    & .title {
      font-weight: 500;
      font-size: 12px;
      line-height: 175%;
      color: #141414;
    }
    & .value {
      font-size: 24px;
      line-height: 32px;
      font-weight: 800;
      margin-top: 5px;
      display: flex;
      align-items: center;
    }
    & .icon {
      width: 30px;
      height: 30px;
      object-fit: contain;
      margin-right: 12px;
    }
  }
}

.analytics-container {
  padding-top: 12px;
  & .conversion-text {
    padding-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
  }
  & .chart {
    padding-top: 44px;
    @media screen and (max-width: $screen-xs-max) {
      padding-top: 24px;
      // min-height: 50vh ;
    }
  }
  & .green-dot {
    background-color: #02ac82;
    border-radius: 3px;
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 6px;
  }
  & .analysis-card {
    background-color: #ffffff;
    border: 1px solid #e3e8ed;
    border-radius: 4px;
    padding: 24px;
    @media screen and (max-width: $screen-xs-max) {
      padding: 12px;
    }
    & .analytics-heading {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      & .info {
        margin-left: 12px;
      }
    }
    & .analytics-subheading {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  & .ant-select-selector {
    width: 135px !important;
    border: 1px solid #000000 !important;
    border-radius: 20px !important;
    @media screen and (max-width: $screen-xs-max) {
      width: 107px !important;
    }
  }
  & .ant-select-arrow {
    color: #000000;
  }
  & .idea-card {
    background-color: #ffd9a23d;
    border: 1px solid rgba(17, 17, 17, 0.1);
    padding: 20px;
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 16px;
    }
    & .ideas-text {
      color: #141414;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      padding-top: 16px;
      padding-bottom: 10px;
      & .ideas-points {
        padding-top: 16px;
      }
    }
  }
}

.no-analytics {
  margin-top: 48px;
  text-align: center;
  & .heading {
    margin-top: 6px;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
  }
  & .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 16px;
    width: 340px;
    @media screen and (max-width: $screen-sm-max) {
      width: auto;
    }
  }
}
