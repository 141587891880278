.top-earners {
  background-color: #000;
  // min-height: 100vh;
  padding-bottom: 100px;
  padding-top: 140px;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: $screen-sm-max) {
    padding-top: 80px;
  }
  & .abs-icon {
    position: absolute;
    font-size: 480px;
    right: -100px;
    top: 80px;
    transform: rotate(5deg);
    @media screen and (max-width: $screen-sm-max) {
      top: -60px;
      right: -140px;
      font-size: 380px;
    }
  }

  & .swiper-slide-shadow {
    background-color: transparent;
  }

  & .swiper-slide {
    text-align: center;
  }

  & .landing-hero-img {
    max-width: 86%;
    height: auto;
    margin: 0 auto;
    border-radius: 24px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 40px;
    }
  }

  & .landing-hero-img-text {
    font-size: 28px;
    font-weight: 500;
    line-height: 120%;
    border-radius: 24px;
    background: #fff;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);
    text-align: left;
    padding: 18px 24px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 12px 16px;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 30px;
    }
  }

  &.billboard {
    background-color: #0fa958;
    & .top-nav {
      z-index: 99;
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(6px);
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
      margin-top: 32px;
      padding: 11px 45px 12px 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
        padding: 12px 20px 12px 24px;
        height: auto;
        border-radius: 24px;
        margin-top: 16px;
      }
      & .nav-btn {
        border-radius: 32px;
        background: #0e944d;
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 35px;
        height: 56px;
        padding: 11px 22px 10px 22px;
      }
    }
    & .title {
      @media screen and (max-width: $screen-sm-max) {
        font-size: 56px !important;
        line-height: 62px !important;
        margin-bottom: 32px !important;
      }
    }
    & .title,
    & .sub-title {
      color: $text-color !important;
    }
    & .faq-item {
      background-color: #fff;
      @media screen and (max-width: $screen-sm-max) {
        min-height: auto;
      }
    }
    & .faq-space .ant-collapse-header-text {
      color: $text-color;
    }
    & .table-space {
      gap: 24px !important;
      @media screen and (max-width: $screen-sm-max) {
        gap: 12px !important;
      }
    }
    & .filter-container {
      background-color: #fff !important;
      border: none;
    }
    & .selected-filter {
      background-color: #cd9ef4 !important;
      border: 2px solid #393939 !important;
    }
    & .name {
      color: $text-color !important;
    }
    & .reward-title {
      color: $text-color !important;
      @media screen and (max-width: $screen-sm-max) {
        padding: 20px 24px 0px;
      }
    }
    & .earners-rewards {
      background: #fff;
      color: $text-color !important;
    }
    & .time-counter {
      color: $text-color;
    }
    & .reward-container {
      background: #fff !important;
    }
  }
  & .top-nav {
    position: fixed;
    width: 84%;
    top: 0;
    z-index: 1;
    @media screen and (max-width: $screen-sm-max) {
      height: 64px;
    }

    & .account-btn {
      font-weight: 700;
      font-size: 20px;
      padding: 12px 40px;
      height: fit-content;
      @media screen and (max-width: $screen-sm-max) {
        font-weight: 700;
        font-size: 16px;
        padding: 8px 28px;
      }
    }
  }

  & .top-earners-container {
    padding-top: 72px;
    @media screen and (max-width: $screen-sm-max) {
      padding-top: 32px;
    }
    & .header {
      & .title {
        color: #ffffff;
        font-weight: 900;
        font-size: 90px;
        line-height: 1;
        margin-bottom: 40px;
        @media screen and (max-width: $screen-sm-max) {
          font-weight: 800;
          font-size: 48px;
        }
      }
      & .sub-title {
        color: #a6a6a6;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 36px;
        @media screen and (max-width: $screen-sm-max) {
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 30px;
        }
      }
      & .hero-btn {
        border-radius: 9px;
        background: #edebdc;
        box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.1);
        padding: 11px 57px 11px 56px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        height: auto;
        @media screen and (max-width: $screen-sm-max) {
          width: 100%;
        }
      }
      & .filter-container {
        background: #1a1a1c;
        border: 1px solid #393939;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        max-width: 424px;
        padding: 8px;
        height: 77px;
        margin: 0 auto;
        margin-bottom: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: $screen-sm-max) {
          border-radius: 15px;
          height: 56px;
          margin-bottom: 16px;
        }
        & .selected-filter {
          background: #ffffff;
          border: 1px solid #393939;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
          color: #141414 !important;
          @media screen and (max-width: $screen-sm-max) {
            border-radius: 9px;
            font-size: 16px;
          }
        }
        & .filter {
          align-items: center;
          border-radius: 16px;
          color: #a6a6a6;
          cursor: pointer;
          font-weight: 600;
          font-size: 24px;
          display: flex;
          height: 100%;
          text-align: center;
          justify-content: center;
          text-transform: capitalize;
          @media screen and (max-width: $screen-sm-max) {
            border-radius: 9px;
            font-size: 16px;
          }
        }
      }
    }

    & .leaderboard {
      text-align: center;
      margin-top: 110px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 90px;
      }
      & .title {
        font-size: 80px;
        font-weight: 800;
        line-height: 100%; /* 80px */
        @media screen and (max-width: $screen-sm-max) {
          font-size: 40px !important;
          line-height: 44px !important;
        }
      }
    }

    & .upcoming-card {
      background-color: #151517;
      border: none;
      text-align: center;
      height: 500px;
      @media screen and (max-width: $screen-sm-max) {
        height: 350px;
      }
      & .main-text {
        font-size: 32px;
        font-weight: 800;
        color: #fff;
        justify-content: center;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 24px;
        }
      }
      & .going-date {
        font-size: 72px;
        font-weight: 800;
        color: #fff;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 52px;
        }
      }
    }
    & .column-title {
      font-weight: 700;
      font-size: 20px;
      color: #a6a6a6;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
      }
    }
  }

  & .people-shares {
    & .slider {
      & .ant-col {
        @media screen and (max-width: $screen-sm-max) {
          width: 70vw;
          overflow: hidden;
        }
      }
    }
  }

  & .reward-container {
    background: linear-gradient(
      286.83deg,
      rgba(27, 27, 30, 0) 0%,
      #1b1b1e 68.16%
    );
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1));
    border-radius: 12px;
    margin-top: 16px;
  }

  & .earners-rewards {
    background: linear-gradient(
      286.83deg,
      rgba(27, 27, 30, 0) 0%,
      #1b1b1e 68.16%
    );
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1));
    border-radius: 48px;
    margin-bottom: 32px;
    padding-bottom: 20px;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }

  & .reward-image {
    width: 72px;

    @media screen and (max-width: $screen-sm-max) {
      width: 62px;
    }
  }

  & .reward-text {
    color: #d9c5a8;
    font-weight: 600;
    font-size: 24px;
    max-width: 266px;
  }

  & .reward-title {
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
    padding: 32px 24px 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    @media screen and (max-width: $screen-sm-max) {
      font-weight: 500;
      font-size: 16px;
      color: #d9c5a8;
      margin-bottom: 12px;
    }
  }

  & .time-counter {
    color: #fff;
  }

  & .billboard-image {
    width: 100%;
    @media screen and (min-width: $screen-sm-max) {
      border-top-right-radius: 48px;
      border-top-left-radius: 48px;
    }
    @media screen and (max-width: $screen-sm-max) {
      height: 100% !important;
      object-fit: cover;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }

  & .more-btn {
    font-weight: 500;
    font-size: 16px;
    text-decoration: underline;
    text-decoration-color: #ffffff;
    color: #ffffff;
    cursor: pointer;
    padding: 0px 24px 0px;
  }

  & .faq {
    @media screen and (max-width: $screen-sm-max) {
      padding-top: 40px;
    }
  }

  & .share {
    padding: 120px 0 0;
    & .title {
      color: #141414;
      text-align: center;
      font-size: 80px;
      font-weight: 500;
      line-height: 110%; /* 88px */
      margin-bottom: 80px;
    }
    & .marquee {
      padding-right: 60px;
    }
  }

  & .faq-space {
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 10px;
    }
  }

  & .faq-answer {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    padding: 0 8px 30px !important;
  }
  & .ant-collapse-header {
    padding-bottom: 10px !important;
    @media screen and (max-width: $screen-sm-max) {
      padding: 20px !important;
    }
  }
}

.billboard-modal {
  & .billboard-gif {
    width: 100%;
    border-radius: 12px;
    margin-bottom: 32px;
  }
  & .modal-title {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 32px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 24px;
    }
  }
  & .modal-desc {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -0.03em;
    margin-bottom: 46px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 18px;
    }
  }
}
