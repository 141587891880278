.public-nav {
  padding: 7px 0;
  height: $navbar-height;
  background-color: #fff;
  z-index: 99;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.102);
  position: fixed;
  left: 0;
  right: 0;
  transition: top 0.6s ease-in;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $screen-sm-max) {
    padding: 8px 0;
    height: $navbar-height-mobile;
    & .logo img {
      width: 30px;
      transition: width 0.6s ease;
    }
  }
  &.category-bg {
    background-color: #faf7f2;
  }
  & .nav-links {
    align-items: center;
    justify-content: flex-end;
    @media screen and (min-width: $screen-md-min) {
      display: flex;
    }
  }
  & .logo {
    height: 100%;
    display: inline-flex;
    align-items: center;
    & img {
      width: 160px;
      transition: width 0.6s ease;
      @media screen and (max-width: $screen-sm-max) {
        width: 28px;
      }
    }
  }
  &.flat {
    @media screen and (min-width: $screen-md-min) {
      height: 72px;
      box-shadow: none;
      transition: all 0.6s ease;
      & .btn-login {
        // background-color: transparent;
        transition: background 0.6s ease;
      }
      & .logo {
        & img {
          width: 200px;
          transition: width 0.6s ease;
        }
      }
    }
  }
  & .nav-btn {
    font-size: 16px;
    line-height: 20px;
    height: 48px;
    font-weight: 500;
    &.btn-login {
      padding: 0 24px;
    }
    @media screen and (max-width: $screen-sm-max) {
      background: #f2f1f1;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-weight: 600;
    }
  }
  & .btn-get-started {
    font-weight: 700;
  }
  & .public-nav-header {
    display: flex;
    align-items: center;
  }
  & .public-menu {
    background: none;
    display: inline-flex;
    border: none;
    width: auto;
    align-items: center;
    justify-content: flex-start;
    margin-right: 15px;
    margin-left: 25px;
    transition: all 0.25s ease-in;
    border-inline-end: none !important;
    & .ant-menu-item,
    & .ant-menu-submenu {
      padding: 0 14px !important;
      margin: 0;
      border: 0 !important;
      &:hover,
      &:focus,
      &:active {
        color: $text-color;
        transition: all 0s;
        border: 0 !important;
        background: none !important;
      }
      &::after {
        border: 0 !important;
        transition: all 0s;
      }
      &.ant-menu-submenu-active,
      &.ant-menu-item-active {
        &::after {
          border: none;
        }
      }
    }
  }
  & .public-menu-links {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    & .links {
      margin: 0 20px;
    }
  }
  & .ai-search-button {
    border-radius: 56px;
    background: #8a60f6 !important;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    &:hover {
      color: #fff !important;
    }
    @media screen and (min-width: $screen-sm-max) {
      padding: 11px 0px 11px 11px;
    }
  }
  & .dark-menu-link {
    color: #fff !important;
    border-bottom: 1px solid transparent !important;
    &:hover {
      color: #fff !important;
      border-bottom: 1px solid #fff !important;
    }
    &.active {
      border-bottom: 1px solid #fff !important;
    }
  }
  & .public-menu-link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $text-color;
    &.btn {
      background-color: #fff;
      color: $text-color !important;
      border-radius: 9px;
      padding: 11px 18px;
      background:
        radial-gradient(
            circle at 100% 100%,
            #ffffff 0,
            #ffffff 7px,
            transparent 7px
          )
          0% 0%/9px 9px no-repeat,
        radial-gradient(
            circle at 0 100%,
            #ffffff 0,
            #ffffff 7px,
            transparent 7px
          )
          100% 0%/9px 9px no-repeat,
        radial-gradient(
            circle at 100% 0,
            #ffffff 0,
            #ffffff 7px,
            transparent 7px
          )
          0% 100%/9px 9px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 7px, transparent 7px)
          100% 100%/9px 9px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 4px)
          calc(100% - 18px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 18px)
          calc(100% - 4px) no-repeat,
        linear-gradient(64deg, #ffd000 8%, #fe393b 43%, #8a30da 100%);
      transition: background 0.5s linear;
      &:hover,
      &:focus,
      &:active {
        border: none !important;
      }
      @media screen and (max-width: $screen-sm-max) {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
    &.active,
    &:focus,
    &:active,
    &:hover {
      // transition: all 0.25s ease-in;
      color: $text-color;
      border-bottom: 1px solid $text-color;
    }
  }
  &.back-dark {
    background-color: $text-color !important;
  }
  & .ant-menu-submenu-open {
    & .public-menu-link {
      border-bottom: 1px solid $text-color !important;
      @media screen and (max-width: $screen-sm-max) {
        border-bottom: none !important;
      }
    }
    & .dark-menu-link {
      border-bottom: 1px solid #fff !important;
    }
  }
}

.nav-submenu {
  border-inline-end: none !important;
  background: none !important;

  & > .ant-menu-submenu-inline {
    & > .ant-menu-submenu-title {
      padding: 0px 12px !important;
      background: #fad9d6 !important;
      border-radius: 3px;
      height: 40px;
      font-size: 12px;
      & > svg,
      & > img {
        padding: 0px !important;
        background: none;
        border-radius: 9px;
        transition: all 0.25s linear;
      }
      & > svg {
        font-size: 18px !important;
      }
    }
  }
  & .ant-menu-item {
    padding-left: 24px !important;
    & > svg,
    & > img {
      padding: 8px;
      background: #f7f7f7;
      border-radius: 9px;
      transition: all 0.25s linear;
    }
    & > svg {
      font-size: 40px !important;
    }
    & > img {
      width: 40px;
      height: auto;
    }
  }
  & .ant-menu-submenu-title {
    padding-left: 24px !important;
    & > svg,
    & > img {
      padding: 8px;
      background: #f7f7f7;
      border-radius: 9px;
      transition: all 0.25s linear;
    }
    & > svg {
      font-size: 30px !important;
      background: none;
    }
    & > img {
      width: 40px;
      height: auto;
    }
  }
  & .ant-menu-submenu-arrow {
    width: 15px;
  }
  & .ant-menu-inline {
    position: fixed;
    left: 0;
    margin-top: 12px;
    border-radius: 12px !important;
    width: -webkit-fill-available;
    right: 2.5%;
    left: 2.5%;
    width: 95%;
    background-color: #fff !important;
    box-shadow: 0px 2px 10px rgb(15 16 16 / 30%) !important;
  }
  & .ant-menu-title-content {
    font-weight: 500;
    font-size: 16px;
  }
}

.public-submenu {
  & .ant-menu-item {
    margin: 4px 12px !important;
    padding: 0px 8px;
    display: flex !important;
    align-items: center;
    width: calc(100% - 24px) !important;
    &:first-of-type {
      margin: 18px 12px 4px !important;
    }
    &:last-of-type {
      margin: 4px 12px 18px !important;
    }
    & > svg,
    & > img {
      padding: 8px;
      background: #f7f7f7;
      border-radius: 9px;
      transition: all 0.25s linear;
    }
    & > svg {
      font-size: 40px !important;
    }
    & > img {
      width: 40px;
      height: auto;
    }
    transition: all 0.25s linear;
    &.ant-menu-item-selected {
      background-color: #f7f7f7;
      color: $text-color;
      font-weight: 700;
      & > svg,
      & > img {
        background-color: #fff;
      }
    }
    &:hover,
    &:focus {
      transition: all 0.25s linear;
      & > svg,
      & > img {
        background-color: #fff;
        transition: all 0.25s linear;
      }
      & .ant-menu-title-content {
        font-weight: 700;
        transition: all 0.25s linear;
      }
    }
  }
  & .ant-menu-title-content {
    margin-right: 50px;
    font-size: 16px;
    line-height: 18px;
  }
}
