.social-media {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.06);
  & .social-media-icons {
    color: #141414;
    font-size: 20px;
  }
  @media screen and (max-width: $screen-sm-max) {
    margin-right: 4px;
    width: 32px;
    height: 32px;
  }
}

.public-profile {
  margin-top: 64px !important;
  & .mobile-hide {
    display: block;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }
  & .crest-title-section {
    display: flex;
    align-items: center;
    & .crests-profile-img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  & .public-profile-section-heading {
    font-size: 46px !important;
    line-height: 42px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  & .profile-content {
    & .header {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 0;
      line-height: 52px;
      font-size: 46px;
      font-weight: 600;
      & button {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
      }
      @media screen and (max-width: $screen-md-max) {
        margin-top: 0 !important;
        font-size: 23px;
        font-weight: 500;
        line-height: 27px;
        margin-left: 10px;
      }
    }
    & .title {
      font-size: 16px;
      line-height: 28px;
      margin-top: 30px;
      margin-bottom: 24px;
      color: #434343;
      width: 75%;
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
      }
    }
    & .status {
      height: 40px;
      width: 120px;
      border-radius: 24px;
      display: inline-flex;
      border: 1px solid #e3e3e3;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      & .status-icon {
        background-color: #10985b;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    & .share-profile {
      margin-top: 42px !important;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      padding: 12px 50px;
      font-weight: 600;
      & .share-icon {
        margin-right: 10px;
      }
    }
  }

  & .service-card {
    background: #ffffff;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    margin-bottom: 30px !important;
    border: none;
    cursor: pointer;
    min-height: 197px;
    transition: box-shadow 0.3s ease-in-out;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.show-more {
      margin-left: 33%;
    }
    & .top-section {
      display: flex;
      align-items: flex-start;
      padding: 25px 24px 24px;
      width: 100%;
    }
    & .service-card-cta {
      transition: all 0.3s ease-in-out;
      font-weight: 600;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      display: none;
      &.desktop {
        @media screen and (min-width: $screen-sm-max) {
          display: block;
        }
      }
      &.mobile {
        @media screen and (max-width: $screen-sm-max) {
          display: block;
        }
      }
      &.basic {
        & > svg {
          margin-right: 8px;
        }
      }
      &.circular {
        min-width: 32px;
        height: 32px;
        padding: 0;
        margin: 0;
        font-size: 18px;
        & > svg {
          margin: 0;
        }
      }
    }
    &:hover {
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
      transition: all 0.3s ease-in-out;
      & .service-card-cta {
        background-color: black !important;
        color: white !important;
        transition: all 0.3s ease-in-out;
      }
    }
    & .card-side-section {
      display: flex;
      width: auto;
      margin-right: 16px;
      & img {
        min-width: 28px;
        border-radius: 50%;
        object-fit: contain;
      }
    }
    & .card-duration-block {
      display: flex;
      align-items: center;
      justify-content: space-around;
      align-self: flex-end;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      & .card-duration-img {
        margin-right: 8px;
      }
    }
    & .card-main-section {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      & .card-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 10px;
        & .service-card-title {
          font-size: 20px;
          line-height: 28px;
          font-weight: 600;
          width: 70%;
          margin-bottom: 0;
          margin-top: 1px;
        }
        & .header-actions {
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      & .card-content {
        display: flex;
        height: 100%;
        & .service-description {
          max-width: 75%;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
    & .card-footer-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 16px;
      border-top: 1px solid rgba(225, 225, 225, 0.4);
      min-height: 57px;
    }
  }
  & .card-overview {
    width: 100%;
    height: 232px;
    background: #ffffff;
    overflow: auto;
    border: 1px solid #f1f1f1;
    border-radius: 12px;
    padding: 28px;
    margin-bottom: 24px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 18px;
    }
    & .top-section {
      display: flex;
      align-items: center;
      padding: 5px 5px 5px;
      width: 100%;
      justify-content: space-between;
      & .card-title-box {
        font-weight: 600;
        font-size: 15px;
      }
    }
    & .card-description {
      font-size: 15px;
      line-height: 25px;
      white-space: break-word;
      margin-top: 24px;
    }
  }

  & .about-me {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
    & .user-description {
      font-size: 19px;
      line-height: 34px;
      font-weight: 500;
      white-space: break-spaces;
    }
    & .about-icon {
      margin-left: -30px;
      position: relative;
      & .title-bg {
        margin-left: -60px;
      }
      & .main-img {
        width: 230px;
        max-width: 352px;
        position: absolute;
        top: 20px;
        left: 0;
        object-fit: contain;
      }
    }
  }
  & .tweak-tag {
    background-color: rgba(102, 84, 245, 0.08);
    padding: 5px 24px;
    border-radius: 22px;
    color: #6654f5;
    font-size: 15px;
    line-height: 26px;
    margin-right: 12px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    & svg,
    & img {
      margin-right: 5px;
    }
  }

  & .booking-number {
    border-radius: 24px;
    padding: 8px 16px 6px;
    font-size: 15px;
    font-weight: 500;
    background-color: rgba(252, 240, 198, 0.69);
    @media screen and (max-width: $screen-sm-max) {
      font-size: 13px;
      padding: 5px 12px;
    }
  }

  & .booking-number-service-card {
    background-color: #fffeef;
    border: 1px solid #f1ecb5;
    border-radius: 24px;
    padding: 3px 8px;
    position: absolute;
    top: -6px;
    left: 65%;
    font-size: 15px;
    font-weight: 500;
  }
  & .microsoft-tag {
    margin-top: 40px;
    cursor: pointer;
    max-width: 350px;
    border-radius: 35px;
    background: rgb(253, 244, 230);
    box-shadow: 0 0 0 0 rgb(253, 244, 230);
    animation: pulse-orange 2s infinite;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
  @keyframes pulse-orange {
    0% {
      transform: scale(0.999);
      box-shadow: 0 0 0 0 #facd86;
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
      transform: scale(0.999);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
  }
}

.tweak-nav {
  background-color: #fbe8e6;
  height: 62px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  padding: 8px 0px;
  @media screen and (max-width: $screen-sm-max) {
    height: 56px;
  }
  & .tweak-tag {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .tweak-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .tweak-text {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: $text-color;
    display: inline-flex;
    align-items: center;
    // margin-left: 15px;
    margin-right: 24px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 15px;
      line-height: 20px;
      margin-right: 10px;
      text-align: left;
    }
  }
  & .tweak-btn {
    // background-color: #fff;
    color: #141414;
    height: 32px;
    border-radius: 6px;
    // border-color: #fff;
    padding: 0 24px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    &:hover,
    &:focus {
      background-color: darken(#fff, 10);
      border-color: darken(#fff, 10);
    }
    @media screen and (max-width: $screen-sm-max) {
      height: 32px;
      font-size: 13px;
      padding: 0 10px;
    }
  }
}

.public-profile-footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 100% !important;
  background-color: #fafafa;
  padding: 40px 20px 50px !important;

  & .help-text {
    display: block;
    font-weight: 600;
    font-size: 15px;
    color: #243239;
  }
  & .help-btn {
    background: #fafafa;
    border: 1px solid #141414;
    border-radius: 6px;
    margin-top: 16px;
  }
  & .footer-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 175%;
    text-align: center;
    letter-spacing: 0.15px;
  }

  & .username-input-outer {
    background: #ffffff;
    // border: 1px solid #ecebec;
    border-radius: 41px;
    min-width: 340px;
    height: 48px !important;
    font-size: 24px;
    display: flex;
    margin-top: 1rem !important;
    align-items: center;
    padding: 10px 10px 10px 10px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
      min-width: auto;
    }
    & > p {
      margin: 0 !important;
    }
  }

  & .username-input-arrow {
    color: #008060;
    width: 1.18em !important;
    transition: none !important;
    opacity: 1 !important;
  }

  & .username-input {
    font-size: 24px;
    padding: 0;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
  &.show-more {
    margin-left: 40%;
  }
}

.mobile-visible {
  display: none !important;
}

@media screen and (max-width: $screen-sm-max) {
  .mobile-hide {
    display: none !important;
  }
  .mobile-visible {
    display: block !important;
  }
  .public-profile {
    margin-top: 0px !important;
    & .public-profile-section-heading {
      font-size: 32px !important;
      line-height: 24px;
      font-weight: 900;
      margin-bottom: 24px;
    }
    & .profile-content {
      flex-direction: column;
      align-items: center;
      & .header {
        margin-top: 0 !important;
        margin-bottom: 10px !important;
        line-height: 28px;
        font-weight: 600 !important;
        font-size: 23px !important;
      }
      & .social-media {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.06);
        // margin-top: 14px;
        & .social-media-icons {
          color: #141414;
          font-size: 16px;
        }
      }
    }
    & .service-card {
      margin-bottom: 15px !important;
      justify-content: space-between;
      &.show-more {
        margin-left: 33%;
      }
      & .top-section {
        padding: 22px 16px 17px;
      }
      & .card-main-section {
        justify-content: space-between;
        & .card-content {
          & .service-description {
            max-width: 100%;
          }
        }
      }
      & .card-header {
        width: 100%;
        & .service-card-title {
          margin: 0;
          width: 100% !important;
        }
      }
      & .card-duration-block {
        align-self: center;
      }
    }

    & .about-me {
      & .about-icon {
        display: none;
      }
    }
    & .show-more {
      margin-left: 33%;
    }
  }
}

.claim-btn {
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #141414;
  height: 48px;
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    background: #fff !important;
    box-shadow: 4px 4px 0px #000000;
    transition: 0.25s all ease-in-out;
  }
}

.public-profile-nav {
  box-shadow: 0px 1px 0px #e5e9f2;
  margin: 0 !important;
  min-height: 56px;
  justify-content: center;
  display: none;
  padding: 6px 0;
  & .user-name {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
  }
  @media screen and (max-width: $screen-sm-max) {
    display: block;
  }
}

.package-webinar {
  & .pw-body {
    margin-top: 12px;
    padding-bottom: 120px;
  }
  & .pw-card {
    margin-top: 20px;
    padding-bottom: 20px !important;
    border-bottom: 1px solid #e3e8ed;
    padding: 0 20px;
  }
  & .pw-title {
    font-size: 15px;
    line-height: 26px;
    font-weight: 700;
  }
  & .cta {
    margin-top: 0 !important;
    border-bottom: 1px solid #e3e8ed;
    border-top: 1px solid #e3e8ed;
    width: 100%;
    padding: 20px;
    & button {
      width: 100%;
    }
  }
  & .booking-price-difference {
    display: block;
    position: initial;
    width: 100%;
    color: #fff !important;
    @media screen and (max-width: $screen-sm-max) {
      position: fixed;
    }
  }
}

.public-profile-pricing {
  padding: 0 12px;
  font-size: 17px !important;
  font-weight: 700 !important;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 24px;
  border: 1px solid #141414;
  background: transparent;
  white-space: nowrap;
  color: #141414;
  width: fit-content;
  @media screen and (max-width: $screen-sm-max) {
    padding: 8px 12px;
    font-size: 16px !important;
    // border-radius: 9px;
  }
}

.font-override {
  &.montserrat {
    & .public-profile * {
      font-family: "Montserrat", sans-serif !important;
    }
  }
}

.subscribe-modal {
  &.modal-container {
    top: 60px;
    @media screen and (max-width: $screen-sm-max) {
      display: block !important;
      width: 90%;
    }
  }
  & .sm-profile-container {
    height: 170px;
    padding: 24px;
    & .sm-profile-pic {
      border-radius: 50%;
    }
  }
  & .sm-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    margin-top: 24px;
    padding: 0 24px;
  }
  & .sm-subtitle {
    font-size: 16px;
    line-height: 22px;
    color: rgba(20, 20, 20, 0.6);
    margin-top: 12px;
    margin-bottom: 20px;
    padding: 0 24px;
  }
  & .sm-form {
    padding: 0 24px;
  }
  & .sm-cta-wrapper {
    padding: 24px;
    margin-top: 60px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.testimonials {
  padding: 60px 0;
  & .t-wrapper {
    background: #f7f7f7;
    border-radius: 40px;
    height: 105%;
    padding: 40px 40px 40px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 30px;
      padding: 20px 25px;
      border-radius: 0;
      height: 100%;
    }
  }
  & .title {
    font-size: 46px;
    line-height: 60px;
    margin-bottom: 36px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 20px;
    }
  }
  & .t-stats-left {
    // gap: 20px;
  }
  & .t-stats {
    background: #f7f7f7;
    border-radius: 28px;
    height: 185px;
    padding: 24px 32px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 24px 12px;
      height: 98px;
      gap: 8px;
    }
    & .ts-prop {
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      white-space: nowrap;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 12px;
        line-height: 15px;
      }
    }
    & .ts-values {
      font-size: 32px;
      line-height: 32px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
}

.testimonial-swiper {
  position: relative;
  padding-top: 6px;
  padding-bottom: 60px;
  & .swiper-slide {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    // grid-auto-rows: 200px;
    grid-auto-flow: dense;
    &.len-1 {
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: $screen-sm-max) {
        grid-template-columns: 1fr;
      }
    }
  }
  & .t-card {
    background: #ffffff;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    padding: 32px 36px 24px 26px;
    display: inline-flex;
    flex-flow: column;
    justify-content: space-between;
    overflow: hidden;
    @media screen and (max-width: $screen-sm-max) {
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.04);
      padding: 24px 16px;
    }
    &.short {
      min-height: 65%;
    }
    &.long {
      grid-row: span 2;
    }
  }
  & .swiper-pagination {
    position: absolute;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // bottom: -20px;
  }
  & .swiper-pagination-bullet {
    // min-width: 50px;
    width: 12px !important;
    height: 12px !important;
    border-radius: 50%;
    background: #e4e4e4;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background-color: $text-color;
    }
    @media screen and (max-width: $screen-sm-max) {
      border-radius: 50%;
      min-width: auto;
    }
  }
  & .t-card-text {
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  & .t-avatar {
    @media screen and (max-width: $screen-sm-max) {
      max-height: 98px;
      max-width: 98px;
      object-fit: contain;
      aspect-ratio: 1/1;
    }
  }
}

.space {
  background-color: #000;
  // padding: 100px;
  min-height: 200vh;
  & .space-btn {
    background: #1a1a1c;
    border: 1px solid #393939;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    height: 100%;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #d9c5a8 !important;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 16px;
      line-height: 18px;
      height: 44px;
    }
  }
  & .nav {
    position: fixed;
    top: 48px;
    height: 80px;
    padding: 10px;
    background: rgba(26, 26, 28, 0.9);
    border: 1px solid #393939;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(6px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 24px;
    min-width: 40%;
    left: 30%;
    right: 30%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: $screen-sm-max) {
      left: 20px;
      right: 20px;
      height: 56px;
      padding: 6px;
      top: 20px;
    }
    & .title {
      color: #fff;
      font-size: 32px;
      line-height: 36px;
      font-weight: 700;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  & .floating-img {
    position: absolute;
    &.space-1 {
      right: -60px;
      top: 40px;
      @media screen and (max-width: $screen-sm-max) {
        right: 0;
        top: 60px;
        & img {
          width: 95px;
          margin-right: -34px;
        }
      }
    }
    &.space-2 {
      left: -60px;
      bottom: 20px;
      @media screen and (max-width: $screen-sm-max) {
        left: 0;
        bottom: 0px;
        & img {
          width: 95px;
          margin-left: -34px;
        }
      }
    }
  }
  & .hero {
    position: relative;
    padding: 240px 0 150px;
    text-align: left;
    @media screen and (max-width: $screen-sm-max) {
      padding: 180px 20px 120px;
      overflow: hidden;
    }
    & .title {
      color: #d9c5a8;
      text-align: left;
      font-weight: 800;
      font-size: 80px;
      line-height: 80px;
      letter-spacing: -0.05em;
      margin-bottom: 50px;
      @media screen and (max-width: $screen-sm-max) {
        font-weight: 800;
        font-size: 48px;
        line-height: 48px;
      }
    }
    & .subtitle,
    & .subtitle > span {
      font-size: 28px;
      line-height: 34px;
      color: #fff;
      font-weight: 500;
      text-align: left;
      width: 60%;
      // margin: 0 auto;
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
    }
    & .hero-cta {
      background: #1a1a1c;
      color: #fff;
      min-width: 30%;
      border: 1px solid #393939;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      height: 64px;
      font-weight: 700;
      font-size: 19px;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 48px;
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
      }
    }
  }
  & .timer {
    padding: 200px 0 100px;
    text-align: center;
    position: relative;
    @media screen and (max-width: $screen-sm-max) {
      padding: 100px 0;
    }
    & .heading {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 0;
      color: #fff;
      @media screen and (max-width: $screen-sm-max) {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
    }
    & .subheading {
      font-size: 200px;
      line-height: 220px;
      font-weight: 800;
      margin: 30px 0 0;
      color: #fff;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 64px;
        line-height: 70px;
      }
    }
  }
  & .testimonials {
    margin: 100px 0;
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 50px 0;
      padding: 0 20px;
    }
    & .title {
      color: #d9c5a8;
      font-size: 72px;
      line-height: 72px;
      font-weight: 500;
      letter-spacing: -0.05em;
      width: 95%;
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
        font-size: 40px;
        line-height: 44px;
        font-weight: 500;
      }
      & a {
        font-weight: 800;
        text-decoration: underline;
      }
    }
  }
  & .flip-clock {
    color: #fff;
  }
  & .card-number {
    position: absolute;
    top: -15px;
    left: 15px;
    font-size: 80px;
    line-height: 88px;
    color: rgba(255, 255, 255, 0.05);
    font-weight: 800;
  }
  & .cards-section {
    margin: 100px 0;
    position: relative;
    & .title-travellers {
      margin: 300px auto 100px;
      max-width: 60%;
      font-size: 72px;
      line-height: 80px;
      color: #d9c5a8;
      font-weight: 400;
      text-align: center;
      letter-spacing: -0.05em;
      & strong {
        font-weight: 900;
        color: #d9c5a8;
      }
      @media screen and (max-width: $screen-sm-max) {
        padding: 0 20px;
        max-width: 100%;
        margin: 100px 0;
        text-align: center;
        font-size: 48px;
        line-height: 1;
      }
    }
    & .space-1 {
      top: 180px;
      @media screen and (max-width: $screen-sm-max) {
        top: -20px;
        width: 95px;
      }
    }
    & .space-2 {
      top: 230px;
      @media screen and (max-width: $screen-sm-max) {
        bottom: 80px;
        width: 95px;
        margin-left: -40px;
      }
    }
    & .card {
      background: #1b1c1e;
      border: 1px solid #393939;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      padding: 32px 40px;
      position: relative;
      overflow: hidden;
      min-height: 390px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 20px 16px;
        min-height: 280px;
        margin-bottom: 20px;
      }
      &.winner {
        margin-top: 48px;
        @media screen and (max-width: $screen-sm-max) {
          padding-top: 200px;
        }
        & .title {
          margin-bottom: 50px;
          @media screen and (max-width: $screen-sm-max) {
            margin-bottom: 30px;
          }
        }
        & .subtitle {
          width: 50%;
          display: flex;
          align-items: flex-start;
          @media screen and (max-width: $screen-sm-max) {
            width: 100%;
          }
          & svg {
            flex-shrink: 0;
            margin-right: 20px;
          }
        }
      }
      & .title {
        font-size: 56px;
        line-height: 62px;
        color: #fff;
        font-weight: 800;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 32px;
          line-height: 36px;
        }
      }
      & .subtitle {
        font-size: 22px;
        line-height: 28px;
        color: #a9a9a9;
        font-weight: 500;
        margin-top: 30px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 18px;
          line-height: 24px;
          margin-top: 10px;
        }
      }
      & .social {
        position: absolute;
        bottom: 0;
        right: 0;
        @media screen and (max-width: $screen-sm-max) {
          transform: scale(0.65);
          transform-origin: bottom right;
        }
      }
      & .username-input-outer {
        margin: 0;
        margin-top: 64px !important;
        background: rgba(26, 26, 28, 0.9);
        border: 1px solid #393939;
        backdrop-filter: blur(6px);
        max-width: 100%;
        height: 80px;
        color: #fff;
        border-radius: 24px;
        align-items: center;
        display: flex;
        @media screen and (max-width: $screen-sm-max) {
          height: 56px;
          & img {
            max-width: 34px;
          }
        }
        & .ant-typography,
        input {
          color: #fff;
          font-size: 25px;
          line-height: 27px;
          font-weight: 700;
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #585859;
            opacity: 1; /* Firefox */
          }
          @media screen and (max-width: $screen-sm-max) {
            font-size: 22px;
            line-height: 24px;
          }
        }
      }
      & .image-section {
        & img {
          position: absolute;
        }
        & .img-1 {
          bottom: 0;
          right: 0;
          @media screen and (max-width: $screen-sm-max) {
            top: 0;
            left: 0;
            right: 0;
            max-height: 180px;
            object-fit: cover;
          }
        }
      }
    }
  }
  & .user-slider {
    margin: 100px 0;
    & .card-number {
      color: rgba(217, 197, 168, 0.28);
      top: 10px;
      left: 24px;
      font-size: 80px;
      line-height: 88px;
      @media screen and (max-width: $screen-sm-max) {
        top: 10px;
        left: 12px;
        font-size: 42px;
        line-height: 46px;
      }
    }
    & .user-slide {
      height: 392px;
      background: #1a1a1c;
      border: 1px solid #393939;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @media screen and (max-width: $screen-sm-max) {
        height: 210px;
      }
    }
    & .user-slider-img {
      width: 110%;
      position: absolute;
      // object-fit: cover;
      object-position: center;
      top: 0;
      left: 0;
    }
    & .user-details-section {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      padding: 60px 24px 40px;
      position: relative;
      bottom: 0;
      z-index: 99;
      width: 100%;
      text-align: left;
      @media screen and (max-width: $screen-sm-max) {
        padding: 32px 12px 24px;
        left: 0;
      }
    }
    & .user-name {
      font-weight: 600;
      font-size: 32px;
      line-height: 36px;
      color: #ffffff;
      display: inline-flex;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      @media screen and (max-width: $screen-sm-max) {
        font-size: 12px;
        line-height: 14px;
      }
    }
    & .subheading {
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      color: #ffffff;
      display: inline-flex;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      @media screen and (max-width: $screen-sm-max) {
        font-size: 12px;
        line-height: 14px;
      }
    }
    & .user-link {
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      color: #a3a3a3;
      margin-top: 10px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  & .initial-child-container {
    gap: 40px;
  }
}

.faq {
  padding: 200px 0;
  @media screen and (max-width: $screen-sm-max) {
    padding: 100px 0;
  }
  & .title {
    color: #d9c5a8;
    font-size: 100px;
    line-height: 110px;
    font-weight: 800;
    text-align: center;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 48px;
      line-height: 48px;
    }
  }
  & .faq-space {
    margin-top: 60px;
    & .faq-item {
      min-height: 80px;
      background: #181b1d;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      margin-top: 18px;
      @media screen and (max-width: $screen-sm-max) {
        min-height: 80px;
      }
    }
    & .ant-collapse-header {
      font-weight: 700;
      font-size: 24px;
      line-height: 42px;
      padding: 24px;
      display: flex;
      align-items: center;
      @media screen and (max-width: $screen-sm-max) {
        padding: 20px;
        font-size: 20px;
        line-height: 32px;
      }
    }
    & .faq-answer {
      color: #757777;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      padding: 0 14px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 0px;
        font-size: 16px;
        line-height: 20px;
      }
      & a {
        color: #757777;
        text-decoration: underline;
      }
    }
    & * {
      color: #fff;
    }
  }
}

.stars,
.twinkling,
.clouds {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.stars {
  z-index: 0;
  background: #000 url("https://i.imgur.com/YKY28eT.png") repeat top center;
}

.twinkling {
  z-index: 0;
  background: transparent url("https://i.imgur.com/XYMF4ca.png") repeat top
    center;
  animation: move-twink-back 200s linear infinite;
}

.clouds {
  z-index: 0;
  background: transparent url("https://i.imgur.com/mHbScrQ.png") repeat top
    center;
  animation: move-clouds-back 200s linear infinite;
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}

@keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}
