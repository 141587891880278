.signup {
  & .main-heading {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  & .signup-title {
    font-weight: 700;
    font-size: 44px;
    margin-bottom: 4px;
    @media screen and (max-width: $screen-sm-max) {
      font-weight: 700;
      font-size: 32px;
    }
  }
  .signup-desc {
    color: rgba(20, 20, 20, 0.8);
    font-size: 16px;
    padding-bottom: 40px;
    font-weight: 500;
    line-height: 175%; /* 28px */
    letter-spacing: 0.1px;
    @media screen and (max-width: $screen-sm-max) {
      padding-bottom: 20px;
    }
  }
}
.page-container {
  height: 100vh;

  .email-signup {
    padding-top: 12px;
    display: block;
    height: fit-content;
    @media screen and (min-width: $screen-xs-max) {
      display: none !important;
    }
  }

  .brand-logo {
    // width: 136px !important;
    height: 54px !important;
    width: auto;
    @media screen and (max-width: $screen-sm-max) {
      // width: 112px !important;
      height: 42px !important;
      width: auto;
    }
  }

  .signup-logo {
    width: 137px !important;
    height: 24px !important;
    @media screen and (max-width: $screen-sm-max) {
      width: 80px !important;
      height: 16px !important;
    }
  }
  .signup-top-divider {
    margin: 16px 0px;
    @media screen and (max-width: $screen-sm-max) {
      margin: 8px 0px;
    }
  }
  .login-side-link {
    .login-side-button {
      border-radius: 6px;
      border: 1px solid #141414;
      color: #141414;
      font-size: 16px;
      font-weight: 500;
      height: auto;
      &:hover {
        border: 1px solid #141414;
        color: #141414;
      }
    }
    @media screen and (min-width: 768px) {
      margin-right: 48px;
    }
  }

  .form-container {
    margin-top: 64px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 42px;
    }
  }

  & .signup-right-image {
    align-items: center;
    background-size: cover !important;
    flex-direction: column;
    justify-content: center;
    text-align: center !important;
    font-weight: 600;
    font-size: 19px;
    line-height: 175%;
    padding: 24px;
    width: 100vh;
    background: url("/images/signup/signup.png");
    @media screen and (min-width: 768px) {
      display: flex !important;
    }
    @media screen and (max-width: 768px) {
      margin-top: 24px;
    }

    & .review-desc {
      color: white;
      @media screen and (min-width: 768px) {
        max-width: 70%;
      }
    }

    & .profile-image {
      margin-top: 32px;
      height: 78px;
      width: 78px;
    }
    & .name {
      color: white;
      margin-top: 1rem;
    }
    & .designation {
      color: white;
      font-size: 14px;
      margin-top: 4px;
    }
  }

  & .right-image-signup {
    background-image: url("/images/signup/SideBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    // min-height: 100vh;
    @media screen and (max-width: 768px) {
      padding: 24px 20px;
    }
    & .review-tweet {
      width: 364px !important;
      height: 327px !important;
      margin: auto;
      @media screen and (max-width: 768px) {
        position: unset !important;
        width: 100% !important;
        height: auto !important;
      }
    }
    & .review-topbot {
      width: 496px !important;
      height: 327px !important;
      margin: auto;
      @media screen and (max-width: 768px) {
        position: unset !important;
        width: 100% !important;
        height: auto !important;
      }
    }
    & .review-image {
      height: 350px !important;
      width: 350px !important;
      margin: auto;
      @media screen and (max-width: 768px) {
        position: unset !important;
        height: 300px !important;
        width: 300px !important;
      }
    }
    & .review-compliance {
      height: 334px !important;
      width: 397px !important;
      margin: auto;
      @media screen and (max-width: 768px) {
        position: unset !important;
        height: 286px !important;
        width: 342px !important;
      }
    }
  }
}
.spacing-top {
  margin-top: 16px !important;
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 8px !important;
  }
}
.error-number {
  background: #ffffff;
  border: 1px solid #e0b4b4;
  color: #9f3a38 !important;
}

.sign-up-form {
  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 40px !important;
  }
  & .name-input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    & .name-field {
      width: 48%;
      display: block !important;
    }
  }
  & .email-field {
    & .ant-input {
      text-transform: lowercase;
    }
  }
  & .ant-form-item {
    margin-bottom: 20px;
  }
  & .signup-title-fields {
    & .ant-form-item-label {
      padding-bottom: 4px;
    }
    & .ant-form-item-label > label {
      font-size: 15px;
      font-weight: 600;
      line-height: 175%; /* 26.25px */
      letter-spacing: 0.15px;
      color: #141414;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .form-submit-button {
    background-color: black !important;
    margin: 12px 0px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    height: 48px;
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 0px;
      margin-top: 4px;
    }
  }
  .submit-text {
    color: white;
  }
  .bottom-desc {
    color: rgba(20, 20, 20, 0.5);
    font-size: 16px;
    font-weight: 500;
    line-height: 175%; /* 28px */
    letter-spacing: 0.1px;
    margin-bottom: 32px;
  }
  .bottom-desc-link {
    color: rgba(20, 20, 20, 0.5) !important;
    text-decoration: underline !important;
  }
}
