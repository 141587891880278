.availability-tabs {
  & .schedule-menu {
    & .tabs-availability {
      display: flex;
      align-items: center;
      overflow-x: auto;

      & .btn-tab {
        color: #222;
        border: none;
        font-size: 14px;
        line-height: 18px;
        box-shadow: none;
        &.active {
          background-color: #f2f2f2;
          font-weight: 500;
        }
      }

      & .btn-new-schedule {
        font-size: 14px;
        font-weight: 500;
        margin-left: 16px;
        &:focus,
        &:hover,
        &:active {
          background: none;
        }
      }
    }
    & > .menu {
      border: none;
      box-shadow: none;
    }
    & .schedule-dropdown {
      font-size: 13px;
      line-height: 22px;
      font-weight: 400;
      justify-content: space-between;
      width: 90%;
      height: 36px;
      border-radius: 4px !important;
      border: 1px solid #a7acb199;
    }
  }
  .unavailable-modal-header {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 175%;
    letter-spacing: 0.5px;
    color: #141414;
  }

  .block-dates-discription {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 175%;
    letter-spacing: 0.15px;
    color: #666666;
  }

  & .flex-center {
    display: flex;
    align-items: center;
  }

  & .item {
    font-weight: 400;
    font-size: 15px;
    &.active {
      font-weight: 600;
      color: #008060 !important;
      border-color: #008060 !important;
    }
  }
}

.calendar-options {
  & .info {
    color: #686b60;
    font-weight: 500;
    font-size: 14px;
  }
  & .connect-option {
    margin-top: 32px;
    font-weight: 500;
    font-size: 16px;

    & .calendar-title {
      font-weight: 500;
      font-size: 16px;
      margin-left: 12px;
    }
  }
}

.configurations {
  & .add-calendar {
    box-shadow: none;
    background-color: #f7f7f7;
    border: none;
  }
  & .meeting-divider {
    margin: 0px !important;
    color: rgb(152 151 151 / 88%);

    &::before {
      @media screen and (min-width: $screen-sm-max) {
        width: 25%;
      }
    }
  }
  & .meeting-link-container {
    margin-bottom: 0px;
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 8px;
    }
  }
  .meeting-input-container {
    margin: 8px 0px 0px 30px;
    @media screen and (max-width: $screen-sm-max) {
      margin: 0px;
    }
  }
  & & .save-btn {
    width: 90px !important;
    height: 40px !important;
    border-radius: 20px !important;
    padding: 1px 12px !important;
    font-size: 14px;
  }
  & .cancel-btn {
    width: 85px !important;
    height: 40px !important;
    font-weight: 700;
    font-size: 14px;
    background: #f7f7f7 !important;
    border: none !important;
    padding: 1px 4px !important;
  }

  & .close-link-btn {
    width: 20px;
    height: 20px;
    background: #f7f7f7;
    border-radius: 24px;
    border: none;
    cursor: pointer;
    margin-left: 8px;
    margin-top: 10px;
  }

  & .meeting-config-row {
    padding: 16px 0 10px;
  }
  & .config-row {
    border-bottom: 1px solid rgba(242, 246, 249, 1);
    padding: 26px 0 20px;
  }
  & .config-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    margin-top: 32px;
  }
  & .config-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
  & .added-calendar {
    & .added-email {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #666666;
    }
    & .disconnect-btn {
      text-decoration-line: underline;
      font-weight: 600;
      font-size: 16px;
    }
    & .sync-tag {
      background: rgba(228, 67, 50, 0.1);
      border: 1px solid #e44332;
      border-radius: 6px;
      color: #e44332;
      padding: 4px 12px;
      margin-left: 12px;
    }
    & .primary-tag {
      color: #666666;
      font-size: 14px;
      font-weight: 500;
      & .check-icon {
        color: #38a169;
        margin-right: 6px;
      }
    }
    .calendar-dropdown {
      height: 34px;
      width: 34px;
      border-radius: 6px;
      background-color: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      & svg {
        font-size: 20px;
        color: #000000;
      }
      @media screen and (max-width: $screen-sm-max) {
        height: 30px;
        width: 26px;
      }
    }
  }
  & .config-type {
    display: flex;
    align-items: flex-start;
    & .config-icon {
      margin-right: 14px;
      font-size: 17px;
      margin-top: 2px;
    }
  }
  & .config-description {
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-top: 4px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@media screen and (max-width: 992px) {
  .timezone-select-slots {
    margin-top: 15px;
  }
  .inuput-link {
    width: 217px;
  }
  .btn-position {
    margin-top: 10px;
  }

  .meeting-link-slot {
    margin-top: 15px !important;
  }
}

.rmdp-shadow {
  box-shadow: none !important;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #008060 !important;
  color: #fff !important;
}

.rmdp-week {
  width: 320px;
}

.rmdp-day.rmdp-selected span:not(.hover) {
  background-color: #008060 !important;
}

.rmdp-day.rmdp-today span {
  background-color: rgba(0, 128, 96, 0.04) !important;
  color: #008060 !important;
}
.rmdp-day {
  color: #008060 !important;
}
.rmdp-week-day {
  font-weight: 700 !important;
  font-size: 12px !important;
  color: #666666 !important;
}
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #008060 !important;
  color: #fff !important;
}
.rmdp-arrow {
  border: solid black !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  height: 3px !important;
  margin-top: 5px !important;
  padding: 5px !important;
  width: 3px !important;
}
.rmdp-arrow :hover {
  color: #fff !important;
}
.rmdp-arrow-container:hover {
  border-radius: 50% !important;
  height: 30px !important;
  width: 30px !important;
  padding: 5px 10px 5px 9px !important;
  background-color: #008060 !important;
}
.rmdp-arrow-container {
  border-radius: 50% !important;
  cursor: pointer !important;
  display: flex !important;
  height: 18px !important;
  justify-content: center !important;
  margin: 0 5px !important;
  //padding: 5px !important;
  width: 20px !important;
  font-size: medium !important;
}
rmdp-header,
.rmdp-month-name {
  font-size: 15px !important;
}
.rmdp-day span {
  background-color: rgba(0, 128, 96, 0.04) !important;
}
.rmdp-day-hidden,
.rmdp-day-hidden span {
  background-color: white !important;
  color: transparent;
}
.rmdp-day-hidden,
.rmdp-day.rmdp-disabled {
  cursor: default !important;
  color: #8798ad !important;
}
.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
}
.rmdp-arrow-container .rmdp-arrow {
  height: 12px !important;
  width: 12px !important;
}
.block-date-modal {
  .block-dates-modal-cancel-button {
    background-color: #ffffff;
    border: 2px solid black !important;
    width: 45%;
    height: 36px;
    align-items: center;
    color: #000000 !important;
    padding: 0;
  }
  .block-dates-modal-submit-button {
    color: #ffffff !important;
    background-color: #000000 !important;
    border: 2px solid black !important;
    width: 45%;
    height: 36px;
    align-items: center;
    padding: 0;
  }
  & .rmdp-wrapper {
    width: 100%;
  }
  & .rmdp-calendar {
    padding: 0;
    & .rmdp-header {
      width: 100%;
      margin-bottom: 14px;
    }
    & .rmdp-day-picker {
      padding: 0;
    }
    & .rmdp-week {
      @media screen and (max-width: $screen-sm-max) {
        width: 75vw;
        margin-bottom: 6px;
      }
    }
  }
}

.analysis-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ant-tag-checkable-checked {
  background: #f7f7f7;
  border: 1px solid #141414 !important;
}
