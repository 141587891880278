.fs-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
}

.default-spinner {
  color: "#666";
}
