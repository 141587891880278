.share-options {
  & .ant-modal-content {
    padding-bottom: 8px;
    border-radius: 24px;
    padding: 0;
  }
  & .header {
    margin-bottom: 16px;
    align-items: center;
    & .back-icon {
      font-size: 20px;
    }
    & .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 110%;
      text-align: center;
    }
    & .close-share {
      cursor: pointer;
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
  & .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #686b60;
  }
}

.link-input {
  margin-top: 20px !important;
  border: 1px solid #e0e2da;
  border-radius: 9px;
  display: flex !important;

  & .copy-btn {
    background-color: #f4f4f4;
    border-radius: 6px !important;
    border: none;
    display: flex !important;
    align-items: center;
    margin: 10px 8px;
    height: 40px;
    font-weight: 700;
    &:hover {
      background-color: darken(#f4f4f4, 5%);
      color: $text-color;
    }
  }
}

.sharing-sites {
  margin-top: 20px;
  & .add-image {
    max-width: 320px;
    width: 100%;
    height: auto;
  }
  & .goto-site {
    margin-top: 24px;
    & .name {
      font-weight: 700;
      font-size: 18px;
      line-height: 110%;
    }
  }
}

.option-list {
  gap: 4px;
  display: flex;
  flex-direction: column;

  & .email-copy-btn {
    border-radius: 9px;
    background: #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #141414;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    &:hover,
    &:active {
      color: #141414 !important;
      border: none;
    }
  }
  & .social-option {
    align-items: center;
    font-size: 16px;
    padding: 12px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      background-color: #f7f7f7;
      border-radius: 6px;
    }
  }
  & .share-image {
    margin-right: 12px;
  }
  & .forward-icon {
    font-size: 12px;
  }
  & .sites {
    & .side-image {
      border-radius: 9px;
      margin-right: 12px;
    }
    font-weight: 700 !important;
  }
}
