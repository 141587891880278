// Ant Resets
.ant-typography,
div.ant-typography,
.ant-typography p {
  margin: 0;
  font-size: inherit;
  margin-bottom: 0;
}
.ant-modal-content {
  border-radius: 12px !important;
}
.ant-modal-body {
  border-radius: 12px !important;
}
.ant-modal-close {
  width: auto !important;
  height: auto !important;
  &:hover,
  &:focus {
    background: none !important;
  }
}
.ant-badge-count {
  font-size: 10px !important;
}

.ant-scrolling-effect {
  width: 100% !important;
}

.ant-form-item-control-input-content {
  & > .ant-input {
    line-height: 24px;
    min-height: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.ant-input {
  transition: all 0.3s ease-in-out;
  font-size: 16px !important;
}

.focus-override {
  outline: 1px solid $text-color;
  border: 1px solid $text-color;
  background-color: #f5f6f8;
  transition: all 0.2s ease-in-out;
  border-radius: $border-radius-base;
}
.ant-input-affix-wrapper,
.ant-input {
  &:focus,
  &.ant-input-affix-wrapper-focused {
    @extend .focus-override;
  }
  &:hover {
    border-color: $text-color !important;
  }
}
.ant-input-number-affix-wrapper,
.ant-input-number {
  transition: all 0.2s ease-in-out;
  &:focus,
  &:hover {
    border-color: $text-color !important;
  }
}
.ant-select-selector {
  outline: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}
.ant-select-focused,
.ant-input-number-focused {
  @extend .focus-override;
  & .ant-select-selector {
    border: none !important;
  }
}
.ant-input-status-error:focus {
  box-shadow: 0 0 0 1px $error-color !important;
}

// Overriding Timezone Select
.timezone-select {
  & > div {
    border-color: $input-border-color;
    border-radius: $border-radius-base;
  }
  &.focused {
    background-color: #f5f6f8;
  }
}
.ant-layout-header {
  background: inherit;
  padding-inline: inherit;
  height: auto;
  line-height: inherit;
}

.ant-input-data-count {
  bottom: -20px;
}

.radio-checkbox {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  & .radio-checkbox-item {
    width: 100%;
    text-align: center;
    border-radius: 4px !important;
    border-width: 1px;
    transition: all 0.25s ease-in-out;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    &::before,
    &::after {
      content: none !important;
    }
    &:hover,
    &:focus {
      color: $text-color;
      background-color: $input-number-handler-hover-bg;
      transition: all 0.25s ease-in-out;
    }
    &.ant-radio-button-wrapper-checked {
      outline: 1px solid $text-color;
      border: 1px solid $text-color;
      color: $text-color;
      background-color: $input-number-handler-hover-bg;
      transition: all 0.25s ease-in-out;
    }
    @media screen and (max-width: 768px) {
      width: auto;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.ant-picker {
  &:hover,
  &:focus {
    border-color: #141414;
    box-shadow: none;
  }
}

.ant-picker-focused {
  border-color: #141414;
  box-shadow: none;
}
