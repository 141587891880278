.category-page {
  & .category-hero {
    padding: 160px 0 200px;
    background-color: #faf7f2;
    @media screen and (max-width: $screen-sm-max) {
      padding: 110px 0 60px;
    }

    & .hero-img-section {
      position: relative;
      @media screen and (max-width: $screen-sm-max) {
        text-align: center;
      }
    }

    & .hero-img {
      position: relative;
      z-index: 1;
      @media screen and (max-width: $screen-sm-max) {
        width: 235px !important;
        height: 235px !important;
      }
    }

    & .hero-img-1 {
      position: absolute;
      right: -30px;
      top: -30px;
      @media screen and (max-width: $screen-sm-max) {
        width: 158px;
        height: 158px;
        right: 30px;
        top: -15px;
      }
    }

    & .hero-img-2 {
      position: absolute;
      left: -12px;
      bottom: -12px;
      @media screen and (max-width: $screen-sm-max) {
        width: 86px;
        height: 86px;
        left: 48px;
        bottom: -12px;
      }
    }

    & .hero-user-tag {
      border: 2px solid #000000;
      position: absolute;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      padding: 10px 20px 10px 12px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: #fff;
      right: -30px;
      z-index: 98;
      top: 80%;
      @media screen and (max-width: $screen-sm-max) {
        right: 30px;
        bottom: 20px;
        padding: 6px 10px;
        & .logo img {
          width: 20px;
        }
      }
    }

    & .hero-cta {
      margin-top: 64px;
      height: 64px;
      font-size: 20px;
      line-height: 20px;
      width: 60%;
      & .anticon {
        margin-left: 50px;
      }
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        height: 48px;
        margin-top: 72px;
        margin-bottom: 54px;
      }
    }
  }

  & .category-title {
    font-size: 54px;
    line-height: 70px;
    font-weight: 700;
    margin: 0;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 40px;
      line-height: 40px;
    }
  }

  & .category-subtitle {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-top: 32px !important;
    margin-bottom: 0;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 24px !important;
      font-size: 16px;
      line-height: 24px;
    }
  }

  & .category-why {
    padding: 120px 0 190px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 64px 0 72px;
    }
    & .cw-card {
      text-align: center;
      margin-top: 90px;
      padding: 0 40px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 50px;
        padding: 0 20px;
      }
      & .cw-card-img {
        margin-bottom: 50px;
        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 30px;
        }
      }
      & .cw-card-title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 16px;
        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 12px;
        }
      }
      & .cw-card-subtitle {
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
      }
    }
  }

  & .category-stats {
    background-color: #f7f9fd;
    padding: 40px 0 110px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 12px 12px;
    }
  }

  & .category-testimonial {
    background-color: #f7f9fd;
    padding: 100px 0 130px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 64px 0px;
    }
    & .ct-quote {
      font-size: 32px;
      line-height: 42px;
      font-weight: 500;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 80px;
        font-size: 20px;
        line-height: 26px;
      }
    }
    & .ct-name {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      margin-top: 56px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 24px;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
    & .ct-position {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-top: 5px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 4px;
        font-size: 16px;
        line-height: 20px;
      }
    }
    & .ct-subtitle {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      margin-top: 24px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 16px;
        font-size: 16px;
        line-height: 20px;
      }
    }
    & .ct-link {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      margin-top: 24px;
      border-bottom: 1px solid $text-color;
      display: inline-flex;
      cursor: pointer;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 24px;
        font-size: 18px;
        line-height: 22px;
      }
    }
    & .ct-img-section {
      position: relative;
      @media screen and (max-width: $screen-sm-max) {
        text-align: center;
      }
    }
    & .ct-img {
      z-index: 1;
      @media screen and (max-width: $screen-sm-max) {
        width: 240px !important;
        height: 240px !important;
      }
      // background-color: #c3c3c3;
    }
    & .ct-img-flower {
      position: absolute;
      top: 110px;
      right: -20px;
      @media screen and (max-width: $screen-sm-max) {
        top: 50px;
        right: 0;
      }
    }
  }

  & .category-benefits {
    padding: 80px 0 170px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 74px 20px;
    }
    & .cb-title {
      margin-bottom: 64px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 40px;
        line-height: 40px;
        padding: 0 20px;
        margin-bottom: 40px;
      }
    }
    & .cb-card-section {
      @media screen and (max-width: $screen-sm-max) {
        gap: 24px;
      }
    }
    & .cb-card {
      background: #ffffff;
      border: 1px solid #f0f0f0;
      border-radius: 24px;
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 440px;
      height: 100%;
      @media screen and (max-width: $screen-sm-max) {
        // margin-bottom: 24px !important;
        min-height: 360px;
      }
      & .cb-card-content {
        margin-top: 120px;
        padding: 24px 24px 36px;
        display: grid;
        height: 100%;
        @media screen and (max-width: $screen-sm-max) {
          padding: 20px 20px 32px;
        }
      }
      & .ct-card-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: auto !important;
        object-fit: contain;
      }
      & .cb-card-title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 0;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 20px;
          line-height: 26px;
        }
      }
      & .cb-card-subtitle {
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 0;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 12px;
        }
      }
      & .cb-card-cta {
        font-size: 18px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 0;
        display: inline-flex;
        align-self: flex-end;
        text-decoration: underline;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 18px;
          line-height: 32px;
          margin-top: 32px;
        }
      }
    }
  }
}
