.booking-tabs {
  & .ant-tabs-tab-btn {
    color: #141414 !important;
    font-size: 16px;
    font-weight: 500;
  }
  & .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #141414 !important;
    font-size: 16px;
    font-weight: 700;
  }
  & .ant-tabs .ant-tabs-tab :hover {
    color: #141414 !important;
  }

  & .ant-tabs-ink-bar-animated,
  .ant-tabs-ink-bar {
    background: #141414 !important;
  }
}
.calls-card-container {
  // @media screen and (min-width: $screen-sm-max) {
  //   width: 488px !important;
  //   max-width: 488px !important;
  // }
}
.calls-main {
  & .dates {
    margin-top: 40px;
    margin-bottom: 8px;
    padding-left: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 175%;
    @media screen and (max-width: $screen-sm-max) {
      padding-left: 22px;
      margin-top: 32px;
    }
  }

  & .card-expanded {
    border-radius: 12px 0px 0px 12px !important;
  }
  & .calls-card {
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    margin-bottom: 20px;
    & .calls-price-nonactive {
      color: #141414;
      font-size: 14px;
      font-weight: 700;
      border-radius: 16px;
      border: 1px solid #0000000f;
      background: #fafafa;
      padding: 6px 12px;
      display: flex;
      align-items: center;
    }
    & .calls-header-price {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      border-radius: 16px;
      border: 1px solid #8c5300;
      background: #8c5300;
      padding: 6px 12px;
      display: flex;
      align-items: center;
    }
    & .calls-price {
      margin-left: 4px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #666666;
      & svg {
        font-size: 14px;
      }
    }
    & .calls-header {
      display: flex;
      align-items: center;
      min-height: 60px;
      border-bottom: 1px solid rgba(231, 234, 239, 0.5);
      padding: 12px 20px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 12px;
      }
      & .package-tag {
        background: #fafafa;
        border: 1px solid #0000000f;
        border-radius: 20px;
        padding: 5px 12px;
        color: #141414;
        font-weight: 700;
        font-size: 14px;
        line-height: 175%;
        text-align: center;
        white-space: nowrap;
      }
      & .calls-time {
        display: flex;
        align-items: center;
      }
      & .calls-icon {
        margin-right: 10px;
        font-size: 16px;
        font-weight: 600;
      }
      & .didnot-show-tag {
        background: rgba(228, 67, 50, 0.1);
        border-radius: 28px;
        padding: 8px 12px;
        color: #880d00;
        font-weight: 700;
        font-size: 12px;
        line-height: 175%;
        text-align: center;
        white-space: nowrap;
      }
    }
    & .calls-details {
      padding: 16px 20px 14px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 12px 12px 32px 12px;
      }
      & .calls-name {
        font-size: 19px;
        font-weight: 700;
        line-height: 32px;
      }
      & .calls-service-desc {
        font-size: 13px;
        line-height: 24px;
        color: #666666;
      }
    }
    & .calls-tags {
      border-top: 1px solid rgba(231, 234, 239, 0.5);
      padding: 10px 20px;
      & .calls-tags-container {
        border-radius: 9px;
        background: #f0f8f6;
        padding: 4px 8px 8px 8px;
        & .ct-text {
          color: #006c51;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 19.5px */
          & b {
            font-weight: 800;
          }
        }
      }
    }
    & .calls-footer {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      padding: 16px 20px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 12px;
      }
    }
    & .doc-sales-tag {
      padding: 8px 12px;
      background-color: #f7f7f7;
      color: #006249;
      font-size: 14px;
      display: flex;
      gap: 4px;
    }
    & .package-calls-footer {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      padding: 16px 20px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 12px;
      }
    }
  }
}

.reschedule-modal {
  & .ant-checkbox-wrapper {
    align-items: flex-start;
  }
}

.send-email-container {
  & .heading {
    padding: 20px 24px !important;
    border-bottom: 1px solid #e3e8ed;
    margin-bottom: 32px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 16px;
    }
    & .email-heading {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 32px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 20px;
      }
    }
  }
  & .content-title {
    font-weight: 700;
    font-size: 16px;
  }
  & .cancel-btn {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    background-color: #df5546;
    border-radius: 3px;
    border: none !important;
    &:not(:disabled) {
      &:hover,
      &:focus {
        border: none !important;
        background-color: #df5546 !important;
        color: #fff !important;
      }
    }
  }
}

.ant-drawer-right > .ant-drawer-content-wrapper {
  transform: none !important;
}
.call-details-drawer {
  @media screen and (max-width: $screen-sm-max) {
    border-radius: 16px;
  }
  & .details-header {
    padding: 20px 24px 20px 32px !important;
    border-bottom: 1px solid #e3e8ed;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $screen-sm-max) {
      padding: 24px 16px 24px 12px !important;
    }
  }
  & .details-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 20px;
    }
  }
  & .drawer-close-icon {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 6px;
    color: $text-color;
    cursor: pointer;
  }
  & .booking-meet-note {
    border-radius: 12px;
    background: #f0f5fe;
    margin: 16px 32px 0px 32px;
    padding: 10px;
    @media screen and (max-width: $screen-sm-max) {
      margin: 16px 16px 0px 16px;
    }
    & .booking-mn-icon {
      border-radius: 50%;
      background: #d2e3fc;
      width: 57px;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @media screen and (max-width: $screen-sm-max) {
        width: unset;
        height: unset;
        padding: 10px;
      }
    }
    & .booking-mn-title {
      color: #141414;
      font-size: 16px;
      font-weight: 700;
      line-height: 130%; /* 20.8px */
    }
    & .booking-mn-subtitle {
      color: #141414;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
    }
    & .booking-mn-btn {
      border: none !important;
      border-radius: 18px;
      background: #d2e3fc !important;
      color: #0045b8 !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 130%; /* 18.2px */
      letter-spacing: 0.1px;
    }
    & .mn-premium-icon {
      border-radius: 12px;
      background: linear-gradient(180deg, #f8eaff 0%, #f0f5fe 100%);
      padding: 3px;
      position: absolute;
      right: 0px;
      bottom: -7px;
    }
  }
  & .booking-review {
    padding: 0px 32px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 0px 16px;
    }
    & .ask-review-desc {
      border-radius: 12px;
      border: 1px solid #ebeaeb;
      background: #fdfbf9;
      box-shadow:
        0.5px 0.5px 4px 0px rgba(0, 0, 0, 0.03),
        -0.5px -0.5px 4px 0px rgba(0, 0, 0, 0.03);
      padding: 12px 16px;
      color: #5b3600;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      width: 100%;

      & .review-count {
        text-align: center;
        border-right: 1px solid #e3e8ed;

        & .count-text {
          color: #5b3600;
          font-size: 20px;
          font-weight: 700;
          line-height: 150%;
          text-align: left;
        }
        & .review-text {
          color: #5b3600;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          text-align: left;
        }
      }
      & .all-ratings-btn {
        color: #5b3600;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  & .details-attendee-title {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    padding: 24px 0px 8px 32px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 24px 0px 8px 16px;
      font-size: 18px;
    }
  }
  & .service-name {
    font-size: 20px;
    font-weight: 500;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 18px;
    }
    & .service-name-main {
      font-weight: 700;
    }
  }
  & .webinar-title {
    font-size: 32px;
    font-weight: 700;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 24px;
    }
  }
  & .details-body {
    & .details-subhead {
      align-items: center;
      display: flex;
      padding: 12px 24px 12px 32px !important;
      justify-content: space-between;
      @media screen and (max-width: $screen-sm-max) {
        padding: 12px 16px 8px 16px !important;
      }
    }
    & .action-btns {
      padding: 24px 32px 20px 32px !important;
      @media screen and (max-width: $screen-sm-max) {
        padding: 16px 16px 24px 16px !important;
      }
    }

    & .doc-detail-col {
      font-size: 16px;
      font-weight: 500;
      border: 1px solid #e3e8ed;
      height: 56px;
      border-top: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 24px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
      }
      & .details-icon {
        padding-right: 12px;
      }
    }

    & .call-date {
      font-size: 16px;
      font-weight: 500;
      border: 1px solid #e3e8ed;
      padding: 24px 4px 16px 32px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 16px 4px 16px 16px;
        font-size: 14px;
      }
      & .details-icon {
        padding-right: 12px;
      }
    }
    & .call-phone-details {
      border-left: 0px;
      @media screen and (max-width: $screen-sm-max) {
        border-top: 0px;
      }
    }
    & .webinar-alert {
      border: 1px solid #ebeaeb;
      background: #fdfbf9;
      margin: 0px 32px;
      @media screen and (max-width: $screen-sm-max) {
        margin: 0px 16px;
      }
      & .webinar-record-alert {
        color: #5b3600;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
      }
    }
    & .email-btn {
      border: 1px solid #e3e8ed;
      border-left: 0px;
      padding: 4px 24px 5px 0px;
      justify-content: flex-end;
      text-align: end;
      display: flex;
      align-items: center;
    }
    & .border-top {
      @media screen and (max-width: $screen-sm-max) {
        border-top: 0px;
      }
    }
    & .call-qna {
      padding: 24px 24px 24px 32px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 16px 16px 24px 16px;
      }
      & .call-question {
        font-size: 14px;
        font-weight: 700;
        line-height: 18.2px;
      }
      & .call-answer {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 16px;
        word-break: break-word;
      }
    }
    & .webinar-reg-title {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 18px;
      }
    }
    & .download-icon {
      font-size: 16px;
      font-weight: 700;
    }
    & .btn-edit {
      background-color: #f2f2f2;
      border: none;
      box-shadow: none;
      padding: 0px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .issue-title {
      padding: 24px 20px 12px 32px;
      font-weight: 700;
      font-size: 14px;
      line-height: 130%;
      @media screen and (max-width: $screen-sm-max) {
        padding: 24px 20px 12px 16px;
      }
    }
    & .no-show {
      align-items: center;
      padding-left: 32px;
      @media screen and (max-width: $screen-sm-max) {
        padding-left: 16px;
      }
      & .divider {
        height: 2em;
      }
      & .no-show-tag {
        background: rgba(228, 67, 50, 0.1);
        border-radius: 28px;
        padding: 8px 12px;
        color: #880d00;
        font-weight: 700;
        font-size: 12px;
        line-height: 175%;
      }
    }
  }
  & .confirmation-body {
    padding: 24px 20px;
    & .confirmation-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      margin-bottom: 64px;
    }
  }
  & .call-details-extra-earnings {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.11);
    margin-bottom: 12px;
    position: relative;
    padding: 16px 20px;
    & .cd-extra-earnings-img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    & .cd-text {
      color: #141414;
      max-width: 70%;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: 0.25px;
    }
    & .earning-tags {
      color: #005a44;
      font-size: 12px;
      font-weight: 500;
      border-radius: 16px;
      background: #ebf5f3;
      padding: 4px 10px;
      z-index: 2;
    }
  }
  & .call-details-experience {
    background: #fdf1db;
    border-radius: 12px;
    padding: 12px 12px 12px 0;
    margin: 20px 20px 40px 32px;
    @media screen and (max-width: $screen-sm-max) {
      margin: 20px 16px 40px 16px;
    }
    & .cda-lottie {
      width: 100%;
    }
    & .cda-cta {
      align-self: flex-end;
      justify-self: flex-end;
      @media screen and (min-width: $screen-sm-max) {
        align-self: first baseline;
      }
    }
    & .cda-title {
      padding-top: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    & .cda-subtitle {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      margin-top: 4px;
    }
  }
  & .drawer-table {
    padding: 0px 32px !important;
    @media screen and (max-width: $screen-sm-max) {
      padding: 8px 16px !important;
    }
  }
}
.status-tag-text {
  font-size: 12px;
  font-weight: 700;
  padding: 6px 12px;
  line-height: 175%;
  text-transform: capitalize;
  @media screen and (max-width: $screen-sm-max) {
    padding: 6px 10px;
  }
}

.testimonial-container {
  position: relative;
  border-radius: 32px;
  border: 1px solid #ebeaeb;
  background: #fdfbf9;
  box-shadow:
    0.5px 0.5px 4px 0px rgba(0, 0, 0, 0.03),
    -0.5px -0.5px 4px 0px rgba(0, 0, 0, 0.03);
  padding: 24px 20px;
  width: 100%;
  @media screen and (max-width: $screen-sm-max) {
    padding: 24px 16ppx;
  }

  & .rating-text {
    font-size: 18px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  & .liked-properties {
    margin-bottom: 24px;
    gap: 12px !important;
    & .property-tag {
      border-radius: 24px;
      background: rgba(140, 83, 0, 0.1);
      padding: 10px 12px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;

      @media screen and (min-width: $screen-sm-max) {
        gap: 8px;
      }
    }
  }
  & .share-icon {
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    padding: 12px;
    position: absolute;
    top: 14px;
    right: 20px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 8px;
    }
  }
  & .testimonial-text {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 175%;
  }
}

.booking-card-analytics {
  border: 1px solid #e8e8e8;
  border-left: 0px;
  margin-bottom: 20px;
  background: #fafafa;
  height: auto;
  border-radius: 0px 12px 12px 0px;
}

.tags-sparkles {
  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }
}
