.home-faq {
  padding: 180px 0 240px;
  @media screen and (max-width: $screen-sm-max) {
    padding: 60px 0 100px;
  }
  & .header {
    font-size: 54px;
    line-height: 70px;
    font-weight: 800;
    margin-bottom: 20px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 32px;
      line-height: 44px;
      font-weight: 800;
    }
  }
  & .sub-header {
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  & .faq-collapse {
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 40px;
    }
    & .faq-item {
      border-bottom: 1px solid #e3e8ef;
      border-radius: 0;
      transition: all 0.2s linear;
      & .ant-collapse-expand-icon {
        transition: all 0.2s linear;
      }
      &.ant-collapse-item-active {
        transition: all 0.2s linear;
        & .ant-collapse-expand-icon {
          transition: all 0.2s linear;
          & svg,
          & img {
            filter: invert(45%) sepia(49%) saturate(3223%) hue-rotate(335deg)
              brightness(86%) contrast(110%);
            transform-origin: 0 7px;
            transform: rotate(-45deg);
            transition: all 0.2s linear;
          }
        }
      }
      & .ant-collapse-header {
        align-items: center;
        padding: 22px 16px;
      }
      & .ant-collapse-header-text {
        font-size: 20px;
        line-height: 34px;
        font-weight: 600;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
    & .faq-answer {
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}
