.create-post-card {
  &.dashed {
    border: 1px dashed $text-color;
  }
  border-radius: 6px;
  height: 80px;
  cursor: pointer;
  border: 1px solid $text-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 20px !important;
  }
  & .share-icon-new {
    font-size: 18px;
    margin-bottom: 20px;
    margin-right: 9px;
    margin-top: 18px;
    color: $text-color;
  }
  & .share-title-new {
    font-size: 15px;
    line-height: 26px;
    font-weight: 600;
    color: $text-color;
    letter-spacing: 0.15px;
  }
}
.ai-post-card {
  @media screen and (min-width: $screen-md-min) {
    margin-left: 8px !important;
  }
}
.share-divider {
  height: 80px !important;
  margin: 0 12px !important;
}

.post-settings {
  & .setting-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .settings-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #f4f4f4;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      cursor: pointer;
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 32px;
  }
}

.settings-drawer {
  & .settings-header {
    padding: 24px 100px 24px 32px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 20px 16px 24px 16px;
    }
    border-bottom: 1px solid #f4f4f4;
  }
  & .setting-text {
    margin-top: 16px;
    font-weight: 500;
    font-size: 14px;
  }
  & .settings-body {
    padding: 24px 32px 24px 32px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 24px 16px 24px 16px;
    }
    & .setting-tab {
      font-weight: 600;
      font-size: 14px;
      line-height: 175%;
    }
  }
  & .settings-title {
    font-size: 24px;
    line-height: 42px;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  & .checkbox-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
  & .drawer-close-icon {
    position: absolute;
    right: 32px;
    top: 24px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 6px;
    color: $text-color;
    cursor: pointer;
    @media screen and (max-width: $screen-sm-max) {
      right: 20px;
    }
  }
}

.share-past-posts {
  & .past-posts-header {
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    margin-top: 40px;
  }
  & .past-posts {
    border: 1px solid #dedddc;
    border-radius: 6px;
    padding: 17px 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    & .past-post-header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      & .past-post-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        margin-left: 20px;
        margin-top: 10px;
        text-transform: capitalize;
      }
    }
    & .past-post-description {
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.container-children:has(.create-post) {
  padding: 0 !important;
  display: flex;
  align-items: stretch;
  min-height: 90vh;
}

.create-post {
  height: 100%;
  @media screen and (max-width: $screen-sm-max) {
    padding-bottom: 130px;
  }
  & .main-section {
    border-right: 1px solid #e4e8ec;
    padding: 30px 0 100px;
    @media screen and (max-width: $screen-sm-max) {
      border-right: none;
      padding: 30px 0 0;
    }
    .share-mobile-footer {
      & .booking-mobile-cta {
        margin-top: 40px;
        font-weight: 700;
      }
      @media screen and (max-width: $screen-sm-max) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 18px;
        background-color: #fff;
        z-index: 9;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
      }
    }
  }
  & .create-post-tabs {
    & .ant-tabs-nav {
      margin-bottom: 0;
      &::before {
        border-bottom: 0;
      }
    }
    & .ant-tabs-ink-bar {
      background: #141414;
    }
    & .ant-tabs-tab {
      color: #989898;
      font-size: 16px;
      line-height: 150%;
      transition: 0.25s all ease-in;
      &.ant-tabs-tab-active {
        & .ant-tabs-tab-btn {
          transition: 0.25s all ease-in;
          color: #141414 !important;
          font-weight: 700;
        }
      }
    }
  }
  & .sidebar-section {
    padding: 30px 0 100px;
  }
  .ant-carousel .slick-slide {
    overflow: hidden;
    height: 160px;
    background: #fff;
  }

  .ant-carousel .slick-slide div {
    color: #141414;
  }
  .ant-carousel .slick-dots li button {
    background: #141414;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #141414;
  }
  & .create-post-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .ink-ai-btn {
      font-size: 14px;
      font-weight: 700;
      margin-left: 12px;
      border: 1px solid #000000;
      border-radius: 3px;
      padding: 0px 16px;
      display: flex;
      align-items: center;
    }
  }
  & .editor-wrapper {
    border: 1px solid #e4e8ec;
    border-radius: 3px;
    padding: 18px;
    max-height: 500px;
    min-height: 240px;
    overflow-y: auto;
    & .ql-editor {
      padding: 0;
      min-height: 84px;
      line-height: 1.25;
      height: 100%;
      outline: 0;
      overflow-y: auto;
      tab-size: 4;
      text-align: left;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    & .ql-container {
      border: none;
    }
    @media screen and (max-width: $screen-sm-max) {
      max-height: 360px;
    }
    & .loading-text {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
    }
    .js-user-slider {
      transition:
        width 0.2s,
        height 0.2s;
      display: inline-flex;
      margin: 0 auto;
      align-items: center;
      vertical-align: bottom;
      text-align: center;
      white-space: nowrap;
      color: #141414;
    }
    .js-user-slider span {
      display: none;
      opacity: 0;
    }
    .js-user-slider span:first-of-type {
      display: block;
      opacity: 1;
    }
    .js-user-slide-init {
      transform: translate3d(0, -100%, 0);
    }
    .js-user-slider.js-loaded span {
      display: block;
      position: absolute;
      top: 100%;
      left: auto;
      right: auto;
    }
    .js-user-slide-in {
      animation: slideIn 1s forwards;
    }
    .js-user-slide-out {
      animation: slideOut 1s forwards;
    }

    @keyframes slideIn {
      0% {
        opacity: 0;
        transform: translate3d(0, -150%, 0);
      }

      100% {
        opacity: 1;
        transform: translate3d(0, -100%, 0);
      }
    }
    @keyframes slideOut {
      0% {
        opacity: 1;
        transform: translate3d(0, -100%, 0);
      }

      30% {
        opacity: 0;
      }

      100% {
        opacity: 0;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
  & .create-post-img-wrapper {
    width: 100%;
    margin: 10px 0;
    position: relative;
    & img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    & .image-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.72);
    }
  }
  & .linkedin-share-btn {
    background: #2d64bc;
    border-radius: 3px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $screen-sm-max) {
      height: 48px;
    }
    &:hover,
    &:focus {
      background-color: darken(#2d64bc, 5) !important;
    }
  }
  & .twitter-share-btn {
    background: #068ce51a;
    border-radius: 3px;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    color: #068ce5 !important;
    @media screen and (max-width: $screen-sm-max) {
      width: 48px;
      height: 48px;
    }
  }
  & .instagram-share-btn {
    border: 1px solid #000000 !important;
    border-radius: 3px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $screen-sm-max) {
      height: 48px;
    }
    & .instagram-icon {
      width: 24px;
      height: 24px !important;
    }
  }
  & .copy-download {
    width: 48px !important;
    @media screen and (max-width: $screen-sm-max) {
      width: 40px !important;
    }
  }
  & .create-post-btn {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 8px;
      width: 100%;
      &.image {
        width: 100%;
      }
    }

    @media screen and (min-width: $screen-sm-max) {
      &.submit {
        // height: 48px;
      }
    }
  }
}

.featured-side-message {
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 30px !important;
  }
}

.share-data-tag {
  display: flex !important;
  background-color: #fcebdf;
  border-radius: 6px;
  padding: 8px 16px;
  margin-top: 60px;

  & .data {
    font-weight: 900;
    font-size: 24px;
  }
  & .desc-text {
    font-weight: 500;
    font-size: 16px;
  }
}

.linkedin-analytics {
  margin-top: 50px;
  & .analytics-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e3e8ed;
  }
  & .analytics-banner {
    margin-top: 24px;
    width: 100%;
    background: #fdeddc;
    padding: 48px 0 0 48px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 20px 16px 0;
    }
    & .banner-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    & .banner-image {
      width: 100%;
      margin-top: auto;
    }
  }
  & .banner-cta {
    margin: 60px 0 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    height: 48px;
    padding: 0 20px;
    &.inprogress {
      margin-top: 24px;
    }
    @media screen and (max-width: $screen-sm-max) {
      margin: 35px 0 40px;
    }
  }
  & .banner-inprogress {
    margin-top: 30px;
    & .inprogress-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 42px;
      margin-top: 32px;
    }
  }
  & .analytics-table {
    margin-top: 24px;
    & .ant-table {
      border: 1px solid #e7e7e9;
      border-bottom: 0;
      border-radius: 9px;
      overflow: hidden;
    }
  }
  & .active-tag,
  & .inactive-tag {
    font-size: 12px;
    line-height: 21px;
    border-radius: 20px;
    display: inline-flex;
    padding: 2px 10px;
  }
  & .active-tag {
    background-color: rgba(0, 128, 96, 0.12);
    color: $success-color;
  }
  & .inactive-tag {
    background-color: rgba(242, 188, 54, 0.13);
    color: #493f32;
  }
}

.templates-drawer {
  & .templates-header {
    padding: 32px 100px 32px 32px;
    border: 1px solid #e3e8ed;
    @media screen and (max-width: $screen-sm-max) {
      padding: 24px;
    }
  }
  & .tempaltes-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  & .drawer-close-icon {
    position: absolute;
    right: 32px;
    top: 32px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 6px;
    color: $text-color;
    cursor: pointer;
  }
  & .template-body {
    padding: 20px 24px;
  }
}

.share-cards {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dedddc;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 24px !important;
  padding: 17px 24px;
  & .share-card-tag {
    background-color: #fcf0c6;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    padding: 2px 8px;
    height: 25px;
    margin-top: 5px;
  }
  & .share-card-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
  }
}

.analytics-steps {
  margin-top: 60px;
  & .steps-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 16px;
  }
  & .steps-list {
    margin-top: 20px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .share-cards {
    padding: 17px 10px;
  }
  .share-card-title {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
.modal-post-guide {
  display: block;
  height: 400px;
  @media screen and (max-width: $screen-sm-max) {
    display: none !important;
  }

  & .ant-modal-content {
    border-radius: 16px;
    padding: 0;
    overflow: hidden;
  }

  & .modal-info {
    display: flex;
  }
  & .ant-image-img {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    max-height: 450px;
    max-width: 420px;
    width: auto;
    height: auto;
    vertical-align: top;
  }
  & .ant-modal-body {
    padding: 0px !important;
  }
}
.drawer-visible {
  display: none;
  @media screen and (max-width: $screen-sm-max) {
    display: block !important;
  }
  & .ant-drawer-header-close-only {
    position: absolute;
    right: 0px;
    border-bottom: none;
    padding: 14px 0px;
  }

  & .ant-drawer-body {
    overflow: hidden;
    padding: 0px;
  }
  &.ant-drawer-content {
    border-radius: 16px;
    @media screen and (max-width: $screen-sm-max) {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  & .image-conatiner {
    width: 100%;
    max-height: 40vh;
    overflow: hidden;
    & .ant-image {
      width: 100%;
      vertical-align: initial;
    }
    & .ant-image-img {
      vertical-align: bottom !important;
    }
  }
  & .info-container {
    background: white;
  }
}

.guide-info-box {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & .popup-number {
    position: fixed;
    padding: 20px 0px 0px 40px;
  }
  & .info-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  & .upper-content {
    padding: 32px 20px 0px 20px;
    @media screen and (min-width: $screen-md-min) {
      padding: 48px 40px 0px 40px;
    }
    & .guide-heading {
      display: flex;
      justify-content: space-between;
      & .popup-number-mobile {
        padding-top: 5px;
      }
    }
  }
  & .title {
    font-size: 32px;
    font-weight: 700;
    display: block;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 24px !important;
    }
  }
  & .desc {
    color: #141414b5;
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
  }
  & .link-input {
    border: 1px dashed black;
    display: flex !important;
    margin-top: 48px;

    & .copy-btn {
      background-color: #f4f4f4;
      border-radius: 3px;
      border: none;
      margin: 3px;
    }
  }
  & .btn-group {
    padding: 0px 24px 24px 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & .btn-dark {
      background-color: black !important;
    }
  }
}
.ai-post {
  padding-bottom: 40px;
  & .ai-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
  & .prompt-container {
    display: block;
    & .prompt-input {
      margin-top: 16px;
      min-height: 140px;
    }
    & .prompt-head {
      display: flex;
      justify-content: space-between;
      & .example-btn {
        @media screen and (min-width: $screen-md-min) {
          display: none;
        }
      }
    }
  }
  & .content-container {
    display: block;
    margin-top: 16px;
    & .content-head {
      display: flex;
      justify-content: space-between;
      & .submit-btn {
        background-color: #000;
        border-radius: 3px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
      }
    }
    & .content-action-btn {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      & .share-btn {
        margin-left: 12px;
      }
    }

    & .content-input {
      margin-top: 12px;
      min-height: 320px;
      @media screen and (max-width: $screen-sm-max) {
        min-height: 270px !important;
      }
    }
  }
  & .action-btn {
    background-color: #ececf1;
    border: none;
    font-size: 14px;
    font-weight: 600;
  }
  & .ant-card-body {
    padding: 18px;
  }
  & .ant-drawer-body {
    padding: 18px !important;
  }
  & .examples-head {
    display: flex;
    justify-content: space-between;
    & .view-guide-btn {
      margin-bottom: 4px;
    }
  }
}
.guide-drawer {
  & .ai-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
  & .ant-drawer-close {
    position: fixed;
    right: 0;
  }
  & .ant-drawer-content {
    border-radius: 16px;
  }
  & .examples-head {
    display: flex;
    justify-content: space-between;
    & .view-guide-btn {
      margin-right: 20px;
    }
    .ant-btn {
      float: right !important;
      margin-right: 20px !important;
    }
  }
  & .ant-drawer-header {
    border-bottom: none;
  }
}
.example-card {
  background-color: #f9f5f1 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px !important;
  min-height: 247px;
  padding: 12px;
  & .example-actions {
    text-align: right;
    & .refresh-btn {
      background-color: #f9f5f1;
      border: 1px solid;
      font-size: 14px;
      font-weight: 600;
      margin-right: 9px;
    }
    & .prompt-btn {
      background-color: #ef5337;
      border: none;
      color: #ffffff;
      font-weight: 700;
    }
  }
}
.publish-drawer {
  @media screen and (max-width: $screen-sm-max) {
    border-radius: 16px;
  }
  & .publish-header {
    padding: 20px 24px !important;
    border-bottom: 1px solid #e3e8ed;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $screen-sm-max) {
      padding: 16px;
    }
  }
  & .publish-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 20px;
    }
  }
  & .drawer-close-icon {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 6px;
    color: $text-color;
    cursor: pointer;
  }
  & .publish-body {
    padding: 24px;
    & .suggestion-text {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      padding: 24px 0px;
    }
    & .ai-post-box {
      margin-left: 8px !important;
    }
  }
}
.templates-container {
  & .milestone-btn {
    margin-left: 12px;
  }
  & .tab-btn {
    padding: 2px 20px !important;
    border-radius: 18px !important;
    border: 1px solid #222222 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  & .tab-btn-selected {
    padding: 2px 20px !important;
    background-color: #f7f7f7 !important;
    border: 2px solid #222222 !important;
    border-radius: 18px !important;
    font-weight: 700 !important;
    font-size: 14px !important;
  }
  & .no-templates {
    padding-top: 24px;
  }
}

.privacy-tag {
  padding-bottom: 24px;
  width: 100%;
  background: #fff;
  @media screen and (min-width: $screen-sm-max) {
    z-index: 99;
    bottom: 0;
    position: fixed;
  }
  & .plus-text {
    padding: 0px 8px;
    font-size: 40px;
  }
  & .privacy-text {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15px;
    margin-left: 24px;
  }
}

.linkedin-auto-modal {
  & .ant-modal-content {
    padding: 0px 0px 24px 0px;
  }
  & .top-nav {
    background: #eee9fc;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    height: 100px;
    padding: 0px 24px;
    & .plus-text {
      padding: 0px 24px;
      font-size: 40px;
    }
  }
  & .content {
    padding: 20px 24px 0px 24px;
    & .heading {
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
    }
    & .desc {
      color: #686b60;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    & .submit-btn {
      margin-top: 56px;
    }
  }
}

.post-success-modal {
  & .ant-modal-content {
    padding: 0px;
  }
  & .post-content {
    padding: 40px 30px 24px 30px;
    text-align: center;
  }
  & .bottom-btns {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 16px 16px;
    background: #f8f7f2;
    padding: 24px 20px;
  }
  & .link-desc {
    background: #f8f7f2;
    padding-bottom: 16px;
  }
  & .link-bio-image {
    width: 100%;
    height: 181px;
  }
  & .link-bio-text {
    color: #141414;
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
    padding-left: 24px;
  }
}
