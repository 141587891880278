:root {
  --backgroundColor: #fff;
  --colorPrimary: #141414;
  --colorSuccess: #008060;
  --colorError: #cd432d;
  --colorSecondary: #e44a32;
  --colorInfo: #008060;
  --colorTextBase: #141414;
  --font-base: "Gilroy";
  --borderRadiusBase: 6px;
  --inputBorderColor: #b0b0b0;
}
