.pricing {
  & .pricing-signup {
    width: 405px;
    height: 64px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $screen-xs-max) {
      width: 320px;
    }
  }
  & .exploring {
    background-color: #fdf2ef;
    padding-top: 220px;
    padding-bottom: 100px;
    justify-content: center;
    text-align: center;
    color: #141414;
    @media screen and (max-width: $screen-xs-max) {
      padding-top: 170px;
    }
    & .go-to-price {
      margin-top: 64px;
      cursor: pointer;
    }
    & .exploring-text {
      color: #141414 !important;
      font-weight: 500;
      font-size: 90px;
      line-height: 90%;
      width: 930px;
      margin: 0 auto;
      margin-bottom: 32px;
      @media screen and (max-width: $screen-xs-max) {
        width: 95%;
        font-size: 52px;
        line-height: 100%;
      }
      & b {
        font-weight: 800;
      }
    }
    & .exploring-desc {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      width: 611px;
      margin: 0 auto;
      @media screen and (max-width: $screen-xs-max) {
        width: 95%;
        font-size: 20px;
      }
    }
    & .see-pricing {
      color: #141414;
      font-weight: 700;
      font-size: 16px;
      line-height: 110%;
    }
  }
  & .utility {
    background: linear-gradient(0deg, #1c2d1e 30%, #d6ebc6 30%);
    padding: 108px 0px;
    @media screen and (max-width: $screen-xs-max) {
      background: #d6ebc6;
      padding: 64px 0px;
    }
    & .heading-utility {
      font-weight: 700;
      font-size: 40px;
      color: #1b223c;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 24px;
      }
    }
    & .sub-heading-utility {
      color: #3c3c3c;
      font-size: 24px;
      font-weight: 300;
      margin-top: 7px;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 13px;
      }
    }
  }
  & .utility-list-item {
    margin-bottom: 12px;
    color: #1b223c;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    @media screen and (max-width: $screen-xs-max) {
      font-size: 13px;
      line-height: 22px; /* 169.231% */
      margin-bottom: 6px;
    }
  }
  & .creator {
    background-color: #e2eed9;
    text-align: center;
    padding: 108px 0px;
    @media screen and (max-width: $screen-xs-max) {
      padding: 64px 0px;
    }
    & .heading-creator {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #008060;
    }
  }
  & .creator-price {
    font-weight: 300;
    font-size: 24px;
    color: #141414;
    padding-top: 24px;
    @media screen and (max-width: $screen-xs-max) {
      padding-top: 13px;
      font-size: 14px;
    }
    & b {
      font-size: 60px;
      font-weight: 300;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 34px;
      }
    }
  }
  & .basic-com-btn {
    border-radius: 12px;
    border: 1px solid #1b223c;
    background: #f9f3f3;
    margin-top: 24px;
    color: #1b223c;
    font-size: 24px;
    font-weight: 700;
    height: unset;
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 14px;
      font-size: 14px;
    }
    &:hover,
    &:active,
    &:focus {
      background: #f9f3f3 !important;
      color: #1b223c !important;
      border: 1px solid #1b223c !important;
    }
  }
  & .premium-com-btn {
    border-radius: 12px;
    border: none !important;
    background: #b743ef;
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    height: unset;
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 14px;
      font-size: 14px;
    }
    &:hover,
    &:active,
    &:focus {
      background: #b743ef !important;
      color: #fff !important;
    }
  }
  & .creator-subtext {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-left: 10px;
  }
  & .premium-pricing {
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 32px;
    }
  }
  & .price-side-image {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 350px;
    @media screen and (max-width: $screen-xs-max) {
      width: 200px;
    }
  }
  & .desc-utility {
    color: #797878;
    font-size: 16px;
    font-weight: 300;
    margin: 0 auto;
    padding-top: 16px;
    text-align: center;
    @media screen and (max-width: $screen-xs-max) {
      padding-top: 10px;
      font-size: 9px;
    }
  }

  & .comparison {
    padding: 108px 0px;
    @media screen and (max-width: $screen-xs-max) {
      padding-top: 64px;
      padding-bottom: 0px;
    }
    & .heading {
      font-weight: 700;
      font-size: 16px;
      line-height: 110%;
      color: #e44a32;
      margin-bottom: 24px;
      @media screen and (min-width: $screen-xs-max) {
        width: 513px;
      }
    }
    & .text {
      font-weight: 700;
      font-size: 32px;
      line-height: 110%;
      @media screen and (min-width: $screen-xs-max) {
        font-size: 54px;
        width: 513px;
      }
    }
  }
  & .aim {
    padding: 108px 0px;
    @media screen and (max-width: $screen-xs-max) {
      padding-top: 0px;
      padding-bottom: 40px;
    }
    & .desc-aim {
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      margin: 0 auto;
      padding-top: 40px;
      width: 467px;
      @media screen and (max-width: $screen-xs-max) {
        padding-top: 30px;
        width: unset;
      }
    }
  }
  & .comparison-table {
    margin-bottom: 80px;

    .premiumTierContainer {
      margin: 0 auto;
      width: fit-content;
      padding: 10px 10px 10px 8px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      border-radius: 12px;
      background: linear-gradient(180deg, #f8eaff 0%, #fff 100%);
    }
    .table-headings {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 110%;
      margin-bottom: 30px;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 16px;
      }
    }
    .number {
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      color: #e44332;
    }
    .values {
      font-weight: 500;
      font-size: 20px;
      line-height: 110%;
      text-align: center;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 14px;
      }
    }
    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 110%;
      text-align: left;
      margin-left: 30px;
      @media screen and (max-width: $screen-xs-max) {
        margin-left: 0px;
        font-size: 16px;
      }
    }
  }
  & .reviews-slider {
    margin-top: 200px;
    & .title {
      font-weight: 900;
      font-size: 66px;
      line-height: 85px;
      text-align: center;
      max-width: 70%;
      margin: 0 auto;
      margin-bottom: 130px;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 48px;
        line-height: 58px;
      }
    }
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 100px;
      & .title {
        max-width: 100%;
        margin-bottom: 60px;
      }
    }
    & .review-card {
      background-color: #f0fbf4;
      padding: 40px;
      height: 380px;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: $screen-xs-max) {
        padding: 24px;
        height: 340px;
      }
      & .info {
        align-items: center;
      }
      & .text {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        @media screen and (max-width: $screen-xs-max) {
          font-size: 16px;
        }
      }
      & .ss-footer-dp {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        @media screen and (max-width: $screen-xs-max) {
          width: 40px;
          height: 40px;
        }
      }
      & .person-info {
        padding-left: 12px;
        & .name {
          font-weight: 700;
          font-size: 16px;
          line-height: 140%;
        }
        & .company {
          color: #6c6c87;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
        }
      }
    }
  }
  & .pricing-features {
    padding: 90px 0px 120px;
    background: #1c2d1e;
    & .title {
      color: #fff;
      text-align: center;
      font-size: 64px;
      font-weight: 900;
      line-height: 82px;
      letter-spacing: -1.92px;
      margin-bottom: 48px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 48px;
        font-weight: 900;
        line-height: 58px;
      }
    }
  }
  & .pricing-services {
    padding: 130px 0px 230px;
    background: #f7f7f7;
    @media screen and (max-width: $screen-sm-max) {
      padding: 80px 0px 130px;
    }
    & .title {
      text-align: center;
      font-size: 64px;
      font-weight: 900;
      line-height: 82px;
      letter-spacing: -1.92px;
      margin-bottom: 70px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 48px;
        font-weight: 900;
        line-height: 58px;
        margin-bottom: 20px;
      }
    }
    & .ps-block {
      text-align: center;
      margin-top: 20px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 70px;
        & img {
          max-width: 160px;
        }
      }
      & .ps-title {
        margin-top: 48px;
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        @media screen and (max-width: $screen-sm-max) {
          margin-top: 24px;
        }
      }
      & .ps-subtitle {
        color: rgba(20, 20, 20, 0.58);
        margin-top: 12px;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }
  & .img-services {
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 20px;
    }
  }
}
