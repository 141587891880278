.beacons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  height: 100vh;

  @media screen and (max-width: $screen-sm-max) {
    margin-top: 0;
  }
  .ant-col {
    height: 100%;
  }
}

.beacons {
  align-content: center;
  max-width: 560px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  height: 100%;

  & > .ant-card-body {
    padding: 0 70px 20px;
    position: relative;

    @media screen and (max-width: $screen-sm-max) {
      padding: 0 10px 20px;
    }
  }

  & > .ant-card-head {
    padding: 0;
  }

  & .ant-card-head-title {
    padding: 0;
  }

  & .beacons-title {
    font-size: 26px;
    font-weight: 700;
    color: #e44332;
    background-color: #f9e8d7;
    text-align: center;
    height: 80px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .service-card {
    height: 159px;
    width: 203px;
    min-height: 159px;
    border-radius: 9px;
    border: 1px solid #e2e2e2;
    @media screen and (max-width: $screen-sm-max) {
      width: auto;
    }
    & > .ant-card-body {
      @media screen and (max-width: $screen-sm-max) {
        padding: 16px 12px;
      }
    }

    & .slider-card-title {
      font-weight: 700;
      font-size: 18px;
      color: #141414;
    }
    & .price {
      position: absolute;
      bottom: 16px;
      align-self: baseline;
      color: #141414;
      font-weight: 700;
      font-size: 14px;
      display: inline;
    }
  }
  & .arrow {
    display: inline;
    position: absolute;
    bottom: 16px;
    right: 16px;
    border: 1px solid;
    padding: 2px;
    border-radius: 12px;
    background-color: #f6f4ef;
  }
  & .arrow-cta {
    position: absolute;
    top: 90px;
    width: 32px;
    height: 32px;
    background: #fafafa;
    border: 1px solid #e4e6ea;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
  }
  & .logo {
    margin-top: 24px;
    text-align: center;
  }
}
