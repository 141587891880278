.public-homepage {
  height: 300vh;
  & .home-mentor-lottie {
    @media screen and (max-width: $screen-sm-max) {
      width: 280px;
    }
  }
  & .text-highlight {
    font-weight: 600;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 1%,
      #febdb6 50%,
      #febdb6 75%,
      #febdb6 75%,
      #febdb6 100%
    );
  }
  & .ss-footer {
    margin: 0 28px 24px;
    display: flex;
    align-items: center;
    & .ss-footer-name {
      margin-left: 14px;
      & .ss-topmate-user {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
      & .ss-topmate-link {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #434343;
        word-break: break-word;
      }
    }
    & .ss-footer-dp {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }
  & .homepage-main-container {
    padding-top: calc($navbar-height + 70px);
    position: relative;
    padding-bottom: 80px;
    @media screen and (max-width: $screen-sm-max) {
      padding-bottom: 60px;
      padding-top: $navbar-height-mobile;
    }
  }
  & .homepage-main-back {
    background-repeat: no-repeat;
    background-position: center bottom;
    // background-image: url("/images/homepage/home-chritsmas-bg.svg");
    background-color: #f8f7f2;
    position: relative;
    & .campaign-btn {
      background: rgba(217, 197, 168, 0.2);
      border: 1px solid rgba(57, 57, 57, 0.33);
      border-radius: 24px;
      cursor: pointer;
      padding: 8px 8px;
      margin-top: 100px;
      @media screen and (min-width: $screen-sm-max) {
        margin-top: 12px;
        width: 390px;
      }
      & .title {
        font-weight: 700;
        font-size: 18px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 16px;
        }
      }
      & .next-icon {
        font-size: 24px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 20px;
        }
      }
    }

    & .landing-page-header {
      & b {
        font-weight: 900;
      }
      font-size: 86px;
      line-height: 1;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 0;
      & .title-img {
        border: 2px solid $secondary-color;
        padding: 3px;
        border-radius: 50%;
        margin-left: 8px;
        object-fit: contain;
        width: 72px;
        height: 72px;
        @media screen and (max-width: $screen-sm-max) {
          width: 46px;
          height: 46px;
        }
      }
      @media screen and (max-width: $screen-sm-max) {
        font-size: 48px;
        line-height: 48px;
      }
    }

    & .landing-page-subheader {
      margin-top: 25px !important;
      font-size: 22px;
      line-height: 30px;
      font-weight: 500;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 26px !important;
      }
    }

    & .swiper-slide-shadow {
      background-color: transparent;
    }

    & .landing-hero-img-bg {
      max-width: 100%;
      height: auto;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 40px;
      }
    }
    & .mental-health-img {
      width: 390px;
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
        margin-top: 40px;
      }
    }
    & .landing-hero-img {
      min-height: 360px;
      max-height: 360px;
      width: auto;
      position: absolute;
      margin: 0 auto;
      z-index: 1;
      left: 0;
      right: 0;
      bottom: 122px;
      @media screen and (max-width: $screen-sm-max) {
        position: relative;
        width: 100%;
        min-height: auto;
        height: auto;
        bottom: 0;
        margin-top: 40px;
      }
    }

    & .home-watch-video {
      position: absolute;
      bottom: 30px;
      left: 86px;
      border-radius: 8px;
      cursor: pointer;
      box-shadow: 4px 4px 0px #000000;
      transition: all 0.4s linear;
      &:hover {
        box-shadow: none;
      }
      @media screen and (max-width: $screen-sm-max) {
        top: 24px;
        left: 0;
      }
    }

    & .landing-page-cc {
      font-size: 16px;
      line-height: 20px;
      margin-top: 16px;
      font-weight: 500;
    }

    & .landing-avatar {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 30px;
      @media screen and (max-width: $screen-sm-max) {
        flex-wrap: wrap;
        gap: 14px;
        margin-top: 16px;
      }
    }
    & .star-ratings {
      @media screen and (max-width: $screen-sm-max) {
        width: 18px;
        height: 18px;
      }
    }
    & .home-video {
      justify-content: center;
      align-items: center;
    }
    & .hero-img-mobile-slider {
      display: none;
      @media screen and (max-width: $screen-sm-max) {
        display: block;
      }
    }

    & .home-btns {
      margin-top: 54px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 30px;
      }
      & .landing-cta {
        & > button,
        & > a {
          width: 50% !important;
          border-width: 2px;
          @media screen and (max-width: $screen-sm-max) {
            width: 100% !important;
          }
        }
        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 10px;
          flex-wrap: wrap;
        }
        & .ant-btn {
          height: 60px;
          border-radius: 6px;
          box-shadow: none;
          font-size: 20px;
          line-height: 20px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: $screen-sm-max) {
            height: 48px;
            font-size: 16px;
          }
          & .anticon {
            margin-left: 25px;
          }
        }
      }
    }
    & .watch-video-cta {
      height: 60px;
      background: #ffffff;
      border: 2px solid #000000;
      border-radius: 6px;
      font-weight: 700;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: #fff !important;
        box-shadow: 4px 4px 0px #000000;
        transition: 0.25s all ease-in-out;
      }
      @media screen and (max-width: $screen-sm-max) {
        height: 48px;
        font-size: 16px;
      }
    }
    & .ant-rate {
      margin: 0 0 2px 0;
      & .ant-rate-star {
        margin-inline: 0;
        padding-inline: 0;
        margin-inline-end: 14px;
      }
    }
  }
  & .public-navbar {
    margin: 0;
    & .logo {
      display: inline-block;
    }
  }
  & .home-discovery {
    padding-top: 90px;
    background-color: #eccc7b;
    position: relative;
    @media screen and (max-width: $screen-sm-max) {
      padding-top: 70px;
    }
    & .hd-title {
      & b {
        font-weight: 900;
      }
      text-align: center;
      font-size: 72px;
      line-height: 84px;
      font-weight: 500;
      position: relative;
      margin-bottom: 0;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    & .hd-sub-heading {
      text-align: center;
      color: #434343;
      font-size: 19px;
      line-height: 34px;
      margin-top: 12px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    & .home-discover-top {
      position: absolute;
      top: -19px;
      right: -60px;
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }
    & .hd-tabs {
      margin-top: 48px;
      & .ant-tabs-nav-wrap {
        @media screen and (max-width: $screen-sm-max) {
          justify-content: flex-start;
        }
      }
      & .swiper,
      & .ant-tabs-nav-list {
        @media screen and (max-width: $screen-sm-max) {
          padding: 0 12px;
        }
      }
      & .ant-tabs-nav {
        &::before {
          border-bottom: 0;
        }
      }
      & .ant-tabs-ink-bar {
        display: none;
      }
      & .ant-tabs-tab {
        padding: 12px 40px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 40px;
        transition: all 0.2s linear;
        font-size: 18px;
        line-height: 32px;
        &:hover {
          background-color: #141414;
          transition: all 0.2s linear;
          & .ant-tabs-tab-btn {
            color: #fff;
          }
        }
        @media screen and (max-width: $screen-sm-max) {
          padding: 12px 16px;
        }
        & .ant-tabs-tab-btn {
          color: $text-color;
        }
        &.ant-tabs-tab-active {
          color: #fff;
          background-color: $text-color;
          transition: all 0.4s linear;
          & .ant-tabs-tab-btn {
            color: #fff;
          }
        }
      }
      & .ant-tabs-nav {
        margin-bottom: 24px;
      }
      & .ant-tabs-content-holder {
        // background: #f7f9fd;
        padding: 14px 0 40px;
        @media screen and (max-width: $screen-sm-max) {
          padding: 0 0 40px;
        }
      }
      & .ant-tabs-nav-operations {
        display: none;
      }
      & .hd-swiper {
        padding: 10px 0 120px;
        @media screen and (max-width: $screen-sm-max) {
          padding: 10px 0 20px;
        }
      }
      & .hd-expert-card {
        padding: 50px 20px 20px;
        text-align: center;
        position: relative;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        flex: 1;
        height: 250px;
        max-height: 250px;
        box-shadow: none;
        cursor: pointer;
        transition: all 0.4s linear;
        transform-style: preserve-3d;
        &:nth-child(1) {
          margin-left: 15px;
        }
        &:nth-child(4) {
          margin-right: 15px !important;
        }
        @media screen and (min-width: $screen-md-min) {
          max-width: calc((100% / 4) - 24px);
        }
        @media screen and (max-width: $screen-sm-max) {
          min-width: calc((100% / 1.9) - 12px);
          max-width: 210px;
        }
        &:hover,
        &:focus {
          box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
          border: 1px solid #fff;
          transition: all 0.4s linear;
        }
        & .hd-expert-name {
          font-weight: 700;
          font-size: 24px;
          line-height: 26px;
          margin-top: 12px;
          @media screen and (max-width: $screen-sm-max) {
            font-size: 20px;
            line-height: 22px;
            height: 44px;
          }
        }
        & .hd-expert-bg {
          position: absolute;
          height: 116px;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          object-fit: contain;
          border-radius: 12px 12px 0px 0px;
          &.bg-0 {
            background-image: url("/images/homepage/expert-bg1.svg");
          }
          &.bg-1 {
            background-image: url("/images/homepage/expert-bg2.svg");
          }
          &.bg-2 {
            background-image: url("/images/homepage/expert-bg3.svg");
          }
          &.bg-3 {
            background-image: url("/images/homepage/expert-bg4.svg");
          }
        }
        & .hd-expert-profile-pic {
          @media screen and (max-width: $screen-sm-max) {
            width: 90px !important;
            height: 90px !important;
            line-height: 90px !important;
            font-size: 18px;
          }
        }
        & .hd-expert-link {
          font-size: 16px;
          line-height: 20px;
          margin-top: 8px;
          font-weight: 500;
          // height: 20px;
          word-break: break-word;
        }
      }
    }
    & .hd-christmas-tree {
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
        position: relative;
      }
    }
    & .hd-chritsmas-wall {
      position: absolute;
      right: 0;
      bottom: 4%;
      @media screen and (max-width: $screen-sm-max) {
        width: 300px;
        bottom: 12%;
      }
    }
  }
  & .home-christmas {
    padding-top: 215px;
    padding-bottom: 200px;
    background-image: url("/images/homepage/home-experts-bg.svg");
    background-color: #e6fcff;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    & *,
    & .ant-typography {
      color: #2a0e00 !important;
    }
    @media screen and (max-width: $screen-sm-max) {
      padding-top: 70px;
      padding-bottom: 0;
    }
    & .hd-title {
      text-align: left;
      font-size: 90px;
      line-height: 81px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 56px;
        line-height: 50px;
      }
    }
    & .hd-subtitle {
      margin-top: 32px;
      font-size: 24px;
      font-weight: 500;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 22px;
        font-size: 20px;
        line-height: 30px;
      }
    }
    & .hd-categories {
      gap: 20px;
      margin-top: 48px;
      margin-bottom: 40px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 34px;
        gap: 12px;
      }
      & .hd-category {
        border-radius: 40px;
        border: 1px solid #401600;
        display: inline-flex;
        padding: 12px 40px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        line-height: 175%; /* 31.5px */
        &.active {
          background: #401600;
          color: #fff !important;
          font-weight: 700;
        }
        @media screen and (max-width: $screen-sm-max) {
          padding: 6px 16px;
          font-size: 16px;
        }
      }
    }
    & .hd-expert-profile-pic {
      position: absolute;
      border-radius: 90px;
      border: 3px solid #fff;
      background: #fafafa;
      cursor: pointer;
      box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.25);
      width: 100px;
      height: 100px;
      @media screen and (max-width: $screen-sm-max) {
        width: 64px;
        height: 64px;
      }
      &.pic-you {
        left: 40%;
        top: 40%;
        border: 3px solid #fff;
        background: #8a60f6;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transform: rotate(-10deg);
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.25);
        color: #fff !important;
        & b {
          font-weight: 800;
        }
        & * {
          color: #fff !important;
        }
        @media screen and (max-width: $screen-sm-max) {
          font-size: 12px;
          line-height: 14px;
        }
      }
      &.pic-1 {
        right: 30%;
        top: 10%;
      }
      &.pic-2 {
        left: 22%;
        top: 15%;
      }
      &.pic-3 {
        right: 15%;
        top: 22%;
      }
      &.pic-4 {
        right: 40%;
        top: 26%;
      }
      &.pic-5 {
        left: 11%;
        top: 29%;
      }
      &.pic-6 {
        right: 12%;
        top: 36%;
      }
      &.pic-7 {
        left: 6%;
        top: 43%;
      }
      &.pic-8 {
        right: 4%;
        top: 50%;
      }
      &.pic-9 {
        right: 33%;
        top: 54%;
      }
      &.pic-10 {
        left: 3%;
        top: 57%;
      }
      &.pic-11 {
        left: 28%;
        top: 64%;
      }
      &.pic-12 {
        right: 2%;
        top: 64%;
      }
      &.pic-13 {
        left: -3%;
        top: 72%;
      }
      &.pic-14 {
        right: 26%;
        top: 73%;
      }
    }
    & .hd-signup {
      position: absolute;
      bottom: 30px;
      margin-top: 32px;
      & * {
        font-size: 24px;
      }
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }

  & .homepage-stats {
    padding: 80px 0 80px 0;
    background-color: #f7f9fd;
    position: relative;
    @media screen and (max-width: $screen-sm-max) {
      padding: 48px 0 70px 0;
    }
    & .header {
      font-size: 54px;
      line-height: 65px;
      font-weight: 700;
      margin-bottom: 80px;
      text-align: center;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 38px;
        text-align: left;
        margin-bottom: 20px;
      }
    }
    & .stats-section {
      margin-bottom: 80px;
      @media screen and (max-width: $screen-sm-max) {
        justify-content: flex-start;
      }
      & .stats-header {
        font-size: 80px;
        line-height: 1;
        text-align: center;
        margin-bottom: 8px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 40px;
          line-height: 1;
          text-align: left !important;
          margin-top: 30px;
        }
      }
      & .stats-subtext {
        font-size: 18px;
        line-height: 22px;
        color: #556370;
        text-align: center;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 16px;
          line-height: 18px;
          text-align: left;
        }
      }
    }
  }
  & .homepage-signup-widget {
    // padding: 156px 0 140px 0;
    padding: 60px 0 330px 0;
    position: relative;
    background-color: #5855cb;
    @media screen and (max-width: $screen-sm-max) {
      padding: 40px 0 200px 0;
    }
    & .women-img-earning {
      position: absolute;
      bottom: 0px;
      left: 0;

      @media screen and (min-width: $screen-sm-max) {
        width: 1200px;
      }
      @media screen and (max-width: $screen-sm-max) {
        right: 0;
        width: 100%;
      }
    }
    & .hsw-col {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    & .hsw-title {
      & b {
        font-weight: 900;
      }
      margin-bottom: 0px;
      color: #fff;
      font-size: 72px;
      font-style: normal;
      font-weight: 300;
      line-height: 110%;

      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 36px;

        // text-align: lcenter;
      }
    }
    & .hsw-subtitle {
      font-size: 18px;
      line-height: 32px;
      font-weight: 500;
      color: #fff;
      margin-top: 16px;
      margin-bottom: 0px;
      @media screen and (max-width: $screen-sm-max) {
        text-align: center;
        font-size: 16px;
        line-height: 28px;
        width: 100%;
        text-align: left;
      }
    }
    & .hsw-label {
      font-size: 20px;
      line-height: 26px;
      margin-top: 54px;
    }
    & .hsw-input {
      border: 2px solid #141414;
      outline: 3px solid rgba(20, 20, 20, 0.14);
      border-radius: 12px;
      margin-top: 48px;
      height: 64px;
      padding: 0 12px 0 24px;
      @media screen and (max-width: $screen-sm-max) {
        height: 48px;
        padding: 0 8px 0 12px;
      }
      & input {
        font-size: 20px !important;
        line-height: 20px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 14px !important;
          line-height: 18px;
        }
      }
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
      }
    }
    & .hsw-input-img {
      cursor: pointer;
      width: 40px;
      font-size: 36px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 28px;
      }
    }
    & .hsw-helper-text {
      font-size: 20px;
      color: #fff;
      line-height: 30px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
      }
      & a {
        text-decoration: underline;
        color: #fff !important;
      }
    }
    & .preview-hero-img {
      @media screen and (max-width: $screen-sm-max) {
        width: 80%;
        margin-bottom: 40px;
      }
    }
    & .home-stats-img {
      // width: 100%;
      height: 100%;
      object-fit: contain;
      flex-shrink: 0;
      display: inline-flex;
    }
    & .home-stats-container {
      display: flex;
      gap: 44px;
      margin-top: 60px;
      @media screen and (max-width: $screen-sm-max) {
        flex-wrap: wrap;
        gap: 24px;
        & img {
          width: 100%;
        }
      }
    }
    & .stats2-conatiner {
      background-color: #353379;
      border-radius: 48px;
      padding: 41px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 24px 32px;
      }
      & .stats-text {
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 12px;
          font-size: 20px;
        }
      }
      & .stats-number {
        color: #fff;
        font-size: 60px;
        font-weight: 500;
        line-height: 129%;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 48px;
        }
      }
    }
  }
  & .homepage-features {
    padding: 60px 0 170px;
    background-color: #fff;
    @media screen and (max-width: $screen-sm-max) {
      padding: 15px 0 50px;
    }
    & .header {
      font-size: 54px;
      line-height: 70px;
      font-weight: 800;
      margin-bottom: 50px;
      position: relative;
      overflow: hidden;
      text-align: center;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 0;
      }
    }
    & .features-post {
      margin-top: 100px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 20px;
      }
      & .feature-header {
        font-size: 64px;
        line-height: 78px;
        font-weight: 700;
        margin-top: 60px;
        letter-spacing: -0.03em;
        @media screen and (max-width: $screen-sm-max) {
          margin-top: 32px;
          font-size: 28px;
          line-height: 34px;
          font-weight: 700;
        }
      }
      & .feature-subheader {
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        margin-top: 32px;
        color: #434343;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 14px;
          line-height: 26px;
          margin-top: 12px;
        }
      }
      & .featured-cta {
        font-size: 19px;
        line-height: 34px;
        font-weight: 00;
        margin-top: 48px;
        font-style: italic;
        text-decoration: underline;
        & a {
          font-weight: 600;
          color: $text-color;
        }
        @media screen and (max-width: $screen-sm-max) {
          font-size: 14px;
          line-height: 26px;
          margin-top: 15px;
        }
      }
      & .feature-img {
        width: 100%;
        height: auto;
        @media screen and (max-width: $screen-sm-max) {
          margin-top: 26px;
        }
        &.mobile-only {
          display: none;
          @media screen and (max-width: $screen-sm-max) {
            display: block;
          }
        }
      }
      & .home-embed-demo {
        position: absolute;
        bottom: 79px;
        left: -106px;
        cursor: pointer;
        border-radius: 8px;
        box-shadow: 4px 4px 0px #000000;
        transition: all 0.4s linear;
        @media screen and (max-width: $screen-sm-max) {
          bottom: -8px;
          right: -10px;
          left: unset;
        }
        &:hover {
          box-shadow: none;
        }
      }
    }
    & .feature-collapse {
      & .fc-header {
        border-bottom: 1px solid #d1d1da;
        border-radius: 0;
        padding: 17px 0;
        transition: all 0.25s ease-in-out;
        &.ant-collapse-item-active {
          & .fc-title {
            color: #b3490c;
            font-weight: 700;
          }
        }
        &:hover,
        &:focus {
          background-color: #f7f7f7;
          transition: all 0.25s ease-in-out;
        }
      }
      & .fc-title {
        font-weight: 500;
        font-size: 28px;
        line-height: 30px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 20px;
          line-height: 22px;
        }
      }
      & .fc-description {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        color: #434343;
        @media screen and (max-width: $screen-sm-max) {
          line-height: 20px;
          margin-top: 0;
        }
      }
      & .fc-number {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #b3490c;
      }
      & .ant-collapse-content-box {
        padding: 0px 16px;
      }
    }
  }
  & .homepage-success-stories {
    background: #1b363f;
    padding: 100px 0;
    @media screen and (max-width: $screen-sm-max) {
      padding: 40px 0 60px;
    }
    & .success-stories-header {
      font-size: 64px;
      line-height: 84px;
      font-weight: 700;
      text-align: center;
      color: #fff;
      margin-bottom: 60px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 40px;
      }
    }
    & .swiper {
      padding-bottom: 20px;
    }
    & .swiper-pagination {
      bottom: 2px;
      z-index: 100;
    }
    & .swiper-slide {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: $screen-sm-max) {
        // margin-top: 60px;
        height: 100%;
      }
      & .testimonial-card {
        background: rgba(255, 255, 255, 0.9);
        border-radius: 40px;
        height: 280px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 0;
        }
      }
      & .ss-description {
        padding: 24px 28px;
        & .ss-quote {
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          min-height: 94px;
          @media screen and (max-width: $screen-sm-max) {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
    }
    & .ss-footer {
      margin-bottom: 32px;
    }
    & .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #4f3e2f;
      &.swiper-pagination-bullet-active {
        background-color: #141414;
      }
    }
  }
  & .homepage-founders {
    background-color: #fdf2e6;
    padding: 100px 0 80px 0;
    position: relative;
    @media screen and (max-width: $screen-sm-max) {
      padding: 60px 0 60px 0;
    }
    & .header {
      font-size: 54px;
      line-height: 70px;
      font-weight: 800;
      margin-bottom: 40px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 44px;
      }
    }
    & .hf-text {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      margin-bottom: 40px;
      font-style: italic;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    & .icon-pencil {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 250px;
      @media screen and (max-width: $screen-sm-max) {
        width: 110px;
      }
    }
    & .ss-footer {
      display: flex;
      padding-top: 40px;
      margin: 0;
      & .ss-topmate-user {
        font-weight: 400;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      & .ss-topmate-link {
        color: $success-color;
        font-weight: 600;
        padding: 0;
        height: 28px;
        word-break: break-word;
      }
    }
  }
  & .homepage-bottom-section {
    padding-top: 90px;
    padding-bottom: 250px;
    position: relative;
    @media screen and (max-width: $screen-sm-max) {
      padding-top: 80px;
      padding-bottom: 160px;
      & .username-input-title {
        margin-left: 0 !important;
        text-align: left;
        width: 70%;
      }
    }
    & .bottom-btn-1 {
      padding: 0 30px;
      font-size: 19px;
      line-height: 20px;
      font-weight: 700;
      height: 56px;
      border-radius: 6px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 10px !important;
        min-width: 300px !important;
      }
    }
    & .bs-header {
      font-size: 54px;
      font-weight: 800;
      line-height: 70px;
      margin-bottom: 0;
      text-align: center;
      & span {
        color: $secondary-color;
      }
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 40px;
      }
    }
    & .bs-button-container {
      margin-top: 70px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 30px;
      }
    }
    & .icon-clap {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      @media screen and (max-width: $screen-sm-max) {
        max-width: 270px;
      }
    }
    & .bottom-section-testimonial {
      display: flex;
      align-items: center;
      width: 60%;
      margin: 0 auto;
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
      }
      & .user-img {
        width: 60;
        height: 60;
        border-radius: 50%;
      }
      & .user-testimonial {
        font-size: 16px;
        line-height: 28px;
        border-left: 2px solid $secondary-color;
        padding-left: 18px;
        margin-left: 18px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
  & .homepage-featured {
    background-color: #000;
    color: #fff;
    & .hf-marquee {
      padding: 14px 0 20px 0;
      border-bottom: 1px solid #fff;
      & .marquee-text {
        color: #fff;
        font-size: 32px;
        line-height: 44px;
        font-weight: 400;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      & .marquee-img {
        margin: 0 16px;
        @media screen and (max-width: $screen-sm-max) {
          width: 34px;
          height: 34px;
          margin: 0 14px;
        }
      }
    }
    & .hf-main {
      padding: 120px 0 180px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 60px 0 120px;
      }
    }
    & .testimonials-slide {
      display: grid;
      grid-template-rows: 1fr 2fr;
      height: 100%;
      max-height: 360px;
      overflow: hidden;
    }
    & .left-arrow,
    & .right-arrow {
      position: absolute;
      width: 64px;
      height: 64px;
      bottom: 35%;
      z-index: 1;
      margin: 0;
      cursor: pointer;
      border-radius: 50%;
      & img {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(146deg)
          brightness(116%) contrast(104%);
        @media screen and (max-width: $screen-sm-max) {
          width: 40px;
        }
      }
      @media screen and (max-width: $screen-sm-max) {
        width: 40px;
        height: 40px;
        bottom: -60px;
      }
    }
    & .right-arrow {
      right: -9%;
      transform: rotate(180deg);
      @media screen and (max-width: $screen-sm-max) {
        right: 35%;
      }
    }
    & .left-arrow {
      left: -9%;
      @media screen and (max-width: $screen-sm-max) {
        left: 35%;
      }
    }
    & .swiper-slide {
      height: 360px;
      border: 1px solid #fff;
      background-color: #fff;
      @media screen and (max-width: $screen-sm-max) {
        height: 270px;
      }
      & .mate-img {
        width: 100%;
        height: 200px !important;
        object-fit: cover;
        @media screen and (max-width: $screen-sm-max) {
          max-height: 140px;
        }
      }
      & .mate-description {
        padding: 20px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: $screen-sm-max) {
          padding: 10px 15px;
        }
        & .mate-name {
          font-size: 19px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 6px;
        }
        & .mate-subtitle {
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          word-break: break-word;
          @media screen and (max-width: $screen-sm-max) {
            font-size: 13px;
          }
        }
        & .mate-service {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          & .service-description {
            text-align: left;
            word-break: break-word;
          }
        }
      }
    }
  }
  & .close-icon {
    position: fixed;
    right: 25px;
    top: 24px;
    z-index: 99;
    @media screen and (max-width: $screen-sm-max) {
      top: 16px;
      right: 15px;
    }
  }
  & .homepage-services {
    padding: 90px 0 140px;
    background: #f2f2f2;
    @media screen and (max-width: $screen-sm-max) {
      padding: 80px 0 90px;
    }
    & .homepage-mobile-services {
      @media screen and (max-width: $screen-sm-max) {
        display: grid;
        gap: 20px;
      }
    }
    & .lottie-animation {
      position: absolute;
      top: 0;
      right: 0;
      &.discount {
        width: 116px;
        position: absolute;
        top: 30px;
        left: 30px;
        @media screen and (max-width: $screen-sm-max) {
          top: 20px;
          left: 20px;
          width: 72px;
        }
      }
      &.ballon {
        width: 174px;
        @media screen and (max-width: $screen-sm-max) {
          width: 96px;
        }
      }
      &.email {
        width: 152px;
        left: 10px;
        @media screen and (max-width: $screen-sm-max) {
          width: 120px;
          left: 0;
        }
      }
    }
    & .hs-title {
      & b {
        font-weight: 900;
      }
      text-align: center;
      font-size: 64px;
      line-height: 84px;
      font-weight: 500;
      margin-bottom: 64px;
      color: #000;
      & .ant-typography {
        color: #000;
      }
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 36px;
      }
    }
  }

  & .home-built-for-you {
    background: #183a23;
    padding: 90px 0 110px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 60px 0;
    }
    & .ant-typography {
      color: #fff;
    }
    & .title {
      font-size: 64px;
      font-weight: 500;
      line-height: 84px;
      margin-bottom: 0;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
        margin-bottom: 40px;
      }
    }
    & .subtitle {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 11px;
      margin-bottom: 86px;
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }
    & .bfy-img {
      width: 100%;
      @media screen and (max-width: $screen-sm-max) {
        height: auto;
      }
    }
    & .bfy-lottie {
      position: relative;
      & .lottie-animation {
        position: absolute;
      }
    }
  }

  & .top-supporters-banner {
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 60px;
      width: 100%;
    }
  }

  & .red-check-mark {
    position: absolute;
    right: 36%;
    & img {
      width: 105px;
      height: auto;
    }
    @media screen and (max-width: $screen-sm-max) {
      left: 0;
    }
  }
  .js-user-slider {
    transition:
      width 0.2s,
      height 0.2s;
    display: inline-flex;
    margin: 0 auto;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    white-space: nowrap;
    color: $secondary-color;
  }
  .js-user-slider span {
    display: none;
    opacity: 0;
  }
  .js-user-slider span:first-of-type {
    display: block;
    opacity: 1;
  }
  .js-user-slide-init {
    transform: translate3d(0, -100%, 0);
  }
  .js-user-slider.js-loaded span {
    display: block;
    position: absolute;
    top: 100%;
    left: auto;
    right: auto;
  }
  .js-user-slide-in {
    animation: slideIn 1s forwards;
  }
  .js-user-slide-out {
    animation: slideOut 1s forwards;
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translate3d(0, -150%, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, -100%, 0);
    }
  }
  @keyframes slideOut {
    0% {
      opacity: 1;
      transform: translate3d(0, -100%, 0);
    }

    30% {
      opacity: 0;
    }

    100% {
      opacity: 0;
      transform: translate3d(0, -50%, 0);
    }
  }
}

.img-services {
  width: 100%;
  position: relative !important;
  @media screen and (min-width: $screen-sm-max) {
    height: 100% !important ;
    object-fit: cover;
    border-radius: 38px;
  }
}

.profile-preview-drawer {
  & .ant-drawer-header {
    display: none;
  }
  & .sidebar {
    border-right: 1px solid #e5e9f2;
    min-height: 100vh;
    padding: 36px 24px;
    position: relative;

    & .sidebar-benefits {
      position: absolute;
      bottom: 0;
      padding: 70px 24px 64px;
      border-top: 1px solid #e5e9f2;
      & .title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 30px;
      }
      & .benefits {
        display: flex;
        align-items: center;
        color: #636363;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
        & svg {
          margin-right: 8px;
        }
      }
    }
  }
  & .main-content {
    background: #f4f4f4;
    position: relative;
    & .iframe-content {
      @media screen and (max-width: $screen-sm-max) {
        margin-bottom: 120px;
      }
    }

    & .mc-footer {
      position: absolute;
      bottom: 0;
      background: #ffffff;
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.03);
      left: 0;
      right: 0;
      padding: 40px 24px;
      @media screen and (max-width: $screen-sm-max) {
        border-radius: 16px 16px 0px 0px;
        position: fixed;
        padding: 14px 0px 20px;
      }
    }
    & .mc-marquee {
      border-bottom: 1px dotted #c3c3c3;
      padding-bottom: 14px;
      margin-bottom: 20px;
      & .mc-marq-item {
        margin-right: 10px;
        display: flex;
        align-items: center;
        color: #636363;
        font-size: 12px;
      }
      & .mc-marq-icon {
        flex-shrink: 1;
        margin-right: 5px;
        color: #636363;
      }
    }
    & .preview-signup-btn {
      font-size: 20px;
      height: 64px;
      & .arrow {
        position: absolute;
        right: 30px;
        top: 19px;
      }
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
        height: 48px;
        & .arrow {
          right: 10px;
          top: 12px;
        }
      }
    }
    & .mc-cta {
      @media screen and (max-width: $screen-sm-max) {
        padding-right: 12px;
      }
    }
    & .mc-earnings {
      @media screen and (max-width: $screen-sm-max) {
        padding-left: 12px;
      }
      & .amount {
        color: #005842;
        font-size: 32px;
        line-height: 42px;
        font-weight: 700;
        display: inline-flex;
        align-items: center;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 18px;
          line-height: 24px;
        }
      }
      & .text {
        font-size: 16px;
        line-height: 20px;
        @media screen and (max-width: $screen-sm-max) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.username-input-outer {
  margin-top: 72px !important;
  border-radius: 41px;
  background: #fff;
  border: 2px solid #141414;
  outline: 3px solid rgba(20, 20, 20, 0.14);
  min-width: 280px;
  max-width: 400px;
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  padding: 10px 10px 10px 10px;
  & .ant-typography {
    word-break: initial;
  }
  @media screen and (max-width: $screen-sm-max) {
    font-size: 16px;
    min-width: auto;
    margin-top: 40px;
  }
  & > p {
    margin: 0 !important;
  }
  & .username-input-arrow {
    color: $text-color;
    width: 40px;
    transition: none !important;
    opacity: 1 !important;
  }
  & .username-input {
    font-size: 20px !important;
    padding: 0;
    border: none;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 16px;
    }
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
      background-color: transparent;
    }
  }
}

.username-input-title {
  font-size: 20px !important;
  line-height: 25px;
  margin: 24px 0 14px 20px !important;
  @media screen and (max-width: $screen-sm-max) {
    font-size: 16px !important;
    line-height: 20px;
  }
}

.homepage-stats {
  padding: 80px 0 80px 0;
  position: relative;
  @media screen and (max-width: $screen-sm-max) {
    padding: 48px 0 70px 0;
  }
  & .header {
    font-size: 54px;
    line-height: 65px;
    font-weight: 700;
    margin-bottom: 80px;
    text-align: center;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 32px;
      line-height: 38px;
      text-align: left;
      margin-bottom: 20px;
    }
  }
  & .stats-section {
    margin-bottom: 80px;
    @media screen and (max-width: $screen-sm-max) {
      justify-content: flex-start;
    }
    & .stats-header {
      font-size: 80px;
      line-height: 1;
      text-align: center;
      margin-bottom: 8px;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 40px;
        line-height: 1;
        text-align: left !important;
        margin-top: 30px;
      }
    }
    & .stats-subtext {
      font-size: 18px;
      line-height: 22px;
      color: #556370;
      text-align: center;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
        line-height: 18px;
        text-align: left;
      }
    }
  }
}

.video-modal {
  & .ant-modal-content {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
  & .ant-modal-close {
    position: fixed;
    top: 70px;
    right: 50px;
    @media screen and (max-width: $screen-sm-max) {
      top: 100px;
      right: 20px;
    }
  }
  & .youtube-player {
    @media screen and (max-width: $screen-sm-max) {
      width: 100%;
      height: auto;
    }
  }
}

.home-b2b {
  padding-top: 115px;
  padding-bottom: 175px;
  @media screen and (max-width: $screen-sm-max) {
    padding-top: 42px;
    padding-bottom: 60px;
  }
  & .hb-tag {
    background-color: #dbd1fc;
    border-radius: 25px;
    padding: 12px 24px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    display: inline-flex;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 24px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }
  }
  & .hb-title {
    margin-top: 20px !important;
    font-size: 64px;
    line-height: 78px;
    font-weight: 800;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 10px !important;
      font-size: 28px;
      line-height: 34px;
      font-weight: 700;
    }
  }
  & .hb-subtitle {
    margin-top: 32px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #434343;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 12px;
      font-size: 14px;
      line-height: 22px;
    }
  }
  & .hb-cta {
    margin-top: 24px;
    & .ant-btn {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      min-width: 180px;
    }
  }
  & .hb-img {
    width: 100%;
  }
}

.home-ai-search {
  margin-top: -50px;
  padding: 140px 0;
  background: #0e1116;
}
.ai-search {
  & .title {
    font-size: 72px;
    line-height: 80px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 16px;
    & b {
      font-weight: 700;
    }
    @media screen and (max-width: $screen-sm-max) {
      font-size: 40px;
      line-height: 110%;
    }
  }
  & .description {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #fff;
  }
  & .footer {
    background-color: transparent;
    margin-top: 20px;
  }

  & .aisearch-example {
    border-radius: 12px;
    border: 1px solid #b0afaf;
    padding: 16px;
    margin-bottom: 24px;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.12);
    }
    & .eg-title {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 130%; /* 20.8px */
    }
    & .eg-desc {
      color: rgba(255, 255, 255, 0.59);
      font-size: 16px;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      @media screen and (max-width: $screen-sm-max) {
        font-size: 14px;
      }
    }
  }

  & .ai-search-swiper {
    margin-top: 50px;
    margin-bottom: 50px;
    & .swiper-wrapper {
      padding-bottom: 24px;
    }
    & .swiper-pagination {
      bottom: 0;
    }
    & .swiper-pagination-bullet {
      background-color: #c3c3c3;
    }
    & .swiper-pagination-bullet-active {
      background-color: #fff;
    }
  }

  & .ai-expert-card {
    border-radius: 24px;
    border: 1px solid #1b1f25;
    background: #0d080f;
    overflow: hidden;
    height: auto;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    width: 600px;
    @media screen and (max-width: $screen-sm-max) {
      height: auto;
      width: 90%;
    }
    & .ant-row {
      padding: 20px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 10px;
      }
    }
    &.swiper-slide-next {
      box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.12);
    }
    &.swiper-slide-active {
      z-index: 9;
    }
    & .ai-expert-content {
      height: 100%;
      padding: 20px 24px;
      @media screen and (max-width: $screen-sm-max) {
        padding: 10px;
      }
    }
    & .ai-booking-rating {
      position: absolute;
      bottom: 12px;
      left: 12px;
      z-index: 2;
      & .ai-expert-bookings {
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.66);
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 110%; /* 15.4px */
        padding: 10px;
      }
    }
    & .ai-expert-img {
      border-radius: 22px;
      object-fit: contain;
      position: relative;
      width: 100%;
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
        border-radius: 20px;
      }
    }
    & .ai-expert-name {
      color: #fff;
      font-weight: 700;
      line-height: 1;
      margin-top: 16px;
      font-size: 18px;
    }
    & .ai-expert-description {
      color: #59616a;
      margin-top: 12px;
      font-size: 16px;
      line-height: 130%; /* 20.8px */
      margin-bottom: 57px;
      @media screen and (max-width: $screen-sm-max) {
        margin-bottom: 70px;
      }
    }
    & .feedback-container {
      position: absolute;
      bottom: 20px;
      & .thumbs-icon {
        color: #4d4d4d;
        border-radius: 20.852px;
        background: rgba(255, 255, 255, 0.06);
        padding: 8px 13px;
      }
    }
    & .ai-expert-arrow {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      z-index: 2;
      width: 42px;
      height: 42px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 20.852px;
      border: 1 solid #393939;
      background: rgba(222, 225, 207, 0.12);
      box-shadow: 0px 3.4753px 3.4753px 0px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(5.212947368621826px);
      @media screen and (max-width: $screen-sm-max) {
        top: 10px;
        right: 10px;
      }
    }
  }

  & .ai-expert-slider-left {
    border-radius: 24px;
    background: linear-gradient(
      270deg,
      #171b21 -16.87%,
      rgba(23, 27, 33, 0) 100%
    );
    height: 290px;
  }

  & .ai-expert-slider-right {
    border-radius: 24px;
    background: linear-gradient(
      90deg,
      #171b21 -16.87%,
      rgba(23, 27, 33, 0) 100%
    );
    height: 290px;
  }

  & .ai-expert-swiper {
    margin: 0 auto;
    padding: 26px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 10px;
    }
  }
  & .left-arrow,
  & .right-arrow {
    position: absolute;
    width: 64px;
    height: 64px;
    bottom: 40%;
    z-index: 1;
    margin: 0;
    cursor: pointer;
    border-radius: 50%;
    & img {
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(146deg)
        brightness(116%) contrast(104%);
      @media screen and (max-width: $screen-sm-max) {
        width: 40px;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      width: 40px;
      height: 40px;
      bottom: 48%;
    }
  }
  & .right-arrow {
    right: -10%;
    transform: rotate(180deg);
    @media screen and (max-width: $screen-sm-max) {
      right: -15%;
    }
  }
  & .left-arrow {
    left: -10%;
    @media screen and (max-width: $screen-sm-max) {
      left: -15%;
    }
  }

  & .ai-loading-state {
    text-align: center;
    margin-bottom: 10px;
    color: #fff;
  }

  & .ai-search-btn {
    border-radius: 56px;
    background: #8a60f6 !important;
    border: 1px solid #8a60f6;
    display: inline-flex;
    height: 56px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 130%; /* 26px */
    &:hover,
    &:focus {
      background: #8a60f6;
      border: 1px solid #8a60f6;
      color: #fff;
    }
    @media screen and (max-width: $screen-sm-max) {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 0;
    }
  }

  & .suggest-input {
    margin-bottom: 0px;
    margin-top: 60px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 24px;
    }

    & .ant-input-affix-wrapper {
      position: relative;
      height: 72px;
      border-radius: 36px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      background: #fff;
      box-shadow: 12px 12px 24px 0px rgba(0, 0, 0, 0.02);
      @media screen and (max-width: $screen-sm-max) {
        height: 50px;
      }
    }
    & .ant-input {
      background: #fff;
      font-size: 24px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 31.2px */
      padding-left: 24px;
      &::placeholder {
        color: rgba(77, 77, 77, 0.59);
      }
      @media screen and (max-width: $screen-sm-max) {
        padding-left: 12px;
        font-size: 16px !important;
      }
    }
  }
  & .ai-listing-card {
    border-radius: 70px;
    background: rgba(255, 255, 255, 0.14);
    padding: 4px 40px 4px 4px;
    margin-top: 40px;
    width: 100%;
    max-width: 604px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 12px;
    }

    & .listing-title {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      line-height: 150%; /* 36px */
      text-align: left;
    }
    & .listing-desc {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      text-align: left;
    }
    & .listing-btn {
      border-radius: 3px;
      background: #fff;
      box-shadow: none !important;
      border: none;
      font-size: 20px;
      font-weight: 700;
      height: unset;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
}

.stories-menu-link {
  color: #141414 !important;
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: $screen-sm-max) {
    font-size: 13px;
  }

  &.active {
    text-decoration-line: underline;
    font-weight: 700;
    @media screen and (max-width: $screen-sm-max) {
      color: #a31202 !important;
    }
  }
}

// .infihuman-review-swiper {
//   &.swiper {
//     @media screen and (min-width: $screen-sm-max) {
//       height: 409px !important;
//     }
//   }

//   & .other-cards {
//     opacity: 0.5;
//     @media screen and (max-width: $screen-sm-max) {
//       display: none;
//     }
//   }

//   .swiper-slide {
//     width: fit-content;
//     height: auto;
//     transition: all 0.3s ease;
//     transform: scale(0.6) translateZ(0) !important;
//     .testimonial-image {
//       width: 281px;
//       transition: all 0.4s linear;
//     }
//     & .active-card {
//       display: none;
//     }
//     &.swiper-slide-prev {
//       transform: scale(0.8) !important;
//       @media screen and (min-width: $screen-sm-max) {
//         width: 231px !important;
//         height: 343px !important;
//       }
//       & .active-card {
//         display: none;
//       }
//     }
//     &.swiper-slide-next {
//       @media screen and (min-width: $screen-sm-max) {
//         width: 231px !important;
//         height: 343px !important;
//       }
//       transform: scale(0.8) !important;
//       & .active-card {
//         @media screen and (min-width: $screen-sm-max) {
//           display: none;
//         }
//       }
//     }

//     &.swiper-slide-active {
//       @media screen and (min-width: $screen-sm-max) {
//         width: 880px !important;
//         height: 409px;
//       }
//       z-index: 2;
//       transform: scale(1) !important;

//       & .active-card {
//         overflow: auto;
//         display: inline-block;
//       }
//       & .other-cards {
//         @media screen and (min-width: $screen-sm-max) {
//           display: none;
//         }
//       }
//     }
//   }
// }
.review-swiper-arrows {
  margin-top: 24px;
  & .left-right-arrow {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #183a23;
    border-radius: 50%;
    cursor: pointer;
  }
}
