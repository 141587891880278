.error-500 {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  color: #000;
  font-family: "Arial Black";
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  & .error-num {
    font-size: 10em;
  }
  #errorText {
    font-size: 22px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 4em;
  }
  & .eye {
    background: #000;
    border-radius: 50%;
    display: inline-block;
    height: 100px;
    position: relative;
    width: 100px;
    margin-right: 8px;
    &::after {
      background: #fff;
      border-radius: 50%;
      bottom: 56.1px;
      content: "";
      height: 33px;
      position: absolute;
      right: 33px;
      width: 33px;
    }
  }
}
