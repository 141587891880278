.terms-privacy {
  & .header {
    background-color: #141414;
    padding-top: 232px;
    padding-bottom: 250px;
    justify-content: center;
    text-align: center;
    color: #fff;
    @media screen and (max-width: $screen-xs-max) {
      padding-top: 100px;
      padding-bottom: 60px;
    }
    & .heading {
      color: #fff;
      font-weight: 700;
      font-size: 68px;
      line-height: 110%;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 40px;
      }
    }
  }
  & .content {
    padding: 0px 24px 160px 24px;
    @media screen and (min-width: $screen-xs-max) {
      padding: 40px 241px 160px 241px;
    }
    & .title {
      font-weight: 700;
      font-size: 48px;
      line-height: 110%;
      margin-bottom: 24px;
      margin-top: 64px;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 32px;
        margin-top: 56px;
      }
    }
    & .text {
      font-weight: 500;
      font-size: 18px;
      line-height: 180%;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 16px;
      }
    }
    & .privacy-para {
      padding-top: 40px;
    }
  }
}
