.about-contact {
  padding: 100px 0 170px;
  background-color: #ffebcd;
  @media screen and (max-width: $screen-sm-max) {
    padding: 70px 0 90px;
  }
  & .about-contact-title {
    font-size: 54px;
    line-height: 70px;
    font-weight: 700;
    margin-bottom: 48px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 32px !important;
      margin-left: 12px;
    }
  }
  & .contact-card {
    height: 100%;
    background: #ffffff;
    border: 2px solid #000000;
    border-radius: 24px;
    padding: 40px 40px 32px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: $screen-sm-max) {
      padding: 20px 20px;
      margin-bottom: 16px;
      height: auto;
    }
  }
  & .cc-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 24px;
  }
  & .cc-content {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 40px;
    @media screen and (max-width: $screen-sm-max) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  .number {
    color: #141414;
  }
}
