$card-height: 330px;
$card-bg-height: 130px;
$card-mobile-height: 175px;

.rewards {
  & .rewards-card {
    border: 1px solid #e3e8ed;
    border-radius: 3px;
    height: $card-height;
    overflow: hidden;
    display: flex;
    margin-bottom: 30px;
    @media screen and (max-width: $screen-sm-max) {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border: none;
      border-bottom: 1px solid #e3e8ed;
      height: $card-mobile-height;
    }
    & .rc-bg {
      height: 130px;
      display: flex;
      align-items: center;
      padding: 0 30px;
      & > img {
        object-fit: contain;
        max-width: 60px;
      }
      @media screen and (max-width: $screen-sm-max) {
        height: 100%;
        justify-content: center;
      }
    }
    & .rc-title {
      margin-top: 12px;
      font-size: 20px;
      line-height: 26px;
      font-weight: 800;
      @media screen and (max-width: $screen-sm-max) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    & .rc-cta {
      height: calc($card-height - $card-bg-height);
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 0 16px;
      @media screen and (max-width: $screen-sm-max) {
        height: 100%;
      }
      & .ant-btn {
        font-weight: 700;
      }
      & .rc-expiry {
        color: #cd8500;
        @media screen and (max-width: $screen-sm-max) {
          margin-left: 6px;
          font-size: 12px;
        }
      }
      & .to-be-claimed,
      & .pending-approval,
      & .claimed-state {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto 0 24px;
        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 12px;
        }
      }
      & .to-be-claimed {
        align-self: stretch;
        @media screen and (max-width: $screen-sm-max) {
          & .btn-dark {
            height: 36px;
            padding: 0 16px;
          }
        }
      }
    }
    & .status {
      font-size: 14px;
      line-height: 32px;
      border-radius: 16px;
      display: inline-flex;
      padding: 0 16px;
      font-weight: 700;
      white-space: nowrap;
    }
    & .pending-approval {
      background-color: #fdf2d6;
      color: #c78203;
    }
    & .claimed {
      background-color: rgba(0, 128, 96, 0.05);
      color: $primary-color;
    }
    & .claimed-state {
      display: flex;
      align-items: center;
    }
  }
  & .show-more-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 100px;
    font-weight: 700;
    @media screen and (max-width: $screen-sm-max) {
      width: 100%;
    }
  }
}

.rc-tags {
  display: flex;
  align-items: center;
  margin-top: 12px !important;
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 8px !important;
  }
  & .tag {
    background-color: #efefef;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-size: 12px;
    line-height: 21px;
    height: 24px;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.rewards-nav {
  padding: 0 !important;
  & .title-rewards-section {
    border-right: 1px solid #e3e8ed;
    padding: 17px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: $screen-sm-max) {
      padding: 10px 16px;
      border-top: 1px solid #e3e8ed;
      border-bottom: 1px solid #e3e8ed;
      margin-top: 20px;
    }
    &:first-child {
      border-left: 1px solid #e3e8ed;
    }
    &:last-child {
      @media screen and (max-width: $screen-sm-max) {
        border-right: none;
      }
    }
    & .title {
      font-size: 12px;
      line-height: 20px;
      color: rgba(20, 20, 20, 0.61);
      & span {
        color: rgba(20, 20, 20, 0.61);
      }
      @media screen and (max-width: $screen-sm-max) {
        & span {
          display: none;
        }
      }
    }
    & .value {
      font-size: 24px;
      line-height: 32px;
      font-weight: 800;
      margin-top: 5px;
      display: flex;
      align-items: center;
    }
    & .icon {
      width: auto;
      height: 30px;
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }
}

.claim-drawer {
  & .claim-header {
    padding: 32px 100px 32px 32px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 32px 46px 43px 20px;
    }
    &.actions {
      border: 1px solid #e3e8ed;
    }
  }
  & .claim-body {
    padding: 20px 24px;
    @media screen and (max-width: $screen-sm-max) {
      padding-bottom: 160px;
    }
    & .rc-tags {
      padding: 0;
    }
  }
  & .claim-form {
    margin-top: 32px;
  }
  & .drawer-close-icon {
    position: absolute;
    right: 32px;
    top: 32px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 6px;
    color: $text-color;
    cursor: pointer;
    @media screen and (max-width: $screen-sm-max) {
      right: 12px;
    }
  }
  & .claim-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & .back-btn {
      cursor: pointer;
      margin-right: 12px;
      width: 24px;
      height: 24px;
    }
  }
  & .rewards-helper-text {
    margin-top: 30px;
  }
  & .rewards-helper-cta {
    margin-top: 70px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 0;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 18px;
      background-color: #fff;
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    }
    & .helper-text {
      background: #fff9ea;
      border-radius: 6px;
      padding: 16px 12px;
      margin-bottom: 16px;
    }
  }
}
$size: 60px;

.easter-egg {
  & canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & .gift {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    width: 35vw;
    height: auto;
    transform: translate3d(-50%, -50%, 0) rotateY(0) rotateX(0) scale(0);
    transition: all 3s ease-out 0.5s;
    user-select: none;
    @media screen and (max-width: $screen-sm-max) {
      width: 85vw;
    }
  }

  &.present {
    margin: 0 auto;
    padding: 30px 0 10px;
    margin-top: 20px;
    left: 0;
    right: 0;
    width: $size;
    height: 110px;
    cursor: pointer;
    perspective: 600px;
    &.animate .wiggle-container {
      animation: wiggle 0.3s ease 0s 1 normal forwards;
    }
  }

  & .wiggle-container {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transform-origin: bottom;
  }

  @keyframes wiggle {
    0% {
      transform: translateX(0) rotateX(0);
    }
    25% {
      transform: translateX(calc(var(--count) * -1px))
        rotateX(calc(var(--count) * 1deg));
    }
    50% {
      transform: translateX(0) rotateX(0);
    }
    75% {
      transform: translateX(calc(var(--count) * 1px))
        rotateX(calc(var(--count) * -1deg));
    }
    100% {
      transform: translateX(0) rotateX(0);
    }
  }

  & .rotate-container {
    height: 100%;
    transform: rotateY(170deg);
    transform-style: preserve-3d;
    animation: present-rotate 30s infinite linear;
  }

  @keyframes present-rotate {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(360deg);
    }
  }

  .bottom,
  .front,
  .left,
  .back,
  .right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #111;
  }

  .bottom {
    transform: translateY(50%) rotateX(90deg);
  }

  .front,
  .left,
  .back,
  .right {
    transition: transform 1s ease-out 3s;
    transform-origin: bottom;
    transform-style: preserve-3d;
  }

  .front,
  .left,
  .back,
  .right,
  .lid-top,
  .lid-front,
  .lid-left,
  .lid-back,
  .lid-right {
    &:after {
      content: "";
      position: absolute;
      left: calc(50% - 10px);
      width: 20px;
      height: 100%;
      background-color: white;
      transform: translateZ(0.1px);
    }
  }

  & .lid-top {
    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 10px);
      left: 0;
      width: 100%;
      height: 20px;
      background-color: white;
      transform: translateZ(0.1px);
    }
  }

  .left {
    transform: translateX(-50%) rotateY(-90deg);
  }
  .back {
    transform: translateZ(-$size / 2) rotateY(180deg) rotateX(0);
  }
  .right {
    transform: translateX(50%) rotateY(90deg);
  }
  .front {
    transform: translateZ($size / 2);
  }

  & .lid {
    animation: lid-animation 3.5s 1s infinite;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    transition: transform 1s ease-out 3s;
  }

  @keyframes lid-animation {
    0% {
      transform: translate3d(0, 0, 0) rotateX(0);
    }
    5% {
      transform: translate3d(0, -10px, -5px) rotateX(5deg);
    }
    10% {
      transform: translate3d(0, -10px, 5px) rotateX(-5deg);
    }
    15% {
      transform: translate3d(0, -10px, -5px) rotateX(5deg);
    }
    20% {
      transform: translate3d(0, -10px, 5px) rotateX(-5deg);
    }
    25% {
      transform: translate3d(0, -10px, -5px) rotateX(5deg);
    }
    30% {
      transform: translate3d(0, 0, 0) rotateX(0);
    }
  }

  .lid-top,
  .lid-left,
  .lid-back,
  .lid-right,
  .lid-front {
    position: absolute;
    top: 0;
    left: -5px;
    width: $size + 10px;
    background-color: #222;
    opacity: 1;
  }

  .lid-top {
    top: -5px;
    height: $size + 10px;
    transform: translateY(-50%) rotateX(90deg);
    transform-style: preserve-3d;
  }

  .lid-left,
  .lid-back,
  .lid-right,
  .lid-front {
    top: -5px;
    height: 40px;
    transform-style: preserve-3d;
  }

  .lid-left {
    transform: translateX(-50%) rotateY(-90deg);
  }
  .lid-back {
    transform: translateZ(-$size / 2 - 5px) rotateY(180deg);
  }
  .lid-right {
    transform: translateX(50%) rotateY(90deg);
  }
  .lid-front {
    transform: translateZ($size / 2 + 5px);
  }

  &:hover {
    .lid {
      animation: none;
    }
  }

  &.open {
    cursor: default;

    .gift {
      transform: translate3d(-50%, -50%, 100px) rotateY(360deg) rotateX(5deg)
        scale(1);
      top: -50%;
    }

    .wiggle-container {
      animation: none;
    }

    .left {
      transform: translateX(-50%) rotateY(-90deg) rotateX(-90deg);
    }
    .back {
      transform: translateZ(-$size / 2) rotateY(180deg) rotateX(-90deg);
    }
    .right {
      transform: translateX(50%) rotateY(90deg) rotateX(-90deg);
    }
    .front {
      transform: translateZ($size / 2) rotateX(-90deg);
    }

    .lid {
      animation: none;
      transform: translate3d(0, -120px, -120px) rotateX(50deg);
    }

    .front,
    .left,
    .back,
    .right,
    .lid {
      transition: transform 0.5s ease-out;
    }
  }
}
