.query-modal {
  height: 100vh;
  justify-content: center;
}
.query-success {
  width: 360px !important;
  & .ant-divider-horizontal {
    margin: 12px 0px;
  }
  & .header {
    margin-top: 12px;
    & .profile-image {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      margin-left: 24px;
    }
    & .username {
      font-weight: 700;
      font-size: 14px;
      line-height: 175%;
      margin-right: 24px;
    }
  }
  & .query-success-lottie {
    width: 134px;
    height: 134px;
  }
  & .query-success-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid #e3e3e3;
    border-radius: 24px;
    & .query-sent {
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      line-height: 175%;
    }
    & .query-text {
      margin: 0px 60px;
      margin-bottom: 40px;
      text-align: center;
    }
  }
}
