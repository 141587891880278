.profile-frame {
  border-radius: 0;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 64px 80px 64px 60px;
  min-height: 100vh;
  @media screen and (max-width: $screen-sm-max) {
    padding: 0;
  }
  & .service-filters {
    display: none;
    @media screen and (max-width: $screen-sm-max) {
      display: block;
    }
  }

  & .profile-section {
    @media screen and (max-width: $screen-sm-max) {
      padding: 42px 16px 30px;
    }
  }

  & .services {
    @media screen and (max-width: $screen-sm-max) {
      background-color: #efece3;
      padding: 14px 0;
    }
    & .service-card {
      background-color: white;
      border-radius: 8px;
      border: none;
      box-shadow: none;
      cursor: pointer;
      min-height: 197px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 6px;
      & .top-section {
        display: flex;
        align-items: flex-start;
        padding: 24px 16px;
        width: 100%;
      }
      & .service-card-cta {
        transition: all 0.3s ease-in-out;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
      }
      &:hover {
        border: 1px solid #000000;
        box-shadow: 2px 2px 0px #000000;
        & .forward-button {
          background-color: #000000;
          color: #fff;
        }
        & .service-card-cta {
          background-color: black !important;
          color: white !important;
          transition: all 0.3s ease-in-out;
        }
      }
      & .card-side-section {
        display: flex;
        width: auto;
        margin-right: 16px;
        & img {
          min-width: 28px;
          border-radius: 50%;
          object-fit: contain;
        }
      }
      & .card-main-section {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        & .card-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          & .service-card-title {
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
            width: 70%;
            margin-bottom: 0;
            margin-top: 1px;
          }
          & .header-actions {
            display: flex;
            align-items: center;
          }
        }
        & .card-content {
          display: flex;
          height: 100%;
          & .service-description {
            color: #425466;
            max-width: 75%;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
          }
        }
      }
      & .card-footer-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px;
        border-top: 1px solid rgba(225, 225, 225, 0.4);
        min-height: 57px;
      }
    }
  }

  & .profile-pic {
    border-radius: 50%;
    aspect-ratio: 1/1;
    object-fit: contain;
    z-index: 1;
    @media screen and (max-width: $screen-sm-max) {
      width: 130px;
    }
  }

  & .name {
    font-size: 36px !important;
    line-height: 48px;
    font-weight: 800;
    margin-top: 24px !important;
    margin-bottom: 16px;

    @media screen and (max-width: $screen-sm-max) {
      margin-top: 20px !important;
      font-size: 24px !important;
      line-height: 32px;
      margin-bottom: 0;
    }
  }

  & .description {
    font-size: 18px !important;
    line-height: 27px;
    font-weight: 500;

    @media screen and (max-width: $screen-sm-max) {
      margin-top: 8px !important;
      font-size: 16px !important;
      line-height: 24px;
    }
  }
}

.tm-modal-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  position: fixed;
  outline: 2px solid transparent;
  outline-offset: 2px;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  justify-content: center;
  z-index: 9999;
}
.tm-mobile-container {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 999;

  & .tm-mobile-section {
    background-color: #fff;
    box-shadow:
      0 20px 25px -5px rgb(0 0 0 / 0.1),
      0 8px 10px -6px rgb(0 0 0 / 0.1);
    bottom: 0;
    border-radius: 24px;
    transition-delay: 300ms;
    transition-duration: 500ms;
    max-width: 512px;
    position: absolute;
    transform: translateX(0px);
    width: 100vw;

    & .tm-mobile-frame {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
      max-width: 512px;
      overflow-y: scroll;
    }
  }
  & .tm-mobile-close-bg {
    cursor: pointer;
    height: 100%;
    width: 100vw;
  }
}

.tm-iframe-container {
  border: 0px;
  border-radius: 24px;
  background: transparent;
  display: flex;
  flex-direction: column;
  position: relative;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 100%;
  @media screen and (min-width: 575px) {
    width: 85%;
    height: 80%;
  }

  & .close-icon {
    background: transparent;
    border: 0px;
    float: right;
    position: fixed;
    margin-left: auto;
    top: 8px;
    right: 16px;
    @media screen and (min-width: 575px) {
      top: 40px;
      right: 40px;
    }
    & .icon-text {
      color: #fff;
      font-size: 1.5rem;
      line-height: 2rem;
      height: 64px;
      width: 64px;
    }
  }

  & .iframe-box {
    border-radius: 16px;
    background: transparent;
    @media screen and (max-width: 575px) {
      border-radius: 0px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }
}

.service-container {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: 100%;
  gap: 20px;
  // margin-top: 30px;
  @media screen and (max-width: $screen-sm-max) {
    gap: 20px;
    // margin-top: 24px;
  }
}
