.unsaved-modal {
  & .desc {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  & .action-buttons {
    margin-top: 60px;
    & .discard {
      color: #e44332;
      font-weight: 700;
      font-size: 15px;
      margin-right: 8px;
    }
  }
}
