.year-wrap-container {
  // background-color: aliceblue;
}
.year-wrap-content {
  min-height: 623px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 575px) {
    min-height: 650px;
  }
  & .header {
    padding: 26px 20px;
    & .title {
      font-weight: 700;
      font-size: 28px;
      line-height: 110%;
    }
  }
  & .recap-image {
    object-fit: contain;
    width: 100%;
  }
  & .bottom-content {
    padding: 16px 24px 32px 24px;
  }
  & .icon-side {
    border: 2px solid #000;
    padding: 8px;
    border-radius: 50%;
    font-size: 16px;
  }
  & .action-btns {
    padding-top: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 175%;
    & .btn-cta {
      font-weight: 700;
      font-size: 16px;
    }
    & .download-btn {
      background-color: #f3f3f3;
      border: 0px;
      margin-top: 8px;
    }
    & .twitter-icon {
      margin-right: 16px;
      color: #03a9f4;
    }
  }
}
.drawer-visible {
  & .ant-drawer-content {
    border-radius: 16px 16px 0px 0px;
  }
}
.modal-container {
  & .ant-modal-content {
    border-radius: 16px;
  }
  & .ant-modal-close-x {
    margin-top: 8px;
  }
}
